import React from "react"
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { TrashIcon } from "./trash";
import { Container } from './styled';
import { useRouter } from "next/router";
import { Grid, Pagination } from '@mui/material';
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { Organograma } from "@/utils/organograma/Organograma";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { ActionButtonElaborations } from "../../ActionButton";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { SelectFieldElaboration } from "../../Inputs/SelectField";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { MultipleSelect } from "@/components/global/Inputs/MultipleInput";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { DashboardManagementPlansService } from "@/services/endpoints/dashboard";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { analisarTerritorioArray, encontrarCorrespondentes, handleContentRelatedToTerritorySelected, removeDuplicatesFromA } from "../utils";

type InputsPrograms = {
  programa: any;
  is_optional?: boolean;

}

const SchemaPrograms = Yup.object().shape({
  programa: Yup.array().of(
    Yup.object().shape({
      setor: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      texto: Yup.string().required('Campo obrigatório'),
      valor: Yup.string().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      description: Yup.string().required('Campo obrigatório'),
      responsavel: Yup.string().required('Campo obrigatório'),
      publicoAlvo: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
    })
  ),
  is_optional: Yup.boolean(),
});

const defaultValue = {
  id: '',
  tor: [],
  setor: '',
  orgao: '',
  texto: '',
  valor: '',
  prazo: null,
  territorio: '',
  description: '',
  responsavel: '',
  publicoAlvo: '',
}

const transformProgramsData = (data: any[]) => {
  return data.map((item: any) => {
    const tor = item?.programasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.programasTerritorio)

    return {
      tor,
      territorio,
      id: item?.id,
      valor: item?.valor,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      description: item?.description,
      publicoAlvo: item?.publicoAlvo || '',
      responsavel: item?.responsavel || '',
      prazo: item?.data ? dayjs(item?.data) : null,
    };
  });
};

const transformProgramsDataReuse = (item: any) => {
  const tor = item?.programasTerritorio.map((m: any) => {
    if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
    if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
    if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
    if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
    return null;
  }).filter(Boolean);

  const territorio = analisarTerritorioArray(item.programasTerritorio)

  return {
    tor,
    territorio,
    valor: item?.valor,
    texto: item?.texto || '',
    orgao: item?.orgao?.id || '',
    setor: item?.setor?.id || '',
    description: item?.description,
    publicoAlvo: item?.publicoAlvo || '',
    responsavel: item?.responsavel || '',
    prazo: item?.data ? dayjs(item?.data) : null,
  };
};

export const ProgramsForm: React.FC = () => {
  const {
    trigger,
    clearErrors,
    watch: watchPrograms,
    control: controlPrograms,
    register: registerPrograms,
    setValue: setValuePrograms,
    handleSubmit: handleSubmitPrograms,
    formState: { errors: errosPrograms } } = useForm<InputsPrograms>({
      resolver: yupResolver(SchemaPrograms) as any,
      defaultValues: { programa: [defaultValue] }
    });

  const { append: appendPrograms, update: updatePrograms, remove: removePrograms } = useFieldArray({
    control: controlPrograms,
    name: "programa"
  });

  const fields = watchPrograms('programa');
  const isOptional = watchPrograms("is_optional") as unknown as string;

  const handleExternalSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmitPrograms(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
  };

  const router = useRouter();
  const routerId = router.query.id;

  const {
    PostProgressCode,
    ProgramsElaboration,
    GetProgramsElaborations,
    GetGoalsEixoElaborations,
    UpdateProgramsElaborations,
    DeleteProgramsElaborations,
    GetThematicAxisElaboration,
    GetMacroactionsEixoElaborations,
  } = new ElaborationService();

  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [data, setData] = React.useState<any[]>([]);
  const [disable, setDisable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [filterData, setFilterData] = React.useState({
    setor: [] as any[],
    orgaoResponsavel: [] as any[],
    territorio: {} as Record<string, any>
  });
  const [reutilizar, setReutilizar] = React.useState(false);
  const [programas, setProgramas] = React.useState<any[]>([]);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [deleteProgramId, setDeleteProgramId] = React.useState(0);
  const { getAllFilters } = new DashboardManagementPlansService();
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteProgramIndex, setDeleteProgramIndex] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, completCode, handleFetchProgressCodePlanId, setDefaultItemMenuId, setNavigable } = usePreparationOfPlans();

  const total = programas.length;

  const handleLeftClick = () => {
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === 0 ? eixos_tematicos.length - 1 : currentIndexEixo - 1);
  };

  const handleRightClick = () => {
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === eixos_tematicos.length - 1 ? 0 : currentIndexEixo + 1);
  };

  const handleChange = async (_: any, value: any) => {
    if (typeof _ == 'string') {
      setPage(value);
      return;
    }
    const isValid = await trigger();

    if (isValid) {
      handleSubmitPrograms(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setPage(value);
  };

  const filterSectors = (data: any, orgao: any) => {
    const findItem = data?.orgaoResponsavel?.find((item: any) => item?.id == orgao);
    const filterItems =
      data?.setor?.filter((item: any) => item.codUa == findItem?.codUa)
        .map((item: any) => ({ value: item?.id, name: item?.descricao }))

    return filterItems
  };

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.programa?.find((item: any) => item[title]);
    return foundObject ? foundObject[title].message : null;
  };

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  React.useEffect(() => {
    if (router.query.id && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) {
      GetThematicAxisElaboration(router.query.id).then(res => {
        setEixosTematicos(res.data);
      });
    }
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    setLoading(true);
    if ((router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) || (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
      const metas = completCode.find(item => item.code == 'metas' && item.skipped == false);
      const macroacoes = completCode.find(item => item.code == 'macroacoes' && item.skipped == false);

      if (zustandSelectedCardItem.title == 'Plano Setorial' || zustandSelectedCardItem.title == 'Plano Institucional') {
        GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setProgramas(res.data);
          setLabel('Macroação')
        }).finally(() => setLoading(false));
      } else {

        if (metas != undefined && macroacoes != undefined) {
          GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProgramas(res.data);
            setLabel('Macroação')
          }).finally(() => setLoading(false));

          return;
        }

        if (metas != undefined && macroacoes == undefined) {
          GetGoalsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProgramas(res.data);
            setLabel('Meta')
          }).finally(() => setLoading(false));

          return;
        }

        if (metas == undefined && macroacoes != undefined) {
          GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProgramas(res.data);
            setLabel('Macroação')
          }).finally(() => setLoading(false));
        }
      }
    }
  }, [completCode, routerId, router.pathname, eixos_tematicos, currentIndexEixo, zustandSelectedCardItem]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      const data = programas[page - 1];
      clearErrors()
      if (data?.programas?.length > 0) {
        setValuePrograms('programa', transformProgramsData(data.programas));
      } else {
        setValuePrograms('programa', [defaultValue])
      }
    }
  }, [router.query.id, page, programas, router.pathname, completCode]);

  React.useEffect(() => {
    if (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      getAllFilters().then((res: any) => {
        const { data } = res.data as any;
        setFilterData(data);
      });
    };
  }, [router.pathname]);

  React.useEffect(() => {
    if (planId && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos') && tipoFluxo == 'substituir') {
      GetProgramsElaborations(planId).then(res => {
        setData(res.data);
      });
    }
  }, [planId, tipoFluxo, router.pathname]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'programas');

    if (code != undefined) {
      setDisable(true);
    } else {
      setDisable(false)
    }
  }, [completCode])

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'programas' && item.skipped === false);

    if (code != undefined) {
      setValuePrograms('is_optional', true)
    }
  }, [completCode]);

  React.useEffect(() => {
    if (zustandSelectedCardItem.title == 'Plano Orçamentário') {
      setValuePrograms('is_optional', true)
    }
  }, [zustandSelectedCardItem]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'programas' && item.skipped === true);

    if (code != undefined) {
      setValuePrograms('is_optional', false)
    }
  }, [completCode])

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const { insertItemById, updateItemById, checkLayerExists, deleteItemById } = useHierarchyPreparationOfPlans();
  const { startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { programa } = data;
    const programaCode = completCode.find(item => item.code == 'programas')

    if (disabeSubmit) return;

    setDisableSubmit(true);

    let submittedData = programa;

    if (event?.data?.length > 0) {
      submittedData = event.data;
    }

    let eixo = '';
    let layerName = '';
    if (label === 'Macroação') {
      eixo = 'macroacaoId';
      layerName = 'macroacoes'
    }

    if (label === 'Meta') {
      eixo = 'metaId';
      layerName = 'metas'
    }

    try {
      for (const [index, itemPrograms] of submittedData.entries()) {
        const { indicadores, tor, id, prazo, ...rest } = itemPrograms;

        const programs = {
          ...rest,
          [eixo]: programas[page - 1]?.id,
          eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
          data: dayjs(prazo).format('YYYY-MM-DD') + 'T00:00:00',
          territorio: encontrarCorrespondentes(itemPrograms.tor, filterData.territorio),
        };

        if (id) {
          await UpdateProgramsElaborations(programs, id);
        } else {
          const response = await ProgramsElaboration(programs, router.query.id);

          const relacionamentoCheck = await consultarRelacionamento();
          const rootCheck = JSON.parse(JSON.parse(relacionamentoCheck));
          const checklayer = checkLayerExists({ root: rootCheck, layerName, targetId: programas[page - 1]?.id });

          if (!checklayer) {
            for (const item of [programs]) {
              const relacionamento = await consultarRelacionamento();
              const root = JSON.parse(JSON.parse(relacionamento));

              const newInsertItem = updateItemById({
                root,
                targetId: programas[page - 1]?.id,
                item: { programas: { itens: [] } },
                layerName,
              });

              startRelacionamento(JSON.stringify(newInsertItem));
              await atualizarRelacionamento(); // Aguarda o término da atualização
            }
          }

          for (const item of [programs]) {
            const relacionamento = await consultarRelacionamento();
            const root = JSON.parse(JSON.parse(relacionamento));

            const newInsertItem = insertItemById({
              root,
              targetId: programas[page - 1]?.id,
              item: { id: response?.data?.data, relacionamento: {} },
              layerName: 'programas',
            });

            startRelacionamento(JSON.stringify(newInsertItem));
            await atualizarRelacionamento(); // Aguarda o término da atualização
          }
        }

        await delay(500);
      }

      toggleSuccessSnackbar()

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true)

        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
      } else {
        setNavigable(false)
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id)
        handleFetchProgressCodePlanId(router.query.id)
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000)
      setDisableSubmit(false);
    } catch (error) {
      toggleErrorSnackbar()
      setDisableSubmit(false);
    }

    if (programaCode != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const newItem = {
        "tooltip": "",
        "position": "",
        "manager": label,
        "name": "Programa",
        "subordinates": [],
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, label, newItem);
      startOrganograma(newOrganograma);
      salvarOrganograma();

    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  };

  const Fields = React.useCallback(() => {
    if (Boolean(isOptional) == false || isOptional == undefined) return null;
    if (isOptional == 'false' || isOptional == undefined) return null;

    if (programas.length == 0) return null;

    return (
      <>
        <Grid item xs={12} sx={{ ml: 2 }}>
          <form onSubmit={() => { }}>
            {fields.map((field: any, goalIndex: any) => {
              const randomId = Math.random();
              const tor = watchPrograms(`programa.${goalIndex}.tor`);
              const data = watchPrograms(`programa.${goalIndex}.prazo`);
              const orgao = watchPrograms(`programa.${goalIndex}.orgao`);
              const setor = watchPrograms(`programa.${goalIndex}.setor`);
              const valor = watchPrograms(`programa.${goalIndex}.valor`);
              const responsavel = watchPrograms(`programa.${goalIndex}.responsavel`);
              const publicoAlvo = watchPrograms(`programa.${goalIndex}.publicoAlvo`);
              const texto = watchPrograms(`programa.${goalIndex}.texto`);
              const descricao = watchPrograms(`programa.${goalIndex}.description`);
              const territorio = watchPrograms(`programa.${goalIndex}.territorio`);

              return (
                <>
                  <Grid key={randomId} container spacing={2} sx={{ background: '#DFDFDF', borderRadius: '5px', mt: 2, padding: '0 1rem  0 0' }}>
                    <Grid item xs={12}>
                      <input
                        type="hidden"
                        defaultValue={field?.id}
                        {...registerPrograms(`programa.${goalIndex}.id`, { value: field?.id })}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextFieldElaboration
                        required={true}
                        errors={errosPrograms}
                        label="Título do programa"
                        commentTittle={texto}
                        defaultValue={texto}
                        register={registerPrograms}
                        name={`programa.${goalIndex}.texto`}
                        placeholder="Digite o título do programa"
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosPrograms, 'texto')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <DatePickerElaboration
                        width="90%"
                        label="Prazo"
                        commentTittle={texto}
                        required={true}
                        defaultValue={data}
                        errors={errosPrograms}
                        control={controlPrograms}
                        name={`programa.${goalIndex}.prazo`}
                      />
                      <div className="error_wrapper" style={{ marginTop: '1.5rem' }}>
                        <span>{getMessageErrorByNameRef(errosPrograms, 'prazo')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldElaboration
                        rows={3}
                        required={true}
                        multiline={true}
                        errors={errosPrograms}
                        defaultValue={descricao}
                        commentTittle={texto}
                        register={registerPrograms}
                        label="Descrição do programa"
                        placeholder="Digite a descrição"
                        name={`programa.${goalIndex}.description`}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosPrograms, 'description')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <SelectFieldElaboration
                        label="Órgão"
                        required={true}
                        defaultValue={orgao}
                        commentTittle={texto}
                        errors={errosPrograms}
                        placeholder="Selecione"
                        register={registerPrograms}
                        name={`programa.${goalIndex}.orgao`}
                        setValue={(value) => setValuePrograms(`programa.${goalIndex}.orgao`, value)}
                        data={filterData.orgaoResponsavel.map((item) => ({ value: item.id, name: item.sigla }))}

                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosPrograms, 'orgao')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <SelectFieldElaboration
                        label="Setor"
                        required={true}
                        defaultValue={setor}
                        commentTittle={texto}
                        errors={errosPrograms}
                        placeholder="Selecione"
                        register={registerPrograms}
                        name={`programa.${goalIndex}.setor`}
                        data={filterSectors(filterData, orgao)}
                        setValue={(value) => setValuePrograms(`programa.${goalIndex}.setor`, value)}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosPrograms, 'setor')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldElaboration
                        required={true}
                        label="Responsável"
                        commentTittle={texto}
                        errors={errosPrograms}
                        defaultValue={responsavel}
                        register={registerPrograms}
                        placeholder="Nome do responsável"
                        name={`programa.${goalIndex}.responsavel`}
                      />
                      <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                        <span>{getMessageErrorByNameRef(errosPrograms, 'responsavel')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <SelectFieldElaboration
                        required={true}
                        label="Território"
                        commentTittle={texto}
                        errors={errosPrograms}
                        defaultValue={territorio}
                        register={registerPrograms}
                        placeholder="Selecione o território"
                        name={`programa.${goalIndex}.territorio`}
                        data={[
                          { value: 0, name: '-' },
                          { value: 1, name: 'AP' },
                          { value: 2, name: 'RP' },
                          { value: 3, name: 'RA' },
                          { value: 4, name: 'Bairros' }
                        ]}
                        onClick={() => updatePrograms(goalIndex, { ...field, tor: [] })}
                        setValue={(value) => setValuePrograms(`programa.${goalIndex}.territorio`, value)}
                      />
                      <div className="error_wrapper" style={{ marginTop: '.2rem' }}>
                        <span>{getMessageErrorByNameRef(errosPrograms, 'territorio')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldElaboration
                        required={true}
                        label="Público-alvo"
                        errors={errosPrograms}
                        defaultValue={publicoAlvo}
                        commentTittle={texto}
                        register={registerPrograms}
                        placeholder="Nome do público-alvo"
                        name={`programa.${goalIndex}.publicoAlvo`}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosPrograms, 'publicoAlvo')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldElaboration
                        type='number'
                        required={true}
                        label="Valor"
                        commentTittle={texto}
                        errors={errosPrograms}
                        defaultValue={valor}
                        register={registerPrograms}
                        placeholder="Digite o valor R$"
                        name={`programa.${goalIndex}.valor`}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosPrograms, 'valor')}</span>
                      </div>
                    </Grid>
                    {territorio != 0 && (
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <MultipleSelect
                          fullWidth={true}
                          value={Array.isArray(tor) ? tor : []}
                          setValue={(e) => {
                            const items = removeDuplicatesFromA(tor, e)
                            updatePrograms(goalIndex, { ...field, tor: items })
                          }}
                          {...registerPrograms(`programa.${goalIndex}.tor`, { required: false })}
                          data={handleContentRelatedToTerritorySelected(filterData, territorio, tor)}
                        />
                      </Grid>
                    )}
                    {goalIndex != 0 && !preview && (
                      <Grid item xs={12} display='flex' sx={{ mb: 2, justifyContent: 'end', alignItems: 'center' }}>
                        <TrashIcon onClick={() => {
                          setOpenDeleteModal(true);
                          setDeleteProgramIndex(goalIndex)
                          setDeleteProgramId(field.id)
                        }} />
                      </Grid>
                    )}
                    <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={async () => {
                      removePrograms(deleteProgramIndex);

                      if (deleteProgramId != 0) {
                        const relacionamento = await consultarRelacionamento();
                        const root = JSON.parse(JSON.parse(relacionamento));
                        
                        const newItem = deleteItemById({ root, layerName: 'programas', targetId: deleteProgramId });
      
                        startRelacionamento(JSON.stringify(newItem));
                        atualizarRelacionamento();

                        DeleteProgramsElaborations(deleteProgramId);
                        setDeleteProgramId(0)
                      }
                      setOpenDeleteModal(false);
                    }} />
                  </Grid>
                </>
              )
            })}
          </form>
        </Grid>
        <Grid item xs={12}>
          <div className="add_line_wrapper">
            <p onClick={() => appendPrograms(defaultValue)}>
              {`+ Adicionar novo programa`}
            </p>
          </div>
        </Grid>

      </>
    );
  }, [programas, completCode, isOptional, fields, appendPrograms, updatePrograms, removePrograms, page, total, filterData, disable, programas, openDeleteModal, errosPrograms]);

  React.useCallback(() => {
    if (zustandSelectedCardItem.title != 'Plano Orçamentário' && zustandSelectedCardItem.title != 'Plano Setorial' && zustandSelectedCardItem.title != 'Plano Institucional') {
      setValuePrograms("is_optional", undefined)
    }
  }, [zustandSelectedCardItem])

  const ThematicAxisBanner = React.useCallback(() => {
    if (Boolean(isOptional) == false || isOptional == undefined) return null;
    if (isOptional == 'false' || isOptional == undefined) return null;

    return (
      <>
        <Grid item xs={12} className="title_wrapper">
          <p>Eixo temático</p>
        </Grid>
        <Grid item xs={12}>
          <div className="goals_select_wrapper">
            <div className="goals_left_arrow" onClick={() => handleLeftClick()}>{"<"}</div>
            <div className="goals_select_content_wrapper">
              <p>{eixos_tematicos[currentIndexEixo]?.texto}</p>
              <span>Eixo temático {currentIndexEixo + 1} de {eixos_tematicos.length}</span>
            </div>
            <div className="goals_rigth_arrow" onClick={() => handleRightClick()}>{">"}</div>
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <div className="pagination_wrapper">
            <Pagination
              count={total}
              page={page}
              onChange={handleChange}
              hidePrevButton hideNextButton
              variant="outlined" shape="rounded"
            />
            <p>Página {page} de {total}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          {programas.length == 0 ? null : (
            <div className="strategy_wrapper">
              <p>
                <b>{label}:</b>&nbsp;
                {programas[page - 1]?.texto}
              </p>
            </div>
          )}
        </Grid>
      </>
    );
  }, [programas, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick]);

  const ReplaceButton = React.useCallback(() => {
    if (isOptional == 'false' || isOptional == undefined && zustandSelectedCardItem.title != 'Plano Orçamentário' && zustandSelectedCardItem.title != 'Plano Setorial' && zustandSelectedCardItem.title != 'Plano Institucional') return null;
    if (tipoFluxo != 'substituir') return null;

    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, programas, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, label, reutilizar, openDisclaimer, isOptional]);

  const ActionButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'true' ? 'true' : 'false'
      } else {
        return Boolean(isOptional) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'false' || isOptional == undefined;
      } else {
        return Boolean(isOptional) == false || isOptional == undefined;
      }
    }

    return (
      <Grid item xs={12}>
        <ActionButtonElaborations disable={disabeSubmit} layer_indicator={false} isOptional={opitional()} isEmpty={programas.length == 0} onClick={() => {
          if (optionalV2()) {
            setNavigable(true);
            PostProgressCode({ code: 'programas', skipped: true }, router.query.id)

            router.push('/dashboard/elaboracao_planos/' + router.query.id);

            const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
            if (plan && plan[selectedItemsMenu?.layer as string]) {
              const planId = plan[selectedItemsMenu?.layer as string]
                .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
              setDefaultItemMenuId(planId);
            }
          }
        }} />
      </Grid>
    )
  }, [isOptional, programas, disabeSubmit]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitPrograms(onSubmit)}>
      <Container>
        <Grid container spacing={1}>
          {zustandSelectedCardItem.title != 'Plano Orçamentário' && (
            <Grid item xs={12}>
              <RadioCheckedElaboration
                required={false}
                name="is_optional"
                disabled={disable}
                errors={errosPrograms}
                control={controlPrograms}
                defaultValue={isOptional == 'true' ? 'true' : 'false'}
                data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
              />
            </Grid>
          )}
          {ReplaceButton()}
          {ThematicAxisBanner()}
          {Fields()}
          <Grid item xs={12} sx={{ mt: 4 }}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {ActionButton()}
        </Grid>
        <ReutilizarElaborationDialog
          title="Programa"
          reuseData={data}
          open={reutilizar}
          setOpen={() => setReutilizar(false)}
          setValue={(e) => {
            e.forEach((item: any, index: any) => {
              setValuePrograms(`programa.${index}`, transformProgramsDataReuse(item))
            })
          }}
        />
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
      </Container>
    </form>
  )
}
