import styled from "styled-components";

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px; 
  display:flex;
  gap: 10px;
  justify-content: center;


  button {
    
    padding: 8px 22px; 
    border-radius: 20px;
    background: var(--primary-links-blue, #004A80);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20); 

    p{
      color: var(--primary-white, #FFF);
    }
  }

  .cancel {
    border-color:  var(--primary-links-blue, #004A80);
    background: var(--primary-white, #FFF);

    p{
      color:  var(--primary-links-blue, #004A80);
    }
  }

`;