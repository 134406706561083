export const Svg_011 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={59}
    fill="none"
  >
    <path fill="url(#a)" d="M10.03 16.666h38.94v4.84H10.03v-4.84Z" />
    <path
      fill="url(#b)"
      d="m10.03 33.474 19.47 6.323 19.47-6.323v-8.511H10.03v8.51Z"
    />
    <path fill="url(#c)" d="M48.78 45.791H59v6.914H48.78v-6.914Z" />
    <path
      fill="url(#d)"
      d="M59 39.972a5.119 5.119 0 0 0-3.381-4.809V13.21H59V6.295H0v6.914h52.161v21.954a5.119 5.119 0 0 0-3.38 4.81v2.36H59v-2.36Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={19.035}
        x2={19.427}
        y1={17.392}
        y2={22.503}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={19.035}
        x2={22.549}
        y1={27.188}
        y2={42.118}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={51.143}
        x2={53.757}
        y1={46.828}
        y2={53.08}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={13.644}
        x2={26.291}
        y1={11.701}
        y2={45.21}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
    </defs>
  </svg>
)
