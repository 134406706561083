import _ from "lodash";
import React from "react";
import { useAuth } from "@/hooks/useAuth";
import { CheckboxFakeItem } from "./constants";
import { SelectChangeEvent } from "@mui/material/Select";
import { ExternalMapService } from "@/services/endpoints/external_map";
import { useRouter } from "next/router";

type setState<T> = React.Dispatch<React.SetStateAction<T>>;

export type Subdivision = "area" | "regiao" | "administrativa" | "bairro" | "";

export interface CheckboxFakeProps {
  id: number;
  nome: string;
  checked: boolean;
}
[];

function removerAcentos(str: any) {
  return str?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
}

export const itemsMock = [
  {
    id: 1,
    title: "Programas",
    clicked: true,
  },
  {
    id: 2,
    title: "Projetos",
    clicked: false,
  },
  {
    id: 3,
    title: "Produtos/Entregas",
    clicked: false,
  },
];

export const itemsMockV2 = [
  {
    id: 1,
    title: "Programas",
    clicked: false,
  },
  {
    id: 2,
    title: "Projetos",
    clicked: false,
  },
  {
    id: 3,
    title: "Produtos/Entregas",
    clicked: true,
  },
];

export type CheckboxFakeItem = Record<string, unknown>;

export interface ITerritorialMarksProps {
  detailsItems: any[];
  busca: string;
  subdivision: Subdivision;
  setCodBairros: React.Dispatch<React.SetStateAction<any[]>>;
  codBairros: any[];
  setLegendMap: setState<any[]>;
  legendMap: any[];
  loadingFilterItems: boolean;
  itemDetailsId: number;
  setBusca: setState<string>;
  searchJsonIds: Record<string, any>;
  regionalizedViewState: boolean;
  nameFilterItemSelected: string;
  setSubdivision: (value: React.SetStateAction<Subdivision>) => void;
  handleClearAllSearchInputs: () => void;

  setTooltipContentMap: React.Dispatch<
    React.SetStateAction<Record<string, any>>
  >;
  tooltipContentMap: Record<string, any>;
  setItemDetailsId: React.Dispatch<React.SetStateAction<number>>;
  setCheckboxItems: setState<Record<string, any>>;
  filterValues: Record<string, any>;
  checkboxItems: Record<string, any>;
  setRegionalizedViewState: setState<boolean>;
  setTooltipMap: React.Dispatch<React.SetStateAction<boolean>>;
  tooltipMap: boolean;
  setLoadingFilterItems: React.Dispatch<React.SetStateAction<boolean>>;
  checkedAllCheckboxItem: Record<string, boolean>;
  handleFilterChangeCheckboxItem: ({ e }: any) => void;
  handleChangeSubdivisionSelect: (event: SelectChangeEvent) => void;
  handleCheckedAllCheckboxItems: (value: boolean, key: string) => void;
  handleChangeOneByOneCheckBoxItem: (
    id: string,
    key: string,
    checked: boolean
  ) => void;
  setSearchJsonIds: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >;
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  items: any[]
}

export const TerritorialMarks = React.createContext(
  {} as ITerritorialMarksProps
);

interface IProps {
  children: React.ReactNode;
}

export const TerritorialMarksProvider: React.FC<IProps> = ({ children }) => {
  const { getFilterItems, postFilterItems } = new ExternalMapService();
  const [regionalizedViewState, setRegionalizedViewState] =
    React.useState(true);
  const [searchJsonIds, setSearchJsonIds] = React.useState(
    {} as Record<string, unknown>
  );
  const { authenticated } = useAuth();
  const [tooltipMap, setTooltipMap] = React.useState(false);
  const [checkboxItems, setCheckboxItems] = React.useState(
    {} as Record<string, any>
  );
  const router = useRouter();

  const [tooltipContentMap, setTooltipContentMap] = React.useState(
    {} as Record<string, any>
  );
  const [subdivision, setSubdivision] = React.useState<Subdivision>("bairro");

  const [legendMap, setLegendMap] = React.useState<any[]>([]);

  const [items, setItems] = React.useState<any[]>(itemsMock);


  const handleClearAllSearchInputs = () => {
    if (router.pathname.split("/").includes('mapa_interativo') || router.pathname.split("/").includes('dashboard'))

      getFilterItems().then((res) => {
        const { data } = res.data as any;

        const ObjectCheckboxItem = insertCheckedPropsOnCheckboxItem(data);

        setCheckboxItems(() => {
          let newObjectCheckboxItem = {
            temas: ObjectCheckboxItem["temas"],
            planos: ObjectCheckboxItem["planos"],
            bairros: ObjectCheckboxItem["bairros"],
          } as Record<string, any>;

          if (subdivision !== "bairro") {
            newObjectCheckboxItem = {
              temas: ObjectCheckboxItem["temas"],
              planos: ObjectCheckboxItem["planos"],
            };
            const items = {} as any;

            Object.entries(newObjectCheckboxItem).map(
              ([key, value]: any) =>
              (items[key] = _.orderBy(
                value?.map((item: any) => ({ ...item, checked: false })),
                [(obj: any) => removerAcentos(obj.nome)],
                ["asc"]
              ))
            );

            setSearchJsonIds(() => ({
              ["temas"]: items["temas"]?.map((i: any) => i?.id),
              ["planos"]: items["planos"]?.map((i: any) => i?.id),
              ["bairros"]: items["bairros"]?.map((i: any) => i?.id),
            }));

            return items;
          }

          if (subdivision === "bairro") {
            newObjectCheckboxItem = {
              temas: ObjectCheckboxItem["temas"],
              planos: ObjectCheckboxItem["planos"],
              bairros: ObjectCheckboxItem["bairros"],
            };

            const items = {} as any;

            Object.entries(newObjectCheckboxItem).map(
              ([key, value]: any) =>
              (items[key] = _.orderBy(
                value?.map((item: any) => ({ ...item, checked: false })),
                [(obj: any) => removerAcentos(obj.nome)],
                ["asc"]
              ))
            );

            setSearchJsonIds(() => ({
              ["temas"]: items["temas"]?.map((i: any) => i?.id),
              ["planos"]: items["planos"]?.map((i: any) => i?.id),
              ["bairros"]: items["bairros"]?.map((i: any) => i?.id),
            }));

            return items;
          }

          const items = {} as any;

          Object.entries(newObjectCheckboxItem).map(
            ([key, value]: any) =>
            (items[key] = _.orderBy(
              value?.map((item: any) => ({ ...item, checked: false })),
              [(obj: any) => removerAcentos(obj.nome)],
              ["asc"]
            ))
          );


          return items;

        });
      });

    setDetailsItems([]);

    setCheckedAllCheckboxItem({ planos: false, temas: false, bairros: false });
  };

  React.useEffect(() => {
    if (router.pathname.split("/").includes('mapa_interativo') || router.pathname.split("/").includes('dashboard'))

      if (subdivision !== "bairro") {
        delete checkboxItems["bairros"];
        setSearchJsonIds((prev) => {
          const data = { ...prev };
          delete data["bairros"];
          return data;
        });
      } else {
        getFilterItems().then((res) => {
          const { data } = res.data as any;
          const ObjectCheckboxItem = insertCheckedPropsOnCheckboxItem(data);
          setCheckboxItems((prev) => {
            const newObjectCheckboxItem = {
              temas: ObjectCheckboxItem["temas"],
              planos: ObjectCheckboxItem["planos"],
              bairros: ObjectCheckboxItem["bairros"],
            };

            const items = {} as any;
            Object.entries(newObjectCheckboxItem).map(([key, value]: any) => {
              const prevData = prev[key] as any[];
              if (prevData?.some((i: any) => i.checked === true)) {
                return (items[key] = _.orderBy(
                  prevData,
                  [(obj: any) => removerAcentos(obj.nome)],
                  ["asc"]
                ));
              } else {
                return (items[key] = _.orderBy(
                  value?.map((item: any) => ({ ...item, checked: false })),
                  [(obj: any) => removerAcentos(obj.nome)],
                  ["asc"]
                ));
              }
            });

            return items;
          });
        });
      }
  }, [subdivision, router.pathname]);

  const insertCheckedPropsOnCheckboxItem = (data: any) => {
    const items = {} as any;

    Object.entries(data).map(
      ([key, value]: any) =>
      (items[key] = _.orderBy(
        value?.map((item: any) => ({ ...item, checked: true })),
        [(obj: any) => removerAcentos(obj.nome)],
        ["asc"]
      ))
    );

    return items;
  };

  React.useEffect(() => {
    if (router.pathname.split("/").includes('mapa_interativo') || router.pathname.split("/").includes('dashboard'))

      getFilterItems().then((res) => {
        const { data } = res.data as any;

        const tir = insertCheckedPropsOnCheckboxItem(data);

        if (subdivision != "bairro") {
          const it = {
            temas: tir["temas"],
            planos: tir["planos"],
          };

          const items = {} as any;

          Object.entries(it).map(
            ([key, value]: any) =>
            (items[key] = _.orderBy(
              value?.map((item: any) => ({ ...item, checked: false })),
              [(obj: any) => removerAcentos(obj.nome)],
              ["asc"]
            ))
          );

          items;
          setCheckboxItems(items);
        } else {
          const it = {
            temas: tir["temas"],
            planos: tir["planos"],
            bairros: tir["bairros"],
          };

          const items = {} as any;

          Object.entries(it).map(
            ([key, value]: any) =>
            (items[key] = _.orderBy(
              value?.map((item: any) => ({ ...item, checked: false })),
              [(obj: any) => removerAcentos(obj.nome)],
              ["asc"]
            ))
          );

          setSearchJsonIds(() => ({
            ["temas"]: items["temas"]?.map((i: any) => i?.id),
            ["planos"]: items["planos"]?.map((i: any) => i?.id),
            ["bairros"]: items["bairros"]?.map((i: any) => i?.id),
          }));

          setFilterValues(
            (prev) =>
            ({
              ["temas"]: false,
              ["planos"]: false,
              ["bairros"]: false,
            } as any)
          );

          setCheckedAllCheckboxItem(() => ({
            ["temas"]: false,
            ["planos"]: false,
            ["bairros"]: false,
          }));

          setCheckboxItems(items);
        }
      });
  }, [router.pathname]);

  const [checkedAllCheckboxItem, setCheckedAllCheckboxItem] = React.useState({
    planos: false,
    temas: false,
    bairros: false,
  } as Record<string, any>);

  const handleCheckedAllCheckboxItems = (
    checkedValue: boolean,
    key: string
  ) => {
    const customItems = {} as any;
    const data = { ...checkboxItems } as Record<string, any>;
    const planos = _.some(data['planos'], { 'checked': true })
    const temas = _.some(data['temas'], { 'checked': true })
    const bairros = _.some(data['bairros'], { 'checked': true })

    if (planos && (!temas && !bairros)) {
      setSearchJsonIds(prev => {
        const data = { ...prev };
        delete data['temas']
        delete data['bairros']
        return data;
      })
    }

    if (temas && (!planos && !bairros)) {
      setSearchJsonIds(prev => {
        const data = { ...prev };
        delete data['planos']
        delete data['bairros']
        return data;
      })
    }

    if (bairros && (!planos && !temas)) {
      setSearchJsonIds(prev => {
        const data = { ...prev };
        delete data['planos']
        delete data['temas']
        return data;
      })
    }

    // + 2

    if (planos && temas && !bairros) {
      setSearchJsonIds(prev => {
        const data = { ...prev };
        delete data['bairros']
        return data;
      })
    }

    if (planos && bairros && !temas) {
      setSearchJsonIds(prev => {
        const data = { ...prev };
        delete data['temas']
        return data;
      })
    }

    if (temas && bairros && !planos) {
      setSearchJsonIds(prev => {
        const data = { ...prev };
        delete data['planos']
        return data;
      })
    }

    if (!temas && !bairros && !planos) {
      setSearchJsonIds({})
    }

    Object.entries(data).forEach(([internalkey, internalArray]) => {
      if (internalkey === key) {
        customItems[key] = internalArray?.map((item: any) => ({
          ...item,
          checked: checkedValue,
        }));
      }
    });

    const jsonIds = { ...searchJsonIds } as Record<string, any>;


    setCheckedAllCheckboxItem((prev: any) => ({
      ...prev,
      [key]: checkedValue,
    }));

    setCheckboxItems((prev) => ({
      ...prev,
      [key]: customItems[key],
    }));

    const ids: any[] = [];
    const ValueItemIds = Object.values(customItems)[0] as any[];

    if (checkedValue) {
      ValueItemIds?.map((item) => ids.push(item?.id));
      searchJsonIds[key] = ids;
      setSearchJsonIds((prev) => ({
        ...prev,
        [key]: ids,
      }));
    }

    if (!checkedValue) {
      ValueItemIds?.map((item) => ids.slice(item.id));
      delete searchJsonIds[key];
      setSearchJsonIds((prev) => {
        const data = { ...prev };
        delete data[key];
        return data;
      });
    }
  };

  const handleChangeOneByOneCheckBoxItem = (
    id: string,
    key: string,
    checked: boolean
  ) => {
    setCheckboxItems((prev: any) => {
      const data = { ...prev };
      const index = data[key].findIndex((item: any) => +item?.id === +id);
      const item = data[key].find((item: any) => +item?.id === +id);

      data[key][index] = { ...item, checked: !item?.checked };

      if (Object.values(data).some((i: any) => i.checked !== false)) {
        setSearchJsonIds((prev) => {
          const data = { ...prev };
          delete data[key];
          return data;
        });
      } else {
        setSearchJsonIds({});
      }
      setSearchJsonIds((prev) => {
        const data = { ...prev } as Record<string, any>;
        const id = item?.id;

        if (data[key] === undefined) {
          data[key] = filtrarElementosUnicos([id]);
        }

        if (data[key]?.length > 0 && checked) {
          data[key] = filtrarElementosUnicos([...data[key], id]);
        }

        if (Object.values(data).length === 0 && checked) {
          data[key] = filtrarElementosUnicos([id]);

          return data;
        }

        if (!checked) {
          const it = data[key]?.filter((item: any) => item !== id);
          data[key] = filtrarElementosUnicos([...it]);
        }

        if (data[key]?.length === 0) {
          delete data[key];
        }

        return data;
      });
      return data;
    });
  };

  const [detailsItems, setDetailsItems] = React.useState([]);
  const [busca, setBusca] = React.useState("");
  const [itemDetailsId, setItemDetailsId] = React.useState(1);
  const [codBairros, setCodBairros] = React.useState<any[]>([]);
  const [loadingFilterItems, setLoadingFilterItems] = React.useState(false);

  React.useEffect(() => {
    const isMapaInterativoPage = router.pathname.split('/').includes('mapa_interativo') || router.pathname.split('/').includes('dashbord');
    if (Object.keys(searchJsonIds).length > 0 && isMapaInterativoPage) {
      const data = {
        ...searchJsonIds,
        isRegionalizado: regionalizedViewState ? true : false,
      };
      setLoadingFilterItems(true);
      postFilterItems(data)
        .then((res: any) => {
          const { data } = res.data as any;

          const insertOpenProps = data?.map((item: any) => {
            return {
              ...item,
              value: item.value.map((it: any) => ({ ...it, open: false })),
            };
          });
          setDetailsItems(insertOpenProps);
        })
        .finally(() => setLoadingFilterItems(false));
    }
  }, [searchJsonIds, regionalizedViewState, router.pathname]);

  const [filterItemSelected, setFilterItemSelected] = React.useState(false);
  const [nameFilterItemSelected, setNameFilterItemSelected] =
    React.useState("");
  const [filterValues, setFilterValues] = React.useState({
    bairros: false,
    temas: false,
    planos: false,
  });

  const handleFilterChangeCheckboxItem = ({ e }: any) => {
    const name = e.currentTarget.innerText.toLocaleLowerCase() as string;
    setFilterItemSelected(!filterItemSelected);
    setNameFilterItemSelected(name);
  };

  React.useEffect(() => {
    setFilterValues((prevState) => ({
      ...prevState,
      [nameFilterItemSelected]: filterItemSelected,
    }));
  }, [filterItemSelected, nameFilterItemSelected]);

  React.useEffect(() => {
    if (!regionalizedViewState) {
      setSubdivision("");
    } else {
      setSubdivision("bairro");
    }
  }, [regionalizedViewState]);

  const handleChangeSubdivisionSelect = (event: SelectChangeEvent) => {
    setSubdivision(event.target.value as Subdivision);
    // localStorage.setItem('filter_value', JSON.stringify(event.target.value))
  };

  function filtrarElementosUnicos(array: any[]) {
    let resultado = [];

    for (let i = 0; i < array.length; i++) {
      if (resultado.indexOf(array[i]) === -1) {
        resultado.push(array[i]);
      }
    }

    return resultado;
  }

  React.useEffect(() => {
    let arrBairros = [] as any[];
    Object.entries(detailsItems).forEach(([key, value]) => {
      const values = value["value"] as any[];
      if (regionalizedViewState) {
        values.forEach((ix) => {
          if (itemDetailsId === 1) {
            const programas = ix.programas as any[];
            if (programas.length > 0) {
            }


            programas.length > 0 &&
              programas.forEach((i) => {
                const programasTerritorio = i.programasTerritorio as any[];

                programasTerritorio?.forEach((itr) => {

                  if (subdivision === "area") {
                    arrBairros.push(itr?.areaPlanejamento?.cod);
                  }

                  if (subdivision === "regiao") {
                    arrBairros.push(itr?.regiaoPlanejamento?.cod);
                  }

                  if (subdivision === "administrativa") {
                    arrBairros.push(itr?.regiaoAdministrativa?.cod);
                  }

                  if (subdivision === "bairro") {
                    arrBairros.push(itr?.bairro?.cod);
                  }
                });
              });
          }

          if (itemDetailsId === 2) {
            const projetos = ix.projetos as any[];
            projetos.length > 0 &&
              projetos.forEach((i: any) => {
                if (i?.ptojetosTerritorio?.length > 0) {
                  const ptojetosTerritorio = i?.ptojetosTerritorio as any[];
                  ptojetosTerritorio.length > 0 &&
                    ptojetosTerritorio.forEach((itr) => {
                      if (subdivision === "area") {
                        arrBairros.push(itr?.areaPlanejamento?.cod);
                      }

                      if (subdivision === "regiao" && itr?.bairro) {
                        arrBairros.push(itr?.regiaoPlanejamento?.cod);
                      }

                      if (subdivision === "administrativa" && itr?.bairro) {
                        arrBairros.push(itr?.regiaoAdministrativa?.cod);
                      }

                      if (subdivision === "bairro" && itr?.bairro) {
                        arrBairros.push(itr?.bairro?.cod);
                      }
                    });
                }
              });
          }

          if (itemDetailsId === 3) {
            const entregas = ix.entregas as any[];
            entregas.length > 0 &&
              entregas.forEach((i: any) => {
                if (i?.entregasTerritorio?.length > 0) {
                  const entregasTerritorio = i?.entregasTerritorio as any[];
                  entregasTerritorio.length > 0 &&
                    entregasTerritorio.forEach((itr) => {
                      if (subdivision === "area") {
                        arrBairros.push(itr?.areaPlanejamento?.cod);
                      }

                      if (subdivision === "regiao") {
                        arrBairros.push(itr?.areaPlanejamento?.cod);
                      }

                      if (subdivision === "administrativa") {
                        arrBairros.push(itr?.regiaoAdministrativa?.cod);
                      }

                      if (subdivision === "bairro") {
                        arrBairros.push(itr?.bairro?.cod);
                      }
                    });
                }
              });
          }
        });
      }
    });
    const codbairroUnico = filtrarElementosUnicos(arrBairros).filter(i => i != undefined);
    setCodBairros(codbairroUnico);
  }, [
    subdivision,
    detailsItems,
    itemDetailsId,
    regionalizedViewState,
    checkedAllCheckboxItem,
  ]);

  const value = {
    busca,
    handleClearAllSearchInputs,
    setBusca,
    subdivision,
    filterValues,
    setCodBairros,
    setTooltipContentMap,
    tooltipContentMap,
    codBairros,
    detailsItems,
    setTooltipMap,
    tooltipMap,
    legendMap,
    setLegendMap,
    setItemDetailsId,
    itemDetailsId,
    searchJsonIds,
    checkboxItems,
    setCheckboxItems,
    setLoadingFilterItems,
    setSearchJsonIds,
    loadingFilterItems,
    regionalizedViewState,
    checkedAllCheckboxItem,
    nameFilterItemSelected,
    setSubdivision,
    setRegionalizedViewState,
    handleChangeSubdivisionSelect,
    handleCheckedAllCheckboxItems,
    handleFilterChangeCheckboxItem,
    handleChangeOneByOneCheckBoxItem,
    items,
    setItems
  } as ITerritorialMarksProps;

  return (
    <TerritorialMarks.Provider value={value}>
      {children}
    </TerritorialMarks.Provider>
  );
};
