import { InfoIncon } from "../info";
import React, { useState } from "react"
import { Tooltip } from '@mui/material';
import { useRouter } from "next/router";
import { ConsideracaoIcon } from "../consideracao";
import { BootstrapInput, Container } from "./styled"
import { ConsiderationModal } from "../../Forms/ConsiderationModal";
import { DetailsConsultElaborationDialog } from "@/components/elaboracao_planos/partials/ActionButton/Dialogs/DetailsConsultDialog";

interface IProps {
  id?: any;
  type?: any;
  errors: any;
  name: string;
  register: any;
  rows?: number;
  tooltip?: any;
  label?: string;
  local?: string;
  value?: string;
  nameRef?: string;
  required: boolean;
  subLabel?: string;
  maxLength?: number;
  multiline?: boolean;
  placeholder?: string;
  hiddeInput?: boolean;
  defaultValue?: string;
  commentTittle?: string;
  showModalTitle?: boolean;
  setValue?: (value: any) => void;
}

export const TextFieldElaboration: React.FC<IProps> = ({
  id,
  name,
  rows,
  value,
  type,
  label,
  local,
  tooltip,
  required,
  register,
  setValue,
  subLabel,
  maxLength,
  multiline,
  hiddeInput,
  placeholder,
  defaultValue,
  commentTittle,
  showModalTitle = false,
}) => {

  const [buffedValue, setBuffervalue] = useState('');

  return (
    <Container subLabel={subLabel} >
      {label && (
        <div className="title_wrapper_textfield">
          <ToolTipWrapper
            name={name}
            local={local}
            label={label}
            value={value}
            tooltip={tooltip}
            register={register}
            required={required}
            hiddeInput={hiddeInput}
            buffedValue={buffedValue}
            commentTittle={commentTittle}
            showModalTitle={showModalTitle}
          />
          <span style={{ marginBottom: '6px' }}>{subLabel}</span>
        </div>
      )}
      {!hiddeInput && (
        <BootstrapInput
          id={id}
          fullWidth
          rows={rows}
          type={type}
          value={value}
          autoComplete="off"
          placeholder={placeholder}
          defaultValue={defaultValue}
          multiline={multiline || false}
          {...register(name, { required: required })}
          onChange={(e) => setValue && setValue(e.target.value)}
          onKeyUp={(e) => setBuffervalue((e.target as HTMLInputElement).value)}
          sx={{ '& .MuiInputBase-input': { height: maxLength == 400 && '130px !important' } }}
          inputProps={{ maxLength, inputMode: type == 'number' ? 'decimal' : undefined, step: type === 'number' ? 'any' : undefined }}
        />
      )}
    </Container>
  )
}

type TooltipWrapperProps = {
  name?: string,
  value?: string,
  local?: string,
  register?: any,
  label?: string,
  tooltip?: string,
  required?: boolean,
  hiddeInput?: boolean,
  buffedValue?: string,
  commentTittle?: string;
  showModalTitle?: boolean;
}

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.withe,
    backgroundColor: theme.palette.common.white,
  },
}));

const ToolTipWrapper = ({
  name,
  local,
  label,
  value,
  tooltip,
  required,
  register,
  hiddeInput,
  commentTittle,
  showModalTitle,
  buffedValue = '',
}: TooltipWrapperProps) => {

  const router = useRouter();
  const [epl, setEpl] = React.useState<boolean>(false);

  React.useEffect(() => {

    if (!router.isReady) return
    if (!router.query.id) return

    setEpl(router.asPath.includes('epl') && router.asPath.includes('dashboard/aprovacao_planos'));
  }, [router])


  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {local == 'sintese_cenario' && (
        <>
          {!hiddeInput && (
            <>
              <label className="interFont">
                <p>{label}</p>
                <span>
                  {required ? '*' : ''}
                </span>
              </label>
              {tooltip && (
                <LightTooltip title={tooltip}>
                  <div>
                    <InfoIncon />
                  </div>
                </LightTooltip>
              )}
            </>
          )}
        </>
      )}
      {!local && (
        <>
          <label className="interFont">
            <p>{label}</p>
            <span>
              {required ? '*' : ''}
            </span>
          </label>
          {!epl && (
            <>
              {
                showModalTitle && <TitleModalWrappwe value={buffedValue} />
              }
              {tooltip && !showModalTitle && (
                <LightTooltip title={tooltip} sx={{ color: 'white', fontSize: '18px' }}>
                  <div>
                    <InfoIncon />
                  </div>
                </LightTooltip>
              )}
            </>
          )}

        </>
      )}
      {epl && (
        <CondiserationWrapper
          name={name}
          label={label}
          value={value}
          register={register}
          commentTittle={commentTittle}
        />
      )}
    </div>
  )

}

const CondiserationWrapper = ({
  name,
  label,
  value,
  register,
  commentTittle,
}: {
  name?: string,
  register?: any,
  label?: string,
  value?: string,
  commentTittle?: string;
}) => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a className="consideration-modal" onClick={() => { setOpen(true) }}>
        <ConsideracaoIcon />
      </a>
      <ConsiderationModal
        open={open}
        name={name}
        label={label}
        value={value}
        register={register}
        handleClose={handleClose}
        commentTittle={commentTittle}
      />
    </>
  )
}

const TitleModalWrappwe = ({ value = '' }: { value: any }) => {
  const [openDetails, setOpenDetails] = React.useState(false);

  return (
    <>
      <a className="details-icon" onClick={() => setOpenDetails(true)}>
        <InfoIncon />
      </a>

      <DetailsConsultElaborationDialog
        data={{
          titulo: value,
          status: 1,
        }}
        open={openDetails}
        setOpen={(value) => setOpenDetails(value)}
        title={value}
      />
    </>
  )
}