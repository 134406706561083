import React from "react"
import * as Yup from 'yup';
import { Grid } from '@mui/material'
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { FileFieldElaboration } from "../../Inputs/FileInput";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { Organograma } from "@/utils/organograma/Organograma";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { getTransformToDiagnosisLayer, transformDiagnosisLayer } from "@/utils/elaborationParseData";

type InputsDiagnosis = {
  sintese: any
  is_optional?: boolean;
  is_complete_pdf?: boolean;
  file_pdf?: any;
  file_word?: any;
};

const defaultValue = {
  diagnostico: '',
  forcas: [{ id: '', texto: '' }],
  ameacas: [{ id: '', texto: '' }],
  fraquezas: [{ id: '', texto: '' }],
  oportunidades: [{ id: '', texto: '' }],
}

const SchemaDiagnosis = Yup.object().shape({
  is_complete_pdf: Yup.boolean(),
  sintese: Yup.array().of(
    Yup.object().shape({
      diagnostico: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string()
        })
      ),
      forcas: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string(),
        })
      ),
      ameacas: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string()
        })
      ),
      fraquezas: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string()
        })
      ),
      oportunidades: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string()
        })
      ),
    })
  ).required('A síntese é obrigatória'),
  file_pdf: Yup.mixed().nullable(),
  file_word: Yup.mixed().nullable(),
}).required('');

export const SummaryDiagnosisForm: React.FC = () => {
  const {
    watch: watchDiagnosis,
    control: controlDiagnosis,
    register: registerDiagnosis,
    setValue: setValueDiagnosis,
    handleSubmit: handleSubmitDiagnosis,
    formState: { errors: errosDiagnosis } } = useForm<InputsDiagnosis>({
      resolver: yupResolver(SchemaDiagnosis) as any,
      defaultValues: { sintese: [defaultValue] }
    });

  const { update: updateDiagnosis } = useFieldArray({
    control: controlDiagnosis,
    name: "sintese"
  });

  const fields = watchDiagnosis("sintese");
  const pdfFile = watchDiagnosis('file_pdf');
  const wordFile = watchDiagnosis('file_word');
  const is_optional = watchDiagnosis('is_optional') as unknown as string;
  const is_complete_pdf = watchDiagnosis('is_complete_pdf') as unknown as string;

  const router = useRouter();
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [disable, setDisable] = React.useState(false);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [disablePdf, setDisablePdf] = React.useState(false);
  const [deleteForcaId, setDeleteForcaId] = React.useState(0);
  const [deleteAmeacaId, setDeleteAmeacaId] = React.useState(0);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openForcaModal, setOpenForcaModal] = React.useState(false);
  const [deleteFraquezaId, setDeleteFraquezaId] = React.useState(0);
  const [openAmeacaModal, setOpenAmeacaModal] = React.useState(false);
  const [deleteForcaIndex, setDeleteForcaIndex] = React.useState(null);
  const [deleteAmeacaIndex, setDeleteAmeacaIndex] = React.useState(null);
  const [openFraquezaModal, setOpenFraquezaModal] = React.useState(false);
  const [deleteOportunidadeId, setDeleteOportunidadeId] = React.useState(0);
  const [deleteFraquezaIndex, setDeleteFraquezaIndex] = React.useState(null);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const [openOportunidadeModal, setOpenOportunidadeModal] = React.useState(false);
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const [deleteOportunidadeIndex, setDeleteOportunidadeIndex] = React.useState(null);
  const { startOrganograma, salvarOrganograma } = new Organograma(router.query.id as unknown as number);
  const { itemsMenu, selectedItemsMenu, completCode, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId } = usePreparationOfPlans();
  const { SummaryDiagnosisElaboration, GetSummaryDiagnosisElaboration, PostProgressCode, DeleteSummaryDiagnosisElaborations } = new ElaborationService();

  function getMessageErrorByNameRef(json: any, title: any, index: any) {
    if (Object.keys(json).length == 0) return null;

    const foundObject = json.sintese[0];

    return foundObject[title]?.length > 0 ? foundObject[title][index]['texto']?.message : null
  }

  function formatString(text: any) {
    let formattedText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

    if (formattedText === "Forcas") {
      formattedText = "Forças";
    } else if (formattedText === "Ameacas") {
      formattedText = "Ameaças";
    } else if (formattedText === 'Diagnostico') {
      formattedText = 'Diagnóstico'
    }

    return formattedText;
  }

  React.useEffect(() => {
    if (router.query.id && (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos'))) {
      setLoading(true);
      GetSummaryDiagnosisElaboration(Number(router.query.id)).then((res: any) => {
        const result = transformDiagnosisLayer(res?.data);
        if (Object.keys(result).length > 0) {
          setValueDiagnosis("sintese", [result]);
          setValueDiagnosis('file_pdf', res.data.filePdf);
          setValueDiagnosis('file_word', res.data.fileWord);
        } else {
          setValueDiagnosis('sintese', [defaultValue])
        }
      }).finally(() => setLoading(false));
    }
  }, [router.query.id, router.pathname, completCode]);

  React.useEffect(() => {
    if ((router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) && planId && tipoFluxo == "substituir") {
      GetSummaryDiagnosisElaboration(planId).then((res: any) => {
        const result = getTransformToDiagnosisLayer(res.data);
        setData([result])
      })
    }
  }, [router.pathname, planId, tipoFluxo])

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'sintese_diagnostico');

    if (code != undefined) {
      setDisable(true);
    } else {
      setDisable(false)
    }
  }, [completCode])

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'sintese_diagnostico' && item.skipped == false);
    const codePdf = completCode.find(item => item.code == 'sintese_cenario' && item.isPdf == true);

    if (code != undefined) {
      setValueDiagnosis('is_optional', true)
    }

    if (codePdf?.isPdf) {
      setValueDiagnosis("is_complete_pdf", false);
      setDisablePdf(true)
    }
  }, [completCode]);

  React.useEffect(() => {
    if (zustandSelectedCardItem.title !== 'Plano Orçamentário') {
      setValueDiagnosis('is_optional', true)
    }
  }, [zustandSelectedCardItem, completCode])

  const onSubmit: SubmitHandler<any> = React.useCallback(async (data: any, event: any) => {
    const { sintese, file_pdf, file_word } = data;
    const code = completCode.find(item => item.code == 'sintese_diagnostico');

    if (disabeSubmit) return;

    setDisableSubmit(true);

    const formData = new FormData();

    if (file_pdf?.length > 0 && typeof file_pdf != 'string') {
      if (file_pdf) formData.append('file_pdf', file_pdf[0]);
    }

    if (file_word?.length > 0 && typeof file_word != 'string') {
      if (file_word) formData.append('file_word', file_word[0]);
    }

    const transformedArray = (Object.entries(sintese[0]) as [any, any][]).flatMap(([key, values]: [key: string, values: any]) =>
      (values as any[]).map(value => {
        const newKey = formatString(key);
        if (value.id) {
          return {
            texto: `${newKey}: ${value.texto}`,
            id: value.id
          };
        } else {
          return {
            texto: `${newKey}: ${value.texto}`
          };
        }
      })
    );

    formData.append('itens', JSON.stringify(transformedArray));

    SummaryDiagnosisElaboration({
      data: formData,
      id: Number(router.query.id)
    }).then(async (res) => {
      toggleSuccessSnackbar();

      const validatePdf = () => {
        if (typeof is_complete_pdf == 'string') {
          return is_complete_pdf == 'true' ? false : true
        } else {
          return is_complete_pdf == true ? false : true
        }
      };

      PostProgressCode({ code: 'sintese_diagnostico', skipped: true, pdf: validatePdf(), isPdf: validatePdf() }, router.query.id)

      if (event.nativeEvent.submitter.name == 'next_step') {
        setNavigable(true);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
        handleFetchProgressCodePlanId(router.query.id);
      } else {
        setNavigable(false);
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id);
        handleFetchProgressCodePlanId(router.query.id);
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000);
    }).catch((err: any) => {
      toggleErrorSnackbar();
      setDisableSubmit(false);
    });

    setDisableSubmit(false);

    if (code != undefined) return;

    try {
      startOrganograma([
        {
          "name": "Síntese Diagnóstico",
          "position": "",
          "manager": "Organograma",
          "tooltip": "",
          "subordinates": []
        }
      ]);

      salvarOrganograma();

    } catch (err: any) {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  }, [completCode, zustandSelectedCardItem, is_optional])

  const FileInputs = React.useCallback(() => {
    if (is_complete_pdf == undefined) return null
    if (is_complete_pdf == 'false' || Boolean(is_complete_pdf) == false) {

      const handleFileValidate = (file: any) => {
        if (typeof file == "object") {
          return file.length > 0 ? file : undefined;
        } else {
          return file?.length > 0 && !file?.split('_').includes('null') ? file : undefined
        }
      }

      return (
        <>
          <Grid item xs={6}>
            <FileFieldElaboration
              accept=".pdf"
              name="file_pdf"
              required={false}
              errors={errosDiagnosis}
              register={registerDiagnosis}
              context='sintese_diagnostico'
              planId={router.query.id}
              label="Carregar PDF do diagnóstico completo"
              downloadName={handleFileValidate(pdfFile)}
            />
          </Grid>
          <Grid item xs={6}>
            <FileFieldElaboration
              accept=".docx"
              name="file_word"
              required={false}
              errors={errosDiagnosis}
              register={registerDiagnosis}
              context='sintese_diagnostico'
              planId={router.query.id}
              label="Carregar Word de concepção do diagnóstico"
              downloadName={handleFileValidate(wordFile)}
            />
          </Grid>
        </>
      );

    }
  }, [is_complete_pdf, wordFile, pdfFile, wordFile]);

  const Fields = React.useMemo(() => {
    if (Boolean(is_optional) == false || is_optional == undefined) return null;
    if (is_optional == 'false' || is_optional == undefined) return null;

    return fields.map((field: any, fieldIndex: any) => {
      const randomId = Math.random();
      const texto = watchDiagnosis(`sintese.${fieldIndex}.diagnostico.${0}.texto`)
      return (
        <React.Fragment key={randomId}>
          <Grid item xs={12}>
            <TextFieldElaboration
              required={true}
              rows={3}
              multiline={true}
              commentTittle={texto}
              errors={errosDiagnosis}
              register={registerDiagnosis}
              placeholder="Digite a síntese"
              label="Síntese do diagnóstico"
              name={`sintese.${fieldIndex}.diagnostico.${0}.texto`}
            />
            <div className="error_wrapper">
              <span>{getMessageErrorByNameRef(errosDiagnosis, 'diagnostico', 0)}</span>
            </div>
          </Grid>
          {field?.forcas?.map((forca: any, forcaIndex: any, arr: any[]) => {
            const randomId = Math.random()
            const lastIndex = arr.length - 1
            if (forcaIndex == 0) {
              return (
                <React.Fragment key={randomId}>
                  <input
                    type="hidden"
                    defaultValue={forca.id}
                    {...registerDiagnosis(`sintese.${fieldIndex}.forcas.${forcaIndex}.id`, { value: forca.id })}
                  />
                  <Grid item xs={12}>
                    <TextFieldElaboration
                      required={false}
                      label="Matriz SWOT"
                      errors={errosDiagnosis}
                      commentTittle={texto}
                      subLabel="Forças (Strengths)"
                      register={registerDiagnosis}
                      placeholder="Digite a força"
                      name={`sintese.${fieldIndex}.forcas.${forcaIndex}.texto`}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosDiagnosis, 'forcas', forcaIndex)}</span>
                    </div>
                  </Grid>
                  {lastIndex == forcaIndex && (
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <div className="add_line_wrapper">
                        <p onClick={() => {
                          const newData = [...field.forcas, { id: '', texto: '' }];
                          updateDiagnosis(fieldIndex, { ...field, forcas: newData });
                        }}>
                          {`+ Adicionar linha`}
                        </p>
                      </div>
                    </Grid>
                  )}
                </React.Fragment>
              );
            }

            if (forcaIndex != 0) {
              const randomId = Math.random()
              return (
                <React.Fragment key={randomId}>
                  <input
                    type="hidden"
                    defaultValue={forca.id}
                    {...registerDiagnosis(`sintese.${fieldIndex}.forcas.${forcaIndex}.id`, { value: forca.id })}
                  />
                  <Grid item xs={12} display='flex' alignItems='center' gap='8px'>
                    <TextFieldElaboration
                      required={false}
                      commentTittle={texto}
                      errors={errosDiagnosis}
                      register={registerDiagnosis}
                      placeholder="Digite a força"
                      name={`sintese.${fieldIndex}.forcas.${forcaIndex}.texto`}
                    />
                    {forcaIndex != 0 && (
                      <div onClick={() => {
                        setOpenForcaModal(true);
                        setDeleteForcaIndex(forcaIndex)
                        setDeleteForcaId(forca.id);
                      }} style={{ marginTop: '-.5rem', display: 'flex', alignItems: 'center' }} className="delete-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                        </svg>
                      </div>
                    )}
                  </Grid>
                  <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
                    <span>{getMessageErrorByNameRef(errosDiagnosis, 'forcas', forcaIndex)}</span>
                  </div>
                  <DeleteElaborationDialog open={openForcaModal} setOpen={(e) => setOpenForcaModal(e)} onDelete={() => {
                    const forcas = [...field.forcas].filter((item, index) => index != deleteForcaIndex) as any;
                    updateDiagnosis(fieldIndex, {
                      ...field,
                      forcas
                    })

                    if (deleteForcaId != 0) {
                      DeleteSummaryDiagnosisElaborations(deleteForcaId)
                      setDeleteForcaId(0)
                    }
                    setOpenForcaModal(false);
                  }} />
                  {arr.length > 1 && lastIndex == forcaIndex && (
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <div className="add_line_wrapper">
                        <p onClick={() => {
                          const newData = [...field.forcas, ''];
                          updateDiagnosis(fieldIndex, { ...field, forcas: newData });
                        }}>
                          {`+ Adicionar linha`}
                        </p>
                      </div>
                    </Grid>
                  )}
                </React.Fragment>
              );
            }
          })}

          {field?.fraquezas?.map((forca: any, forcaIndex: any, arr: any[]) => {
            const lastIndex = arr.length - 1
            const randomId = Math.random()

            if (forcaIndex == 0) {
              return (
                <React.Fragment key={randomId}>
                  <input
                    type="hidden"
                    defaultValue={forca.id}
                    {...registerDiagnosis(`sintese.${fieldIndex}.fraquezas.${forcaIndex}.id`, { value: forca.id })}
                  />
                  <Grid item xs={12}>
                    <TextFieldElaboration
                      required={false}
                      errors={errosDiagnosis}
                      label="Fraquezas (Weaknesses)"
                      register={registerDiagnosis}
                      commentTittle={texto}
                      placeholder="Digite a fraqueza"
                      name={`sintese.${fieldIndex}.fraquezas.${forcaIndex}.texto`}
                    />
                    <div className="error_wrapper" >
                      <span>{getMessageErrorByNameRef(errosDiagnosis, 'fraquezas', forcaIndex)}</span>
                    </div>
                  </Grid>
                  {lastIndex == forcaIndex && (
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <div className="add_line_wrapper">
                        <p onClick={() => {
                          const newData = [...field.fraquezas, { id: '', texto: '' }];
                          updateDiagnosis(fieldIndex, { ...field, fraquezas: newData });
                        }}>
                          {`+ Adicionar linha`}
                        </p>
                      </div>
                    </Grid>
                  )}
                </React.Fragment>
              );
            }

            if (forcaIndex != 0) {
              const randomId = Math.random()
              return (
                <React.Fragment key={randomId}>
                  <input
                    type="hidden"
                    defaultValue={forca.id}
                    {...registerDiagnosis(`sintese.${fieldIndex}.fraquezas.${forcaIndex}.id`, { value: forca.id })}
                  />
                  <Grid item xs={12} display='flex' alignItems='center' gap='8px'>
                    <TextFieldElaboration
                      required={false}
                      errors={errosDiagnosis}
                      register={registerDiagnosis}
                      commentTittle={texto}
                      placeholder="Digite a fraqueza"
                      name={`sintese.${fieldIndex}.fraquezas.${forcaIndex}.texto`}
                    />
                    {forcaIndex != 0 && (
                      <div onClick={() => {
                        setOpenFraquezaModal(true);
                        setDeleteFraquezaIndex(forcaIndex);
                        setDeleteFraquezaId(forca.id)
                      }} style={{ marginTop: '-.5rem', display: 'flex', alignItems: 'center' }} className="delete-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                        </svg>
                      </div>
                    )}
                  </Grid>
                  <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
                    <span>{getMessageErrorByNameRef(errosDiagnosis, 'fraquezas', forcaIndex)}</span>
                  </div>
                  <DeleteElaborationDialog open={openFraquezaModal} setOpen={(e) => setOpenFraquezaModal(e)} onDelete={() => {
                    const fraquezas = [...field.fraquezas].filter((item, index) => index != deleteFraquezaIndex) as any;
                    updateDiagnosis(fieldIndex, {
                      ...field,
                      fraquezas
                    })

                    if (deleteFraquezaId != 0) {
                      DeleteSummaryDiagnosisElaborations(deleteFraquezaId)
                      setDeleteFraquezaId(0)
                    }

                    setOpenFraquezaModal(false);
                  }} />
                  {arr.length > 1 && lastIndex == forcaIndex && (
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <div className="add_line_wrapper">
                        <p onClick={() => {
                          const newData = [...field.fraquezas, ''];
                          updateDiagnosis(fieldIndex, { ...field, fraquezas: newData });
                        }}>
                          {`+ Adicionar linha`}
                        </p>
                      </div>
                    </Grid>
                  )}
                </React.Fragment>
              );
            }
          })}

          {field?.oportunidades?.map((forca: any, forcaIndex: any, arr: any[]) => {
            const lastIndex = arr.length - 1
            const randomId = Math.random()
            if (forcaIndex == 0) {
              return (
                <React.Fragment key={randomId}>
                  <input
                    type="hidden"
                    defaultValue={forca.id}
                    {...registerDiagnosis(`sintese.${fieldIndex}.oportunidades.${forcaIndex}.id`, { value: forca.id })}
                  />
                  <Grid item xs={12}>
                    <TextFieldElaboration
                      required={false}
                      commentTittle={texto}
                      errors={errosDiagnosis}
                      label="Oportunidades (Opportunities)"
                      register={registerDiagnosis}
                      placeholder="Digite a oportunidadee"
                      name={`sintese.${fieldIndex}.oportunidades.${forcaIndex}.texto`}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosDiagnosis, 'oportunidades', forcaIndex)}</span>
                    </div>
                  </Grid>
                  {lastIndex == forcaIndex && (
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <div className="add_line_wrapper">
                        <p onClick={() => {
                          const newData = [...field.oportunidades, { id: '', texto: '' }];
                          updateDiagnosis(fieldIndex, { ...field, oportunidades: newData });
                        }}>
                          {`+ Adicionar linha`}
                        </p>
                      </div>
                    </Grid>
                  )}
                </React.Fragment>
              );
            }

            if (forcaIndex != 0) {
              const randomId = Math.random()
              return (
                <React.Fragment key={randomId}>
                  <input
                    type="hidden"
                    defaultValue={forca.id}
                    {...registerDiagnosis(`sintese.${fieldIndex}.oportunidades.${forcaIndex}.id`, { value: forca.id })}
                  />
                  <Grid item xs={12} display='flex' alignItems='center' gap='8px'>
                    <TextFieldElaboration
                      required={false}
                      errors={errosDiagnosis}
                      register={registerDiagnosis}
                      commentTittle={texto}
                      placeholder="Digite a oportunidade"
                      name={`sintese.${fieldIndex}.oportunidades.${forcaIndex}.texto`}
                    />
                    {forcaIndex != 0 && (
                      <div onClick={() => {
                        setOpenOportunidadeModal(true);
                        setDeleteOportunidadeIndex(forcaIndex)
                        setDeleteOportunidadeId(forca.id)
                      }} style={{ marginTop: '-.5rem', display: 'flex', alignItems: 'center' }} className="delete-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                        </svg>
                      </div>
                    )}
                  </Grid>
                  <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
                    <span>{getMessageErrorByNameRef(errosDiagnosis, 'oportunidades', forcaIndex)}</span>
                  </div>
                  <DeleteElaborationDialog open={openOportunidadeModal} setOpen={(e) => setOpenOportunidadeModal(e)} onDelete={() => {
                    const oportunidades = [...field.oportunidades].filter((item, index) => index != deleteOportunidadeIndex) as any;
                    updateDiagnosis(fieldIndex, {
                      ...field,
                      oportunidades
                    })

                    if (deleteOportunidadeId != 0) {
                      DeleteSummaryDiagnosisElaborations(deleteOportunidadeId)
                      setDeleteOportunidadeId(0)
                    }

                    setOpenOportunidadeModal(false);
                  }} />
                  {arr.length > 1 && lastIndex == forcaIndex && (
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <div className="add_line_wrapper">
                        <p onClick={() => {
                          const newData = [...field.oportunidades, ''];
                          updateDiagnosis(fieldIndex, { ...field, oportunidades: newData });
                        }}>
                          {`+ Adicionar linha`}
                        </p>
                      </div>
                    </Grid>
                  )}
                </React.Fragment>
              );
            }
          })}

          {field?.ameacas?.map((forca: any, forcaIndex: any, arr: any[]) => {
            const lastIndex = arr.length - 1
            const randomId = Math.random()
            if (forcaIndex == 0) {
              return (
                <React.Fragment key={randomId}>
                  <input
                    type="hidden"
                    defaultValue={forca.id}
                    {...registerDiagnosis(`sintese.${fieldIndex}.ameacas.${forcaIndex}.id`, { value: forca.id })}
                  />
                  <Grid item xs={12}>
                    <TextFieldElaboration
                      required={false}
                      commentTittle={texto}
                      errors={errosDiagnosis}
                      label="Ameaças (Threats)"
                      register={registerDiagnosis}
                      placeholder="Digite a ameaça"
                      name={`sintese.${fieldIndex}.ameacas.${forcaIndex}.texto`}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosDiagnosis, 'ameacas', forcaIndex)}</span>
                    </div>
                  </Grid>
                  {lastIndex == forcaIndex && (
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <div className="add_line_wrapper">
                        <p onClick={() => {
                          const newData = [...field.ameacas, { id: '', texto: '' }];
                          updateDiagnosis(fieldIndex, { ...field, ameacas: newData });
                        }}>
                          {`+ Adicionar linha`}
                        </p>
                      </div>
                    </Grid>
                  )}
                </React.Fragment>
              );
            }

            if (forcaIndex != 0) {
              const randomId = Math.random()
              return (
                <React.Fragment key={randomId}>
                  <input
                    type="hidden"
                    defaultValue={forca.id}
                    {...registerDiagnosis(`sintese.${fieldIndex}.ameacas.${forcaIndex}.id`, { value: forca.id })}
                  />
                  <Grid item xs={12} display='flex' alignItems='center' gap='8px'>
                    <TextFieldElaboration
                      required={false}
                      errors={errosDiagnosis}
                      register={registerDiagnosis}
                      commentTittle={texto}
                      placeholder="Digite a ameaça"
                      name={`sintese.${fieldIndex}.ameacas.${forcaIndex}.texto`}
                    />
                    {forcaIndex != 0 && (
                      <div onClick={() => {
                        setOpenAmeacaModal(true)
                        setDeleteAmeacaIndex(forcaIndex);
                        setDeleteAmeacaId(forca.id)
                      }} style={{ marginTop: '-.5rem', display: 'flex', alignItems: 'center' }} className="delete-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                        </svg>
                      </div>
                    )}
                  </Grid>
                  <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
                    <span>{getMessageErrorByNameRef(errosDiagnosis, 'ameacas', forcaIndex)}</span>
                  </div>
                  <DeleteElaborationDialog open={openAmeacaModal} setOpen={(e) => setOpenAmeacaModal(e)} onDelete={() => {
                    const ameacas = [...field.ameacas].filter((item, index) => index != deleteAmeacaIndex) as any;
                    updateDiagnosis(fieldIndex, {
                      ...field,
                      ameacas
                    })

                    if (deleteAmeacaId != 0) {
                      DeleteSummaryDiagnosisElaborations(deleteAmeacaId)
                      setDeleteAmeacaId(0)
                    }

                    setOpenAmeacaModal(false);
                  }} />
                  {arr.length > 1 && lastIndex == forcaIndex && (
                    <Grid item xs={12} sx={{ mt: -1 }}>
                      <div className="add_line_wrapper">
                        <p onClick={() => {
                          const newData = [...field.ameacas, { id: '', texto: '' }];
                          updateDiagnosis(fieldIndex, { ...field, ameacas: newData });
                        }}>
                          {`+ Adicionar linha`}
                        </p>
                      </div>
                    </Grid>
                  )}
                </React.Fragment>
              );
            }
          })}

          <Grid item xs={12}>
            <RadioCheckedElaboration
              required={false}
              commentTittle={texto}
              disabled={disablePdf}
              name="is_complete_pdf"
              errors={errosDiagnosis}
              control={controlDiagnosis}
              label="O PDF do Plano contém o documento completo sobre os cenários?"
              data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
            />
          </Grid>
          {FileInputs()}
        </React.Fragment>
      );
    })
  }, [fields, is_optional, errosDiagnosis, is_complete_pdf, openForcaModal, openAmeacaModal, openFraquezaModal, openOportunidadeModal, disablePdf])

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, reutilizar, openDisclaimer, data]);

  const SubmitButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'true' ? 'true' : 'false'
      } else {
        return Boolean(is_optional) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'false' || is_optional == undefined;
      } else {
        return Boolean(is_optional) == false || is_optional == undefined;
      }
    }

    return (
      <ActionButtonElaborations disable={disabeSubmit} isOptional={opitional()} layer_indicator={false} onClick={() => {
        if (optionalV2()) {
          setNavigable(true)

          router.push('/dashboard/elaboracao_planos/' + router.query.id);
          PostProgressCode({ code: 'sintese_diagnostico', skipped: true }, router.query.id)
          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          if (plan && plan[selectedItemsMenu?.layer as string]) {
            const planId = plan[selectedItemsMenu?.layer as string]
              .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
            setDefaultItemMenuId(planId);
          }
        }
      }} />
    )
  }, [is_optional, disabeSubmit]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitDiagnosis(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          {zustandSelectedCardItem.title == 'Plano Orçamentário' && (
            <Grid item xs={12}>
              <RadioCheckedElaboration
                required={false}
                name="is_optional"
                disabled={disable}
                tooltip="lorem ipsum"
                errors={errosDiagnosis}
                control={controlDiagnosis}
                defaultValue={is_optional == 'true' ? 'true' : 'false'}
                data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
              />
            </Grid>
          )}
          {ReplaceButton()}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {Fields}
          <Grid item xs={12}>
            {SubmitButton()}
          </Grid>
        </Grid>
        <ReutilizarElaborationDialog reuseData={data} title="Síntese do diagnóstico" open={reutilizar} setOpen={() => setReutilizar(false)} setValue={(e) => {
          e.forEach((item: any) => {
            item?.diagnostico.forEach(({ id, ...rest }: any, index: any) => {
              setValueDiagnosis(`sintese.${0}.diagnostico.${index}`, rest);
            })
            item?.ameacas.forEach(({ id, ...rest }: any, index: any) => {
              setValueDiagnosis(`sintese.${0}.ameacas.${index}`, rest);
            })
            item?.fraquezas.forEach(({ id, ...rest }: any, index: any) => {
              setValueDiagnosis(`sintese.${0}.fraquezas.${index}`, rest);
            })
            item?.forcas.forEach(({ id, ...rest }: any, index: any) => {
              setValueDiagnosis(`sintese.${0}.forcas.${index}`, rest);
            })
            item?.oportunidades.forEach(({ id, ...rest }: any, index: any) => {
              setValueDiagnosis(`sintese.${0}.oportunidades.${index}`, rest);
            })
          })

        }} />
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
      </Container>
    </form>
  )
}
