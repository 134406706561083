export const Svg_008 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={59}
    fill="none"
  >
    <path
      fill="url(#a)"
      d="M35.703 43.133H59V3.945H37.61a9.796 9.796 0 0 0-6.381 2.356v38.218a7.887 7.887 0 0 1 4.474-1.386Zm8.142-28.814h9.8v3.457h-9.8V14.32Zm-7.262 0h3.458v3.457h-3.458V14.32Zm0 8.068h17.062v3.457H36.583v-3.457Zm0 8.068h17.062v3.458H36.583v-3.458Z"
    />
    <path
      fill="url(#b)"
      d="M27.771 44.52V6.3a9.796 9.796 0 0 0-6.38-2.355H0v39.189h23.297c1.658 0 3.2.511 4.474 1.385ZM15.155 33.912h-9.8v-3.458h9.8v3.458Zm7.262 0h-3.458v-3.458h3.458v3.458Zm0-8.068H5.355v-3.458h17.062v3.457Zm0-8.069H5.355V14.32h17.062v3.457Z"
    />
    <path
      fill="url(#c)"
      d="M23.297 46.591h-5.083v4.61h9.557v-.136a4.48 4.48 0 0 0-4.474-4.474Z"
    />
    <path
      fill="url(#d)"
      d="M31.229 51.066v.136H59v-4.61H35.703a4.48 4.48 0 0 0-4.474 4.474Z"
    />
    <path fill="url(#e)" d="M10.146 46.591h4.61v8.464h-4.61V46.59Z" />
    <path fill="url(#f)" d="M0 46.591h6.688v4.61H0v-4.61Z" />
    <defs>
      <linearGradient
        id="a"
        x1={37.651}
        x2={59.088}
        y1={10.031}
        y2={33.779}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={6.422}
        x2={27.86}
        y1={10.031}
        y2={33.779}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={20.424}
        x2={21.765}
        y1={47.283}
        y2={51.781}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={37.651}
        x2={38.148}
        y1={47.283}
        y2={52.129}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={11.212}
        x2={15.672}
        y1={47.861}
        y2={51.792}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={1.547}
        x2={3.312}
        y1={47.283}
        y2={51.428}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
    </defs>
  </svg>
)