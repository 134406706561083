import React from 'react';
import { GoalsContext } from '@/contexts/elaboracao_planos/forms/metas';
import { VisionContext } from '@/contexts/elaboracao_planos/forms/visao';
import { PreparationOfPlansContext } from '@/contexts/elaboracao_planos';
import { ValuesContext } from '@/contexts/elaboracao_planos/forms/valores';
import { ProjectsContext } from '@/contexts/elaboracao_planos/forms/projetos';
import { ProgramsContext } from '@/contexts/elaboracao_planos/forms/programas';
import { StrategyContext } from '@/contexts/elaboracao_planos/forms/estrategia';
import { GuidelinesContext } from '@/contexts/elaboracao_planos/forms/diretriz';
import { ObjectivesContext } from '@/contexts/elaboracao_planos/forms/objetivos';
import { AspirationContext } from '@/contexts/elaboracao_planos/forms/aspiracao';
import { ActivitesContext } from '@/contexts/elaboracao_planos/forms/atividates';
import { MacroactionsContext } from '@/contexts/elaboracao_planos/forms/macroacoes';
import { InitiativesContext } from '@/contexts/elaboracao_planos/forms/iniciativas';
import { PreRegisterContext } from '@/contexts/elaboracao_planos/forms/pre_register';
import { ScenarioContext } from '@/contexts/elaboracao_planos/forms/sintese_cenario';
import { ThematicAxisContext } from '@/contexts/elaboracao_planos/forms/eixo_tematico';
import { DiagnosisContext } from '@/contexts/elaboracao_planos/forms/sintese_diagnostico';
import { MissionPurposeContext } from '@/contexts/elaboracao_planos/forms/missao_proposito';
import { HierarchyPreparationOfPlansContext } from '@/contexts/elaboracao_planos/hierarquia';
import { ProductsDeliveriesContext } from '@/contexts/elaboracao_planos/forms/produtos_entregas';

export const useGoalsForm = () => React.useContext(GoalsContext);
export const useVisionForm = () => React.useContext(VisionContext);
export const useValuesForm = () => React.useContext(ValuesContext);
export const useScenarioForm = () => React.useContext(ScenarioContext);
export const useProjectsForm = () => React.useContext(ProjectsContext);
export const useStrategyForm = () => React.useContext(StrategyContext);
export const useProgramsForm = () =>  React.useContext(ProgramsContext);
export const useDiagnosisForm = () => React.useContext(DiagnosisContext);
export const useGuidelineForm = () => React.useContext(GuidelinesContext);
export const useActivitiesForm = () => React.useContext(ActivitesContext);
export const useAspirationForm = () => React.useContext(AspirationContext);
export const useObjectivesForm = () => React.useContext(ObjectivesContext);
export const usePreRegisterForm = () => React.useContext(PreRegisterContext);
export const useInitiavitesForm = () =>  React.useContext(InitiativesContext);
export const useThematicAxisForm = () => React.useContext(ThematicAxisContext);
export const useMacroactionsForm = () =>  React.useContext(MacroactionsContext);
export const useMissionPurposeForm = () => React.useContext(MissionPurposeContext);
export const usePreparationOfPlans = () => React.useContext(PreparationOfPlansContext);
export const useProductsDeliveriesForm = () => React.useContext(ProductsDeliveriesContext);
export const useHierarchyPreparationOfPlans = () => React.useContext(HierarchyPreparationOfPlansContext);