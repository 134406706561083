export const addItemToNodeOrganogram = (organograma: any, targetName: any, newItem: any) => {
  function findAndAdd(node: any) {
    if (node.name === targetName) {
      node.subordinates.push(newItem);
      return true;
    }
    
    if (node.subordinates && node.subordinates.length > 0) {
      for (let subordinate of node.subordinates) {
        if (findAndAdd(subordinate)) {
          return true;
        }
      }
    }
    return false;
  }

  for (let node of organograma) {
    if (findAndAdd(node)) {
      break;
    }
  }

  return organograma; 
}