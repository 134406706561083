import React from "react"
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { isNumber } from "lodash";
import { Grid } from '@mui/material'
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ReutilizarElaborationDialogV2 } from "../Vision/ReutilizarModal";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DeleteIcon, transformGuidelineAspirationStrategy } from "../utils";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { Organograma } from "@/utils/organograma/Organograma";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";

type InputsAspiration = {
  aspiracao: any;
};

function validateArray(array: any) {
  // Verifica se o parâmetro é um array
  if (!Array.isArray(array)) {
    throw new TypeError('O parâmetro deve ser um array.');
  }

  // Percorre cada array na camada externa
  for (let i = 0; i < array.length; i++) {
    const innerArray = array[i];

    // Verifica se algum item no array interno tem tanto o texto quanto a data preenchidos
    const hasValidItem = innerArray.some((item: any) => {
      const hasTexto = item.texto !== null;
      const hasData = item.data !== null;

      // Considera válido apenas se ambos, texto e data, estiverem preenchidos
      return hasTexto && hasData;
    });

    // Se encontrar um item válido, retorna sem erro
    if (hasValidItem) {
      return { error: null };
    }
  }

  // Retorna mensagem de erro e índice do array interno que falhou
  return { error: 'Ao menos um conjunto deve ter tanto o campo "texto" quanto o campo "data" preenchidos.', index: array.length > 0 ? [array.length - 1, 0] : [0, 0] };
}

const SchemaAspiration = Yup.object().shape({
  aspiracao: Yup.array().required('Campo obrigatório')
});

const defaultValue = {
  id: '',
  texto: '',
  data: null,
}

export const AspirationForm: React.FC = () => {
  const {
    watch: watchAspiration,
    control: controlAspiration,
    register: registerAspiration,
    setError: setAspirationError,
    setValue: setValueAspiration,
    handleSubmit: handleSubmitAspiration,
    formState: { errors: errosAspiration } } = useForm<InputsAspiration>({
      resolver: yupResolver(SchemaAspiration) as any,
      defaultValues: { aspiracao: [[defaultValue]] }
    });

  const { update: updateAspiration } = useFieldArray({
    control: controlAspiration,
    name: "aspiracao"
  });

  const fields = watchAspiration("aspiracao");

  const router = useRouter();
  const [data, setData] = React.useState<any[]>([]);
  const [deleteId, setDeleteId] = React.useState(0);
  const replaceButtonRef = React.useRef(null) as any;
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [loading, setLoading] = React.useState(false);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [fieldIndexItem, setFieldIndexItem] = React.useState(0);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteStrategyId, setDeleteStrategyId] = React.useState(null);
  const [reuseClickedIndex, setReuseClickedIndex] = React.useState(null);
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const { AspirationElaboration, GetThematicAxisElaboration, GetAspirationElaboration, DeleteAspirationElaborations } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, completCode, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId } = usePreparationOfPlans();

  const handleAddLine = (field: any, it: any, fieldIndex: any) => {
    const newPlanejados = [...field, { texto: '', data: null, eixo_tematico_id: it.eixo_tematico_id }];
    updateAspiration(fieldIndex, newPlanejados);
  };

  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const { id } = router.query;
      const { pathname } = router;

      if (id && pathname.includes('/elaboracao_planos') || pathname.includes('/aprovacao_planos')) {
        try {
          const { data: axisData } = await GetThematicAxisElaboration(router.query.id);
          const axisLabel = axisData.map((item: any) => ({
            label: item.texto,
            eixo_tematico_id: item.id,
          }));

          const { data } = await GetAspirationElaboration(id);
          const parsedData = data.filter((item: any) => item?.eixoTematico);

          const transformedArray = transformGuidelineAspirationStrategy(parsedData, axisLabel);

          if (transformedArray.length > 0) {
            setValueAspiration('aspiracao', transformedArray);
            setLoading(false);
          }

        } catch (error) {
          console.error('Failed to fetch aspiration elaboration data:', error);
        }
      }
    };

    fetchData();
  }, [router.query.id, router.pathname, completCode]);

  React.useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      const { pathname } = router;

      if (planId && (pathname.includes('/elaboracao_planos') || pathname.includes('/aprovacao_planos')) && tipoFluxo == 'substituir') {
        try {
          const { data: axisData } = await GetThematicAxisElaboration(planId);
          const axisLabel = axisData.map((item: any) => ({
            label: item.texto,
            eixo_tematico_id: item.id,
          }));


          const { data } = await GetAspirationElaboration(planId);

          const parsedData = data.filter((item: any) => item?.eixoTematico);

          if (parsedData.length > 0) {
            const transformedArray = parsedData.map((item: any) => ({ data: item.data, texto: item.texto }))

            setData(transformedArray);
            setLoading(false);
          }

        } catch (error) {
          console.error('Failed to fetch aspiration elaboration data:', error);
        }
      }
    };

    fetchData();
  }, [planId, router.pathname, tipoFluxo, completCode]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router])

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const { insertItemById, findNewItemsWithId, deleteItemById } = useHierarchyPreparationOfPlans();
  const { startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { aspiracao } = data;
    const aspiracaoCode = completCode.find(item => item.code == 'aspiracao')

    if (disabeSubmit) return;

    setDisableSubmit(true);

    const { error } = validateArray(aspiracao);

    if (error != null) {
      setAspirationError('root', { message: error });
      return;
    }

    const filteredArrays = aspiracao.map((subArray: any) =>
      subArray.filter((item: any) => !(item.texto === null || item.texto === "" && item.data === null))
    ).filter((subArray: any) => subArray.length > 0).flat();


    const newItem = filteredArrays.map((item: any) => {
      const { id, label, plano, ...res } = item;

      if (!isNumber(id)) {
        return {
          ...res,
          data: dayjs(item.data).format('YYYY-MM-DD') + 'T00:00:00'
        }
      } else {
        return {
          ...res,
          id,
          data: dayjs(item.data).format('YYYY-MM-DD') + 'T00:00:00',
        }
      }
    })

    try {
      const res = await AspirationElaboration({
        data: { itens: newItem },
        id: Number(router.query.id)
      });

      const response = res.data as any[];
      const relacionamento = await consultarRelacionamento()
      const newArray = findNewItemsWithId(newItem, response);
      const root = JSON.parse(JSON.parse(relacionamento));
      
      await Promise.all(newArray.map(async (item: any) => {
        const newInsertItem = insertItemById({ root, targetId: item.id, item: { id: item.id, relacionamento: {} }, layerName: 'eixos_tematicos' });
        startRelacionamento(JSON.stringify(newInsertItem));
        atualizarRelacionamento();
      }));
  
      toggleSuccessSnackbar();
      
      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);
  
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id === selectedItemsMenu?.id).id + 1;
        
        handleFetchProgressCodePlanId(router.query.id);
        setDefaultItemMenuId(planId);
  
      } else {
        setNavigable(false);
  
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id === selectedItemsMenu?.id).id;
  
        setDefaultItemMenuId(planId);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);
        handleFetchProgressCodePlanId(router.query.id);
      }
  
      setTimeout(() => toggleSuccessSnackbar(), 3000);
    } catch (error) {
      toggleErrorSnackbar();
    } finally {
      setDisableSubmit(false);
    }

    if (aspiracaoCode != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const newItem = {
        "name": "Aspiração",
        "position": "",
        "manager": 'Visão',
        "tooltip": "",
        "subordinates": []
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, 'Visão', newItem);

      startOrganograma(newOrganograma);
      salvarOrganograma();
    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');

    }
  };

  const Fields = React.useCallback(() => {
    return fields?.reduce((acc: any, field: any, fieldIndex: any) => {
      field.map((it: any, itIndex: any) => {
        const data = watchAspiration(`aspiracao.${fieldIndex}.${itIndex}.data`);
        const texto = watchAspiration(`aspiracao.${fieldIndex}.${itIndex}.texto`);

        acc.push(
          <React.Fragment key={it?.id || `${fieldIndex}-${itIndex}`}>
            <input
              type="hidden"
              {...registerAspiration(`aspiracao.${fieldIndex}.${itIndex}.id`, { value: it?.id })}
              defaultValue={it?.id}
            />
            {itIndex == 0 && (
              <Grid item xs={12}>
                {ReplaceButton(fieldIndex)}
              </Grid>

            )}
            <Grid item xs={4}>
              <TextFieldElaboration
                rows={3}
                required
                multiline
                defaultValue={texto}
                commentTittle={texto}
                errors={errosAspiration}
                register={registerAspiration}
                placeholder="Digite a aspiração"
                label={itIndex === 0 ? it?.label : undefined}
                name={`aspiracao.${fieldIndex}.${itIndex}.texto`}
              />
            </Grid>
            <Grid item xs={4} sx={{ mt: itIndex === 0 ? 0.5 : -2.8 }}>
              <DatePickerElaboration
                required
                defaultValue={data}
                errors={errosAspiration}
                commentTittle={texto}
                label="Prazo da aspiração"
                control={controlAspiration}
                name={`aspiracao.${fieldIndex}.${itIndex}.data`}
              />

            </Grid>
            <Grid item xs={4} sx={{ mt: -0.5 }}>
              {itIndex !== 0 && !preview && (
                <div onClick={() => {
                  setOpenDeleteModal(true);
                  setDeleteStrategyId(itIndex)
                  setFieldIndexItem(fieldIndex)
                  setDeleteId(it.id)
                }} style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                  <DeleteIcon />
                </div>
              )}
            </Grid>
            {itIndex === field.length - 1 && (
              <Grid item xs={12} sx={{ mt: -2 }}>
                <div className="add_line_wrapper">
                  <p onClick={() => handleAddLine(field, it, fieldIndex)}>
                    + Adicionar linha
                  </p>
                </div>
              </Grid>
            )}
            <DeleteElaborationDialog
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
              onDelete={async () => {
                updateAspiration(fieldIndexItem, fields[fieldIndexItem].filter((_: any, index: any) => index !== deleteStrategyId));
                setOpenDeleteModal(false);

                if (deleteId != 0) {
                  const relacionamento = await consultarRelacionamento();
                  const root = JSON.parse(JSON.parse(relacionamento));
                  
                  const newItem = deleteItemById({ root, layerName: 'aspiracao', targetId: deleteId });

                  startRelacionamento(JSON.stringify(newItem));
                  atualizarRelacionamento();

                  DeleteAspirationElaborations(deleteId);
                  setDeleteId(0)
                }

              }}
            />
          </React.Fragment>
        );

        return acc;
      });

      return acc;
    }, []);
  }, [fields, openDeleteModal, errosAspiration]);

  const ReplaceButton = React.useCallback((index: any) => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4} key={index}>
        <button className="recicle_content_button" type='button' onClick={() => {

          if (replaceButtonRef?.current?.reuseLen > 0) {
            setReutilizar(true);
            setReuseClickedIndex(index)
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, reutilizar, openDisclaimer, replaceButtonRef]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitAspiration(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          </Grid>
          {Fields()}
          <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
            <span>{errosAspiration.root?.message}</span>
          </div>
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={12}>
            <ActionButtonElaborations disable={disabeSubmit} isOptional="true" layer_indicator={false} />
          </Grid>
          <ReutilizarElaborationDialogV2
            reuseData={data}
            open={reutilizar}
            title="Aspiração"
            ref={replaceButtonRef}
            setOpen={() => setReutilizar(false)}
            setValue={(e) => {
              if (reuseClickedIndex != null) {
                e.map((item: any, index: any) => {
                  const { checked, ...res } = item
                  if (index == 0) {
                    setValueAspiration(`aspiracao.${reuseClickedIndex}.${index}.texto` as any, res.texto)
                    setValueAspiration(`aspiracao.${reuseClickedIndex}.${index}.data` as any, dayjs(res.data))
                    setValueAspiration(`aspiracao.${reuseClickedIndex}.${index}.label` as any, fields[reuseClickedIndex][0]?.label)
                    return;
                  }

                  setValueAspiration(`aspiracao.${reuseClickedIndex}.${index}.texto` as any, res.texto)
                  setValueAspiration(`aspiracao.${reuseClickedIndex}.${index}.data` as any, dayjs(res.data))
                });
              }
            }}
          />
          <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
        </Grid>
      </Container>
    </form>
  )
}
