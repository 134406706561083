import React from 'react';
import { PlanoSetorialIcon } from "./svgs/plano_setorial";
import { ImagePlanoEstadoIcon } from './svgs/image_plano_estado';
import { ImagePlanoGestaoIcon } from './svgs/image_plano_gestao';
import { PlanoOrcamentarioIcon } from "./svgs/plano_orcamentario";
import { PlanoInstitucionalIcon } from "./svgs/plano_institucional";
import { ImagePlanoSetorialIcon } from './svgs/image_plano_setorial';
import { ImagePlanoOrcamentarioIcon } from './svgs/image_plano_orcamentario';
import { ImagePlanoInstitucionalIcon } from './svgs/image_plano_institucional';
import { PlanoEstadoIcon } from "@/contexts/elaboracao_planos/svgs/plano_estado";
import { PreRegistrationForm } from '@/components/global/elaboracao_planos/Forms/PreRegister';
import { StrategicLayerForm } from '@/components/global/elaboracao_planos/Forms/StrategicLayer';
import { DiagnosisLayerForm } from '@/components/global/elaboracao_planos/Forms/DiagnossisLayer';
import { SummaryScenarioForm } from '@/components/global/elaboracao_planos/Forms/SummaryScenario';
import { SummaryDiagnosisForm } from '@/components/global/elaboracao_planos/Forms/SummaryDiagnosis';
import { VisionForm } from '@/components/global/elaboracao_planos/Forms/Vision';
import { ThematicAxisForm } from '@/components/global/elaboracao_planos/Forms/ThematicAxis';
import { AspirationForm } from '@/components/global/elaboracao_planos/Forms/Aspiration';
import { EstrategyForm } from '@/components/global/elaboracao_planos/Forms/Strategy';
import { ValuesForm } from '@/components/global/elaboracao_planos/Forms/Values';
import { GuidelinesForm } from '@/components/global/elaboracao_planos/Forms/Guidelines';
import { DescriptionMissionPurpose } from '@/components/global/elaboracao_planos/Forms/MissionPurpose/Description';
import { MissionPurposeForm } from '@/components/global/elaboracao_planos/Forms/MissionPurpose';
import { ObjectivesDemonstrationForm } from '@/components/global/elaboracao_planos/Forms/Objectives/demonstration';
import { ObjectivesForm } from '@/components/global/elaboracao_planos/Forms/Objectives';
import { GoalsDemonstrationForm } from '@/components/global/elaboracao_planos/Forms/Goals/demonstration';
import { GoalsForm } from '@/components/global/elaboracao_planos/Forms/Goals';
import { SimilarityDemonstrationForm } from '@/components/global/elaboracao_planos/Forms/Goals/Similarity/demonstration';
import { Similarity } from '@/components/global/elaboracao_planos/Forms/Goals/Similarity';
import { MacroactionDemonstrationForm } from '@/components/global/elaboracao_planos/Forms/Macroactions/demonstration';
import { MacroactionForm } from '@/components/global/elaboracao_planos/Forms/Macroactions';
import { SimilarityMacroactionDemonstrationForm } from '@/components/global/elaboracao_planos/Forms/Macroactions/Similarity/demonstration';
import { TaticLayerForm } from '@/components/global/elaboracao_planos/Forms/TaticLayer';
import { ProgramsForm } from '@/components/global/elaboracao_planos/Forms/Programs';
import { ProjectsForm } from '@/components/global/elaboracao_planos/Forms/Projets';
import { ProductsAndDeliveriesForm } from '@/components/global/elaboracao_planos/Forms/Products_Deliveries';
import { ActivitiesForm } from '@/components/global/elaboracao_planos/Forms/Activities';
import { InitiativesForm } from '@/components/global/elaboracao_planos/Forms/Initiatives';
import { ConclusionLayerForm } from '@/components/global/elaboracao_planos/Forms/ConclusionLayer';
import { FinalDataForm } from '@/components/global/elaboracao_planos/Forms/FinalData';
import { NextLinkingForm } from '@/components/global/elaboracao_planos/Forms/Linking';
import { PendingCheckLayerForm } from '@/components/global/elaboracao_planos/Forms/PendingCheck';
import { SendingPlanForm } from '@/components/global/elaboracao_planos/Forms/SendingPlan';
import { ConsiderationLayerForm } from '@/components/global/elaboracao_planos/Forms/Considerations';
import { PlanoGestaoIcon } from './svgs/plano_gestao';

export interface ICardProps {
  id: number;
  open: boolean;
  title: string;
  image: any;
  ref_id: number;
  description: string;
  icon: JSX.Element,
  elements: Array<any>,
}

export interface IHandlePermissionClickMenuItem {
  plan?: any,
  navigable?: boolean;
  id?: number,
  click?: boolean,
}

export const cardsItems: ICardProps[] = [
  {
    id: 1,
    open: false,
    icon: <PlanoEstadoIcon />,
    title: 'Plano de Estado',
    image: <ImagePlanoEstadoIcon />,
    ref_id: 3,
    description: `Plano de longo prazo, instituído por lei ou decreto, 
    com período de vigência de 10 anos ou mais, com caráter permanente 
    e sistêmico. Objetiva ordenar o desenvolvimento sustentável e integrado 
    das políticas públicas em atendimento às legislações específicas ou Acordos 
    Nacionais e Internacionais. Sua elaboração envolve o desenvolvimento de estudos 
    técnicos, cenários e tendências que devem nortear a construção das políticas de estado.`,
    elements: [],
  },
  {
    id: 2,
    open: false,
    ref_id: 1,
    icon: <PlanoSetorialIcon />,
    title: 'Plano Setorial',
    image: <ImagePlanoSetorialIcon />,
    description: `Instrumento que estabelece o planejamento detalhado para políticas públicas específicas, instituído por lei ou decreto, atendendo à legislação pertinente.`,
    elements: [],
  },
  {
    id: 3,
    open: false,
    ref_id: 2,
    icon: <PlanoInstitucionalIcon />,
    title: 'Plano Institucional',
    image: <ImagePlanoInstitucionalIcon />,
    description: `Instrumento que estabelece os objetivos e metas a serem alcançados pelo órgão ou entidade e traça os planos de ação nos níveis tático, no curto e médio prazos, em alinhamento com todos os planos traçados para a Cidade.`,
    elements: []
  },
  {
    id: 4,
    open: false,
    ref_id: 4,
    icon: <PlanoGestaoIcon />,
    title: 'Plano de Gestão',
    image: <ImagePlanoGestaoIcon />,
    description: `Previsto na Lei Orgânica do Município, direciona as ações da Prefeitura no prazo de quatro anos, período equivalente ao mandato do chefe do Poder Executivo, em alinhamento com as aspirações de longo prazo estabelecidas para a cidade nos Planos de Estado, com base nas prioridades da Administração e em análises técnicas. Define as iniciativas, metas estratégicas e projetos.`,
    elements: []
  },
  {
    id: 5,
    open: false,
    ref_id: 5,
    icon: <PlanoOrcamentarioIcon />,
    title: 'Plano Orçamentário',
    image: <ImagePlanoOrcamentarioIcon />,
    description: `Instituído por lei, orienta os gestores públicos na execução dos gastos e na aplicação dos investimentos. Deve estabelecer de forma regionalizada os objetivos e metas da administração pública municipal para as despesas de capital e outras delas decorrentes, assim como para os programas de duração continuada.`,
    elements: []
  },
]

export interface IDetailsItemContentProps {
  id: number,
  code: string,
  title: string,
  layer?: string;
  filled: boolean,
  label_2?: string,
  clicked: boolean,
  tooltip?: string,
  optional: boolean,
  navigable: boolean,
  form_component: any,
  layer_indicator?: boolean;
  demostration_layer?: boolean;
  menu_layer?: string,
  description: string | JSX.Element,
}

export interface IItemContentProps {
  pre_cadastro: IDetailsItemContentProps[],
  camada_tatica: IDetailsItemContentProps[],
  finalizacao_plano: IDetailsItemContentProps[],
  camada_diagnostica: IDetailsItemContentProps[],
  camada_estrategica: IDetailsItemContentProps[],
}

export interface IObjectMenuProps {
  plano_estado: IItemContentProps,
  plano_gestao: IItemContentProps,
  plano_setorial: IItemContentProps,
  plano_orcamentario: IItemContentProps,
  plano_institucional: IItemContentProps,
}

export const MenuItems = {
  plano_estado: {
    pre_cadastro: [
      {
        id: 0,
        filled: false,
        clicked: false,
        optional: false,
        navigable: true,
        code: 'pre_cadastro',
        title: 'Pré-cadastro',
        layer: 'pre_cadastro',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        form_component: <PreRegistrationForm />
      }
    ],
    camada_diagnostica: [
      {
        id: 1,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_diagnostica",
        title: 'Camada Diagnóstica',
        layer: 'camada_diagnostica',
        menu_layer: 'camada_diagnostica',
        form_component: <DiagnosisLayerForm />
      },
      {
        id: 2,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        tooltip: `Exemplo: Taxa de mortalidade infantil na cidade do Rio de Janeiro acima do valor de referência da OMS, com velocidade de redução abaixo da média dos 100 maiores municípios do Brasil nos últimos 10 anos (problema/fragilidade);Elevado número de médicos ginecologistas, obstetras e pediatras por habitante, disponíveis na rede pública municipal do Rio de Janeiro, indicador que posiciona o Rio de Janeiro entre as 5 melhores capitais do país (força)`,
        code: "sintese_diagnostico",
        layer: 'camada_diagnostica',
        description: `São os principais problemas que deverão ser reduzidos ou superados e, também, potencialidades que poderão ser melhor exploradas no horizonte de vigência do Plano. Consiste nas principais saídas (“outputs”) do diagnóstico de uma área/tema/dimensão ou da organização. Sua origem deriva da identificação de fragilidades e potencialidades, ou seja, de um lado, são fraquezas, passivos estruturais e indicadores que apresentaram trajetória preocupante e, de outro, são forças e ativos que podem ser melhor utilizados.`,
        title: 'Síntese do Diagnóstico',
        form_component: <SummaryDiagnosisForm />
      },
      {
        id: 3,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        code: 'sintese_cenario',
        tooltip:`Exemplo: Tendência de envelhecimento da população da cidade do Rio de Janeiro, com implicações para a saúde e o mercado de trabalho, qualidade dos espaços urbanos e outras políticas.; Tendência de redução de áreas verdes por expansão urbana irregular.;Tendência de expansão urbana para áreas não infraestruturadas.;Premissa de proteção de áreas naturais que oferecem serviços ecossistêmicos e que garantam a biodiversidade.;Premissa de redução de 20% das emissões de gases de efeito estufa até o ano 2030, conforme o cenário base escolhido como referência.`,
        layer: 'camada_diagnostica',
        description: `São os principais resultados da análise sobre o futuro (análise prospectiva). Consiste nas principais saídas (“outputs”) do prognóstico de uma área/tema/dimensão ou do contexto no qual a organização está inserida. Deriva das principais indicações contidas nos cenários tendenciais e planejados ou ainda de oportunidades e ameaças resultantes destes cenários.`,
        title: 'Síntese do Cenário',
        form_component: <SummaryScenarioForm />
      }
    ],
    camada_estrategica: [
      {
        id: 4,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_estrategica",
        title: 'Camada Estratégica',
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica',
        form_component: <StrategicLayerForm />
      },
      {
        id: 5,
        filled: false,
        clicked: false,
        optional: false,
        code: 'visao',
        layer: 'camada_estrategica',
        navigable: false,
        tooltip:`Exemplo: “Tornar o Rio uma cidade referência na equidade de gênero.” (Fonte: Plano Estratégico Institucional da Secretaria Especial de Políticas e Promoção da Mulher)`,
        description: `Consiste na descrição (ou imagem) da situação desejada para o objeto do plano em um horizonte determinado. Consiste em um grande alvo a ser alcançado, exprimindo uma conquista estratégica de grande valor. Ao mesmo tempo em que configura um sonho inspirador, a visão de futuro deve ser factível. Deve ser concisa, preferencialmente descrita em uma única frase, podendo contar com atributos desejados relativos a direção, qualidade, magnitude e abrangência.`,
        title: 'Visão',
        form_component: <VisionForm />
      },
      {
        id: 6,
        filled: false,
        clicked: false,
        optional: false,
        tooltip: 'Exemplo: “Longevidade, Bem-Estar e Território Conectado; Igualdade e Equidade; Desenvolvimento Econômico, Competitividade e Inovação; Mudanças Climáticas e Resiliência; Cooperação e Paz; Governança” (Fonte: Plano Estratégico 2021 – 2024)',
        layer: 'camada_estrategica',
        navigable: false,
        code: 'eixos_tematicos',
        description: `Eixos de concentração de esforços e recursos visando às transformações e melhorias desejadas para o alcance da visão de futuro, considerando o objeto do plano. Também podem ser chamados de temas transversais e áreas de resultado.`,
        title: 'Eixo Temático',
        form_component: <ThematicAxisForm />
      },
      {
        id: 7,
        filled: false,
        clicked: false,
        optional: false,
        tooltip: 'Exemplo: Tema transversal: Longevidade e Bem-Estar. Aspirações: “A cidade terá um sistema alimentar sustentável, com incentivo à produção agrícola familiar orgânica, alimentos in natura e proximidade entre produtor e produtora e consumidor final.”; “Espaços públicos arborizados, equitativamente distribuídos, estimulando hábitos saudáveis na população carioca de todas as idades.”;  (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)',
        layer: 'camada_estrategica',
        navigable: false,
        code: 'aspiracao',
        description: `Está conectada diretamente à Visão, podendo funcionar como um desdobramento desta, auxiliando a orientar esforços de cada área/tema em direção a metas ambiciosas. Pode conter atributos desejados relativos à direção, qualidade, magnitude e abrangência.`,
        title: 'Aspiração',
        form_component: <AspirationForm />
      },
      {
        id: 8,
        filled: false,
        clicked: false,
        optional: true,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'estrategia',
        tooltip: `Exemplo: “Promover a todas as crianças e jovens cariocas, creches e escolas em todas as etapas e modalidades, com melhoria do fluxo escolar e da aprendizagem para um desenvolvimento saudável, seguro e pleno, aprendendo as habilidades e competências fundamentais para a vida, o trabalho e a cidadania, com a valorização dos profissionais da educação, alcançando índices adequados de Desenvolvimento da Educação Básica.” (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)`,
        description: `Consiste na forma de materialização da aspiração, estabelecendo como os resultados contidos na aspiração deverão ser alcançados e as direções para atuação. Pode ser desdobrada em objetivos específicos, com metas associadas, e em macroações ou ações estruturantes.`,
        title: 'Estratégia',
        form_component: <EstrategyForm />
      },
      {
        id: 9,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Objetivos',
        form_component: <ObjectivesDemonstrationForm />
      },
      {
        id: 10,
        filled: false,
        clicked: false,
        optional: false,
        atc: true,

        demostration_layer: true,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo_form',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        description: `São resultados prioritários que devem ser alcançados ou mantidos no horizonte temporal de vigência do plano. O objetivo deve sintetizar a transformação que se buscará efetivar. Devem obedecer aos seguintes critérios: pertinentes, mensuráveis, motivadores, inteligíveis e alcançáveis.`,
        title: 'Objetivos',
        form_component: <ObjectivesForm />
      },
      {
        id: 11,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA).`,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 12,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <Similarity type='objetivos' />
      },
      {
        id: 13,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas',
        navigable: false,
        code: 'metas',
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        form_component: <GoalsDemonstrationForm />
      },
      {
        id: 14,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas_form',
        navigable: false,
        atc: true,
        demostration_layer: true,
        code: 'metas',
        tooltip: `Exemplo: Objetivo: Acelerar a redução  da mortalidade infantil no município do Rio de Janeiro para alcançar o padrão de referência da OMS. Meta: Reduzir a Taxa de Mortos por Nascidos Vivos na Cidade do Rio de Janeiro de 14,4 para 10 até 2026.`,
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        form_component: <GoalsForm />
      },
      {
        id: 15,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 16,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='goals' />
      },
    ],
    camada_tatica: [
      {
        id: 17,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_tatica",
        title: 'Camada Tática',
        layer: "camada_tatica",
        menu_layer: 'camada_tatica',
        form_component: <TaticLayerForm />
      },
      {
        id: 18,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes',
        code: 'macroacoes',
        description: `Também chamadas de ações estruturantes, linhas de ação ou mesmo estratégias específicas, indicam o que deve ser feito para, em seu conjunto, produzir os resultados almejados e, consequentemente, atingir os objetivos e metas estabelecidas. Cada macroação deverá conectar-se a pelo menos um objetivo estabelecido na camada estratégica do plano.`,
        title: 'Macroações',
        form_component: <MacroactionDemonstrationForm />
      },
      {
        id: 19,
        filled: false,
        clicked: false,
        optional: false,
        tooltip: `Exemplo: “Criar campanhas e conteúdos infanto-juvenis em planejamento sustentável com periodicidade para alimentar a plataforma Participa.rio.” (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)`,
        navigable: false,
        atc: true,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes_form',
        code: 'macroacoes',
        demostration_layer: true,
        description: `Também chamadas de ações estruturantes, linhas de ação ou mesmo estratégias específicas, indicam o que deve ser feito para, em seu conjunto, produzir os resultados almejados e, consequentemente, atingir os objetivos e metas estabelecidas. Cada macroação deverá conectar-se a pelo menos um objetivo estabelecido na camada estratégica do plano.`,
        title: 'Macroações',
        form_component: <MacroactionForm />
      },
      {
        id: 20,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        demostration_layer: true,
        code: 'macroacoes',
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <SimilarityMacroactionDemonstrationForm />
      },
      {
        id: 21,
        filled: false,
        clicked: false,
        demostration_layer: true,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        code: 'macroacoes',
        description: `A Ferramenta de Similaridade ajuda você a comparar suas macroações com as macroações de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='macroacoes' />
      },
    ],
    finalizacao_plano: [
      {
        id: 22,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        title: 'Conclusão',
        layer_indicator: true,
        code: "finalizacao_plano",
        layer: "finalizacao_plano",
        menu_layer: 'finalizacao_plano',
        form_component: <ConclusionLayerForm />,
        description: `Na etapa de conclusão do seu Plano, você passará por cinco etapas principais para finalizar e enviar seu preenchimento:`,
      },
      {
        id: 23,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Finalize seu Plano com os detalhes essenciais. Inclua o Ato Normativo de Aprovação, palavras-chave relevantes, uma foto de capa atrativa e um ícone representativo. Faça o upload do PDF completo do Plano e um resumo executivo em PDF para uma visão geral rápida. Certifique-se de que todas as informações estejam precisas para garantir a conformidade e a clareza do seu Plano.',
        navigable: false,
        code: "dados_finais",
        title: 'Dados Finais',
        layer: "finalizacao_plano",
        form_component: <FinalDataForm />
      },
      {
        id: 24,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        code: 'vinculado_pds',
        layer: 'finalizacao_plano',
        description: `Alinhe seu Plano com os objetivos, metas e macroações dos planos de longo prazo da prefeitura. Essa vinculação é essencial para garantir que seu Plano esteja em sintonia com a visão estratégica da cidade, promovendo coesão e continuidade nas ações públicas. Ao fazer essa conexão, você contribui para a realização das metas municipais de forma integrada e sustentável, fortalecendo o compromisso com o desenvolvimento urbano e social. `,
        title: 'Vinculação',
        label_2: 'Vinculação ao Plano de longo prazo',
        form_component: <NextLinkingForm />
      },
      {
        id: 25,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Antes de prosseguir, utilize a ferramenta de verificação de pendências. O sistema checará se todos os campos obrigatórios foram preenchidos, ajudando a identificar e corrigir possíveis omissões. Você só poderá seguir para a próxima etapa se não houver pendências apontadas. Observe que essa verificação pode demorar dependendo do tamanho do Plano, garantindo que todas as informações necessárias estejam completas e corretas.',
        navigable: false,
        code: "verificacao_pendencia",
        layer: 'finalizacao_plano',
        menu_layer: 'verificacao_pendencia',
        title: 'Verificação de pendências',
        form_component: <PendingCheckLayerForm />
      },
      // {
      //   id: 26,
      //   filled: false,
      //   clicked: false,
      //   layer: 'pre-visualizacao',
      //   menu_layer: 'pre-visualizacao',
      //   optional: false,
      //   navigable: false,
      //   code: 'pre_visualizacao',
      //   description: `Visualize uma prévia do Plano diretamente no sistema. Isso permite que você revise e valide todas as seções do documento, garantindo que tudo esteja conforme o esperado.`,
      //   title: 'Pré-visualização',
      //   form_component: <PreviewLayerForm />
      // },
      {
        id: 26,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'finalizacao_plano',
        navigable: false,
        code: 'consideracao',
        description: `Na Página de Considerações, você pode visualizar os comentários inseridos pelo órgão revisor. Esses comentários são organizados por data de revisão, permitindo uma navegação fácil e estruturada. Essa funcionalidade ajuda você a acompanhar o feedback recebido, garantindo que todas as observações e sugestões sejam devidamente consideradas e atendidas. Acompanhe cada revisão para ajustar e aprimorar seu Plano conforme necessário, garantindo um processo de aprovação mais eficiente e transparente.`,
        title: 'Considerações',
        menu_layer: 'consideracao_plano',
        form_component: <ConsiderationLayerForm />
      },
      {
        id: 27,
        filled: false,
        clicked: true,
        optional: false,
        layer: 'finalizacao_plano',
        navigable: false,
        code: 'envio_epl',
        description: `Na sessão de Envio do Plano, você pode tomar a decisão de incluir o Plano no sistema, oficializando sua aprovação, ou devolvê-lo ao órgão responsável para ajustes com base nas considerações recebidas. Essa etapa finaliza o processo de revisão, assegurando que o Plano esteja pronto para implementação após considerar todas as recomendações relevantes. `,
        title: 'Envio do plano',
        menu_layer: 'envio_epl',
        form_component: <SendingPlanForm />
      },
    ]
  },
  plano_setorial: {
    pre_cadastro: [
      {
        id: 0,
        filled: false,
        clicked: false,
        optional: false,
        navigable: true,
        layer: 'pre_cadastro',
        code: 'pre_cadastro',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Pré-cadastro',
        form_component: <PreRegistrationForm />
      }
    ],
    camada_diagnostica: [
      {
        id: 1,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_diagnostica",
        title: 'Camada Diagnóstica',
        menu_layer: 'camada_diagnostica',
        layer: 'camada_diagnostica',
        form_component: <DiagnosisLayerForm />
      },
      {
        id: 2,
        filled: false,
        navigable: false,
        optional: false,
        clicked: false,
        layer: 'camada_diagnostica',
        code: 'sintese_diagnostico',
        tooltip: `Exemplo: Taxa de mortalidade infantil na cidade do Rio de Janeiro acima do valor de referência da OMS, com velocidade de redução abaixo da média dos 100 maiores municípios do Brasil nos últimos 10 anos (problema/fragilidade);Elevado número de médicos ginecologistas, obstetras e pediatras por habitante, disponíveis na rede pública municipal do Rio de Janeiro, indicador que posiciona o Rio de Janeiro entre as 5 melhores capitais do país (força)`,
        description: `São os principais problemas que deverão ser reduzidos ou superados e, também, potencialidades que poderão ser melhor exploradas no horizonte de vigência do Plano. Consiste nas principais saídas (“outputs”) do diagnóstico de uma área/tema/dimensão ou da organização. Sua origem deriva da identificação de fragilidades e potencialidades, ou seja, de um lado, são fraquezas, passivos estruturais e indicadores que apresentaram trajetória preocupante e, de outro, são forças e ativos que podem ser melhor utilizados.`,
        title: 'Síntese do Diagnóstico',
        form_component: <SummaryDiagnosisForm />
      },
      {
        id: 3,
        filled: false,
        navigable: false,
        optional: false,
        layer: 'camada_diagnostica',
        tooltip: `Exemplo: Tendência de envelhecimento da população da cidade do Rio de Janeiro, com implicações para a saúde e o mercado de trabalho, qualidade dos espaços urbanos e outras políticas.; Tendência de redução de áreas verdes por expansão urbana irregular.;Tendência de expansão urbana para áreas não infraestruturadas.;Premissa de proteção de áreas naturais que oferecem serviços ecossistêmicos e que garantam a biodiversidade.;Premissa de redução de 20% das emissões de gases de efeito estufa até o ano 2030, conforme o cenário base escolhido como referência.`,
        code: 'sintese_cenario',
        description: `São os principais resultados da análise sobre o futuro (análise prospectiva). Consiste nas principais saídas (“outputs”) do prognóstico de uma área/tema/dimensão ou do contexto no qual a organização está inserida. Deriva das principais indicações contidas nos cenários tendenciais e planejados ou ainda de oportunidades e ameaças resultantes destes cenários,`,
        title: 'Síntese do Cenário',
        form_component: <SummaryScenarioForm />
      }
    ],
    camada_estrategica: [
      {
        id: 4,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_estrategica",
        title: 'Camada Estratégica',
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica',
        form_component: <StrategicLayerForm />
      },
      {
        id: 5,
        filled: false,
        clicked: false,
        optional: false,
        code: 'visao',
        tooltip: `Exemplo: “Tornar o Rio uma cidade referência na equidade de gênero.” (Fonte: Plano Estratégico Institucional da Secretaria Especial de Políticas e Promoção da Mulher)`,
        layer: 'camada_estrategica',
        navigable: false,
        description: `Consiste na descrição (ou imagem) da situação desejada para o objeto do plano em um horizonte determinado. Consiste em um grande alvo a ser alcançado, exprimindo uma conquista estratégica de grande valor. Ao mesmo tempo em que configura um sonho inspirador, a visão de futuro deve ser factível. Deve ser concisa, preferencialmente descrita em uma única frase, podendo contar com atributos desejados relativos a direção, qualidade, magnitude e abrangência.`,
        title: 'Visão',
        form_component: <VisionForm />
      },
      {
        id: 6,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        tooltip: 'Exemplo: “Longevidade, Bem-Estar e Território Conectado; Igualdade e Equidade; Desenvolvimento Econômico, Competitividade e Inovação; Mudanças Climáticas e Resiliência; Cooperação e Paz; Governança” (Fonte: Plano Estratégico 2021 – 2024)',
        code: 'eixos_tematicos',
        description: `Eixos de concentração de esforços e recursos visando às transformações e melhorias desejadas para o alcance da visão de futuro, considerando o objeto do plano. Também podem ser chamados de temas transversais e áreas de resultado.`,
        title: 'Eixo Temático',
        form_component: <ThematicAxisForm />
      },
      {
        id: 7,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'estrategia',
        tooltip: `Exemplo: “Promover a todas as crianças e jovens cariocas, creches e escolas em todas as etapas e modalidades, com melhoria do fluxo escolar e da aprendizagem para um desenvolvimento saudável, seguro e pleno, aprendendo as habilidades e competências fundamentais para a vida, o trabalho e a cidadania, com a valorização dos profissionais da educação, alcançando índices adequados de Desenvolvimento da Educação Básica.” (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)`,
        description: `Consiste na forma de materialização da aspiração, estabelecendo como os resultados contidos na aspiração deverão ser alcançados e as direções para atuação. Pode ser desdobrada em objetivos específicos, com metas associadas, e em macroações ou ações estruturantes.`,
        title: 'Estratégia',
        form_component: <EstrategyForm />
      },
      {
        id: 8,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Objetivos',
        form_component: <ObjectivesDemonstrationForm />
      },
      {
        id: 9,
        filled: false,
        clicked: false,
        optional: false,
        atc: true,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo_form',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `São resultados prioritários que devem ser alcançados ou mantidos no horizonte temporal de vigência do plano. O objetivo deve sintetizar a transformação que se buscará efetivar. Devem obedecer aos seguintes critérios: pertinentes, mensuráveis, motivadores, inteligíveis e alcançáveis.`,
        title: 'Objetivos',
        form_component: <ObjectivesForm />
      },
      {
        id: 10,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA).`,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 11,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <Similarity type='objetivos' />
      },
      {
        id: 12,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas',
        navigable: false,
        code: 'metas',
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        form_component: <GoalsDemonstrationForm />
      },
      {
        id: 13,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas_form',
        navigable: false,
        atc: true,
        code: 'metas',
        demostration_layer: true,
        tooltip: `Exemplo: Objetivo: Acelerar a redução  da mortalidade infantil no município do Rio de Janeiro para alcançar o padrão de referência da OMS. Meta: Reduzir a Taxa de Mortos por Nascidos Vivos na Cidade do Rio de Janeiro de 14,4 para 10 até 2026.`,
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        form_component: <GoalsForm />
      },
      {
        id: 14,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 15,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='goals' />
      },
    ],
    camada_tatica: [
      {
        id: 16,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_tatica",
        title: 'Camada Tática',
        layer: "camada_tatica",
        menu_layer: 'camada_tatica',
        form_component: <TaticLayerForm />
      },
      {
        id: 17,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes',
        code: 'macroacoes',
        description: `Também chamadas de ações estruturantes, linhas de ação ou mesmo estratégias específicas, indicam o que deve ser feito para, em seu conjunto, produzir os resultados almejados e, consequentemente, atingir os objetivos e metas estabelecidas. Cada macroação deverá conectar-se a pelo menos um objetivo estabelecido na camada estratégica do plano.`,
        title: 'Macroações',
        form_component: <MacroactionDemonstrationForm />
      },
      {
        id: 18,
        filled: false,
        clicked: false,
        optional: false,
        tooltip: `Exemplo: “Criar campanhas e conteúdos infanto-juvenis em planejamento sustentável com periodicidade para alimentar a plataforma Participa.rio.” (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)`,
        navigable: false,
        atc: true,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes_form',
        code: 'macroacoes',
        demostration_layer: true,
        description: `Também chamadas de ações estruturantes, linhas de ação ou mesmo estratégias específicas, indicam o que deve ser feito para, em seu conjunto, produzir os resultados almejados e, consequentemente, atingir os objetivos e metas estabelecidas. Cada macroação deverá conectar-se a pelo menos um objetivo estabelecido na camada estratégica do plano.`,
        title: 'Macroações',
        form_component: <MacroactionForm />
      },
      {
        id: 19,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        demostration_layer: true,
        code: 'macroacoes',
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <SimilarityMacroactionDemonstrationForm />
      },
      {
        id: 20,
        filled: false,
        clicked: false,
        demostration_layer: true,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        code: 'macroacoes',
        description: `A Ferramenta de Similaridade ajuda você a comparar suas macroações com as macroações de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='macroacoes' />
      },
      {
        id: 21,
        filled: false,
        optional: true,
        navigable: false,
        tooltip: 'Exemplo: Programa “Mães Cariocas”, voltado à atenção de mães em situação de vulnerabilidade social na Zona Norte do Rio de Janeiro”',
        layer: 'camada_tatica',
        code: 'programas',
        description: `Consistem em um conjunto de projetos gerenciados de modo coordenado com vistas à conclusão de uma macroação. `,
        title: 'Programas',
        form_component: <ProgramsForm />
      },
      {
        id: 22,
        filled: false,
        layer: 'camada_tatica',
        optional: true,
        navigable: false,
        tooltip: 'Exemplo: Projeto “Aleitamento Materno”, voltado à conscientização e incentivo do aleitamento materno na Zona Norte do Rio de Janeiro”',
        code: 'projetos',
        description: `Ações com escopo, recursos alocados, prazo e entregas definidas, que auxiliam na conclusão de uma macroação. Podem estar elencados dentro de um programa, caso possuam inter-relação entre si, ou se conectar diretamente à macroação.`,
        title: 'Projetos',
        form_component: <ProjectsForm />
      },
      {
        id: 23,
        layer: 'camada_tatica',
        filled: false,
        optional: true,
        navigable: false,
        code: 'produtos_entregas',
        tooltip: 'Exemplo: “Formação da Comissão Permanente de Segurança Viária” (Fonte: Plano de Segurança Viária)',
        description: `Os produtos ou entregas são os resultados que decorrem da execução do programa/projeto. Para que o projeto seja concluído com sucesso, há uma série de atividades que precisam ser desenvolvidas, gerando, como “saídas” (outputs), entregas e produtos.`,
        title: 'Produtos/Entregas',
        form_component: <ProductsAndDeliveriesForm />
      },
    ],
    finalizacao_plano: [
      {
        id: 24,
        filled: false,
        clicked: false,
        optional: false,
        description: `Na etapa de conclusão do seu Plano, você passará por cinco etapas principais para finalizar e enviar seu preenchimento:`,
        navigable: false,
        code: "finalizacao_plano",
        title: 'Conclusão',
        layer: "finalizacao_plano",
        layer_indicator: true,
        menu_layer: 'finalizacao_plano',
        form_component: <ConclusionLayerForm />
      },
      {
        id: 25,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Finalize seu Plano com os detalhes essenciais. Inclua o Ato Normativo de Aprovação, palavras-chave relevantes, uma foto de capa atrativa e um ícone representativo. Faça o upload do PDF completo do Plano e um resumo executivo em PDF para uma visão geral rápida. Certifique-se de que todas as informações estejam precisas para garantir a conformidade e a clareza do seu Plano.',
        navigable: false,
        code: "dados_finais",
        title: 'Dados Finais',
        layer: "finalizacao_plano",
        form_component: <FinalDataForm />
      },
      {
        id: 26,
        filled: false,
        optional: false,
        navigable: false,
        code: 'vinculado_pds',
        layer: 'finalizacao_plano',
        description: `Alinhe seu Plano com os objetivos, metas e macroações dos planos de longo prazo da prefeitura. Essa vinculação é essencial para garantir que seu Plano esteja em sintonia com a visão estratégica da cidade, promovendo coesão e continuidade nas ações públicas. Ao fazer essa conexão, você contribui para a realização das metas municipais de forma integrada e sustentável, fortalecendo o compromisso com o desenvolvimento urbano e social. `,
        title: 'Vinculação',
        label_2: 'Vinculação ao Plano de longo prazo',
        form_component: <NextLinkingForm />
      },
      {
        id: 27,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Antes de prosseguir, utilize a ferramenta de verificação de pendências. O sistema checará se todos os campos obrigatórios foram preenchidos, ajudando a identificar e corrigir possíveis omissões. Você só poderá seguir para a próxima etapa se não houver pendências apontadas. Observe que essa verificação pode demorar dependendo do tamanho do Plano, garantindo que todas as informações necessárias estejam completas e corretas.',
        navigable: false,
        code: "verificacao_pendencia",
        layer: 'finalizacao_plano',
        menu_layer: 'verificacao_pendencia',
        title: 'Verificação de pendências',
        form_component: <PendingCheckLayerForm />
      },
      // {
      //   id: 28,
      //   filled: false,
      //   layer: 'pre-visualizacao',
      //   menu_layer: 'pre-visualizacao',
      //   optional: false,
      //   clicked: true,
      //   code: 'pre_visualizacao',
      //   description: `Visualize uma prévia do Plano diretamente no sistema. Isso permite que você revise e valide todas as seções do documento, garantindo que tudo esteja conforme o esperado.`,
      //   title: 'Pré-visualização',
      //   form_component: <PreviewLayerForm />
      // },
      {
        id: 28,
        filled: false,
        clicked: false,
        optional: true,
        navigable: false,
        code: 'consideracao',
        title: 'Considerações',
        layer: 'finalizacao_plano',
        menu_layer: 'consideracao_plano',
        form_component: <ConsiderationLayerForm />,
        description: `Na Página de Considerações, você pode visualizar os comentários inseridos pelo órgão revisor. Esses comentários são organizados por data de revisão, permitindo uma navegação fácil e estruturada. Essa funcionalidade ajuda você a acompanhar o feedback recebido, garantindo que todas as observações e sugestões sejam devidamente consideradas e atendidas. Acompanhe cada revisão para ajustar e aprimorar seu Plano conforme necessário, garantindo um processo de aprovação mais eficiente e transparente.`,
      },
      {
        id: 29,
        filled: false,
        layer: 'finalizacao_plano',
        optional: false,
        clicked: true,
        navigable: false,
        code: 'envio_epl',
        description: `Na sessão de Envio do Plano, você pode tomar a decisão de incluir o Plano no sistema, oficializando sua aprovação, ou devolvê-lo ao órgão responsável para ajustes com base nas considerações recebidas. Essa etapa finaliza o processo de revisão, assegurando que o Plano esteja pronto para implementação após considerar todas as recomendações relevantes. `,
        title: 'Envio do plano',
        menu_layer: 'envio_epl',
        form_component: <SendingPlanForm />
      },

    ]
  },
  plano_institucional: {
    pre_cadastro: [
      {
        id: 0,
        filled: false,
        clicked: false,
        optional: false,
        navigable: true,
        layer: 'pre_cadastro',
        code: 'pre_cadastro',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Pré-cadastro',
        form_component: <PreRegistrationForm />
      }
    ],
    camada_diagnostica: [
      {
        id: 1,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_diagnostica",
        title: 'Camada Diagnóstica',
        layer: 'camada_diagnostica',
        menu_layer: 'camada_diagnostica',
        form_component: <DiagnosisLayerForm />
      },
      {
        id: 2,
        filled: false,
        navigable: false,
        layer: 'camada_diagnostica',
        optional: false,
        code: 'sintese_diagnostico',
        tooltip: `Exemplo: Taxa de mortalidade infantil na cidade do Rio de Janeiro acima do valor de referência da OMS, com velocidade de redução abaixo da média dos 100 maiores municípios do Brasil nos últimos 10 anos (problema/fragilidade);Elevado número de médicos ginecologistas, obstetras e pediatras por habitante, disponíveis na rede pública municipal do Rio de Janeiro, indicador que posiciona o Rio de Janeiro entre as 5 melhores capitais do país (força)`,
        description: `São os principais problemas que deverão ser reduzidos ou superados e, também, potencialidades que poderão ser melhor exploradas no horizonte de vigência do Plano. Consiste nas principais saídas (“outputs”) do diagnóstico de uma área/tema/dimensão ou da organização. Sua origem deriva da identificação de fragilidades e potencialidades, ou seja, de um lado, são fraquezas, passivos estruturais e indicadores que apresentaram trajetória preocupante e, de outro, são forças e ativos que podem ser melhor utilizados.`,
        title: 'Síntese do Diagnóstico',
        form_component: <SummaryDiagnosisForm />
      },
    ],
    camada_estrategica: [
      {
        id: 3,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        layer: 'camada_estrategica',
        code: "camada_estrategica",
        title: 'Camada Estratégica',
        menu_layer: 'camada_estrategica',
        form_component: <StrategicLayerForm />
      },
      {
        id: 4,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_estrategica',
        code: 'missao_proposito',
        description: <DescriptionMissionPurpose />,
        title: 'Missão/Propósito',
        tooltip: 'Exemplo: Missão da Secretaria Especial de Políticas e Promoção da Mulher: “Promover as mulheres cariocas para potencializar todas as suas diversidades.” (Fonte: Plano Estratégico Institucional da Secretaria Especial de Políticas e Promoção da Mulher)',
        form_component: <MissionPurposeForm />
      },
      {
        id: 5,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'visao',
        tooltip: `Exemplo: “Tornar o Rio uma cidade referência na equidade de gênero.” (Fonte: Plano Estratégico Institucional da Secretaria Especial de Políticas e Promoção da Mulher)`,
        description: `Consiste na descrição (ou imagem) da situação desejada para o objeto do plano em um horizonte determinado. Consiste em um grande alvo a ser alcançado, exprimindo uma conquista estratégica de grande valor. Ao mesmo tempo em que configura um sonho inspirador, a visão de futuro deve ser factível. Deve ser concisa, preferencialmente descrita em uma única frase, podendo contar com atributos desejados relativos a direção, qualidade, magnitude e abrangência.`,
        title: 'Visão',
        form_component: <VisionForm />
      },
      {
        id: 6,
        filled: false,
        clicked: false,
        optional: false,
        tooltip: 'Exemplo: “Diálogo; Respeito; Equidade; Diversidade; Excelência; Proatividade; Comprometimento” (Fonte: Plano Estratégico Institucional da Secretaria Especial de Políticas e Promoção da Mulher)',
        layer: 'camada_estrategica',
        navigable: false,
        code: 'valores',
        description: `Valores e crenças representam princípios básicos compartilhados pelos atores envolvidos, que devem ditar o comportamento, as atitudes, o relacionamento e as práticas de gestão. Servem para criar significado e engajamento entre seus partícipes e funcionam com uma bússola comportamental.`,
        title: 'Valores',
        form_component: <ValuesForm />
      },
      {
        id: 7,
        layer: 'camada_estrategica',
        navigable: false,
        filled: false,
        clicked: false,
        optional: false,
        code: 'eixos_tematicos',
        tooltip:'Exemplo: “Longevidade, Bem-Estar e Território Conectado; Igualdade e Equidade; Desenvolvimento Econômico, Competitividade e Inovação; Mudanças Climáticas e Resiliência; Cooperação e Paz; Governança” (Fonte: Plano Estratégico 2021 – 2024)',
        description: `Eixos de concentração de esforços e recursos visando às transformações e melhorias desejadas para o alcance da visão de futuro, considerando o objeto do plano. Também podem ser chamados de temas transversais e áreas de resultado.`,
        title: 'Eixo Temático',
        form_component: <ThematicAxisForm />
      },
      {
        id: 8,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Objetivos',
        form_component: <ObjectivesDemonstrationForm />
      },
      {
        id: 9,
        filled: false,
        clicked: false,
        optional: false,
        atc: true,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo_form',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `SSão resultados prioritários que devem ser alcançados ou mantidos no horizonte temporal de vigência do plano. O objetivo deve sintetizar a transformação que se buscará efetivar. Devem obedecer aos seguintes critérios: pertinentes, mensuráveis, motivadores, inteligíveis e alcançáveis.`,
        title: 'Objetivos',
        form_component: <ObjectivesForm />
      },
      {
        id: 10,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA).`,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 11,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <Similarity type='objetivos' />
      },
      {
        id: 12,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas',
        navigable: false,
        code: 'metas',
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        form_component: <GoalsDemonstrationForm />
      },
      {
        id: 13,
        filled: false,
        clicked: false,
        optional: false,
        atc: true,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas_form',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        tooltip: `Exemplo: Objetivo: Acelerar a redução  da mortalidade infantil no município do Rio de Janeiro para alcançar o padrão de referência da OMS. Meta: Reduzir a Taxa de Mortos por Nascidos Vivos na Cidade do Rio de Janeiro de 14,4 para 10 até 2026.`,
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        form_component: <GoalsForm />
      },
      {
        id: 14,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 15,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='goals' />
      },
    ],
    camada_tatica: [
      {
        id: 16,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_tatica",
        title: 'Camada Tática',
        layer: "camada_tatica",
        menu_layer: 'camada_tatica',
        form_component: <TaticLayerForm />
      },
      {
        id: 17,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes',
        code: 'macroacoes',
        description: `Também chamadas de ações estruturantes, linhas de ação ou mesmo estratégias específicas, indicam o que deve ser feito para, em seu conjunto, produzir os resultados almejados e, consequentemente, atingir os objetivos e metas estabelecidas. Cada macroação deverá conectar-se a pelo menos um objetivo estabelecido na camada estratégica do plano.`,
        title: 'Macroações',
        form_component: <MacroactionDemonstrationForm />
      },
      {
        id: 18,
        filled: false,
        clicked: false,
        optional: false,
        tooltip: `Exemplo: “Criar campanhas e conteúdos infanto-juvenis em planejamento sustentável com periodicidade para alimentar a plataforma Participa.rio.” (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)`,
        navigable: false,
        layer: 'camada_tatica',
        atc: true,
        menu_layer: 'camada_tatica_macroacoes_form',
        code: 'macroacoes',
        demostration_layer: true,
        description: `Também chamadas de ações estruturantes, linhas de ação ou mesmo estratégias específicas, indicam o que deve ser feito para, em seu conjunto, produzir os resultados almejados e, consequentemente, atingir os objetivos e metas estabelecidas. Cada macroação deverá conectar-se a pelo menos um objetivo estabelecido na camada estratégica do plano.`,
        title: 'Macroações',
        form_component: <MacroactionForm />
      },
      {
        id: 19,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        demostration_layer: true,
        code: 'macroacoes',
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <SimilarityMacroactionDemonstrationForm />
      },
      {
        id: 20,
        filled: false,
        clicked: false,
        demostration_layer: true,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        code: 'macroacoes',
        description: `A Ferramenta de Similaridade ajuda você a comparar suas macroações com as macroações de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='macroacoes' />
      },
      {
        id: 21,
        filled: false,
        layer: 'camada_tatica',
        optional: true,
        navigable: false,
        tooltip: 'Exemplo: Programa “Mães Cariocas”, voltado à atenção de mães em situação de vulnerabilidade social na Zona Norte do Rio de Janeiro”',
        code: 'programas',
        description: `Consistem em um conjunto de projetos gerenciados de modo coordenado com vistas à conclusão de uma macroação. `,
        title: 'Programas',
        form_component: <ProgramsForm />
      },
      {
        id: 22,
        filled: false,
        optional: true,
        navigable: false,
        layer: 'camada_tatica',
        code: 'projetos',
        tooltip: 'Exemplo: Projeto “Aleitamento Materno”, voltado à conscientização e incentivo do aleitamento materno na Zona Norte do Rio de Janeiro”',
        description: `Ações com escopo, recursos alocados, prazo e entregas definidas, que auxiliam na conclusão de uma macroação. Podem estar elencados dentro de um programa, caso possuam inter-relação entre si, ou se conectar diretamente à macroação.`,
        title: 'Projetos',
        form_component: <ProjectsForm />
      },
      {
        id: 23,
        layer: 'camada_tatica',
        filled: false,
        navigable: false,
        tooltip: 'Exemplo: “Formação da Comissão Permanente de Segurança Viária” (Fonte: Plano de Segurança Viária)',
        optional: true,
        code: 'produtos_entregas',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Produtos/Entregas',
        form_component: <ProductsAndDeliveriesForm />
      },
    ],
    finalizacao_plano: [
      {
        id: 24,
        filled: false,
        clicked: false,
        optional: false,
        description: `Na etapa de conclusão do seu Plano, você passará por cinco etapas principais para finalizar e enviar seu preenchimento:`,
        navigable: false,
        layer_indicator: true,
        code: "finalizacao_plano",
        title: 'Conclusão',
        layer: "finalizacao_plano",
        menu_layer: 'finalizacao_plano',
        form_component: <ConclusionLayerForm />
      },
      {
        id: 25,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Finalize seu Plano com os detalhes essenciais. Inclua o Ato Normativo de Aprovação, palavras-chave relevantes, uma foto de capa atrativa e um ícone representativo. Faça o upload do PDF completo do Plano e um resumo executivo em PDF para uma visão geral rápida. Certifique-se de que todas as informações estejam precisas para garantir a conformidade e a clareza do seu Plano.',
        navigable: false,
        code: "dados_finais",
        title: 'Dados Finais',
        layer: "finalizacao_plano",
        form_component: <FinalDataForm />
      },
      {
        id: 26,
        filled: false,
        optional: false,
        navigable: false,
        layer: 'finalizacao_plano',
        code: 'vinculado_pds',
        description: `Alinhe seu Plano com os objetivos, metas e macroações dos planos de longo prazo da prefeitura. Essa vinculação é essencial para garantir que seu Plano esteja em sintonia com a visão estratégica da cidade, promovendo coesão e continuidade nas ações públicas. Ao fazer essa conexão, você contribui para a realização das metas municipais de forma integrada e sustentável, fortalecendo o compromisso com o desenvolvimento urbano e social. `,
        title: 'Vinculação',
        label_2: 'Vinculação ao Plano de longo prazo',
        form_component: <NextLinkingForm />
      },
      {
        id: 27,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Antes de prosseguir, utilize a ferramenta de verificação de pendências. O sistema checará se todos os campos obrigatórios foram preenchidos, ajudando a identificar e corrigir possíveis omissões. Você só poderá seguir para a próxima etapa se não houver pendências apontadas. Observe que essa verificação pode demorar dependendo do tamanho do Plano, garantindo que todas as informações necessárias estejam completas e corretas.',
        navigable: false,
        code: "verificacao_pendencia",
        layer: 'finalizacao_plano',
        menu_layer: 'verificacao_pendencia',
        title: 'Verificação de pendências',
        form_component: <PendingCheckLayerForm />
      },
      // {
      //   id: 28,
      //   filled: false,
      //   layer: 'pre-visualizacao',
      //   menu_layer: 'pre-visualizacao',
      //   optional: false,
      //   clicked: true,
      //   code: 'pre_visualizacao',
      //   description: `Visualize uma prévia do Plano diretamente no sistema. Isso permite que você revise e valide todas as seções do documento, garantindo que tudo esteja conforme o esperado.`,
      //   title: 'Pré-visualização',
      //   form_component: <PreviewLayerForm />
      // },
      {
        id: 28,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        code: 'consideracao',
        title: 'Considerações',
        layer: 'finalizacao_plano',
        menu_layer: 'consideracao_plano',
        form_component: <ConsiderationLayerForm />,
        description: `Na Página de Considerações, você pode visualizar os comentários inseridos pelo órgão revisor. Esses comentários são organizados por data de revisão, permitindo uma navegação fácil e estruturada. Essa funcionalidade ajuda você a acompanhar o feedback recebido, garantindo que todas as observações e sugestões sejam devidamente consideradas e atendidas. Acompanhe cada revisão para ajustar e aprimorar seu Plano conforme necessário, garantindo um processo de aprovação mais eficiente e transparente.`,
      },
      {
        id: 29,
        layer: 'finalizacao_plano',
        filled: false,
        clicked: true,
        optional: false,
        navigable: false,
        code: 'envio_epl',
        description: `Na sessão de Envio do Plano, você pode tomar a decisão de incluir o Plano no sistema, oficializando sua aprovação, ou devolvê-lo ao órgão responsável para ajustes com base nas considerações recebidas. Essa etapa finaliza o processo de revisão, assegurando que o Plano esteja pronto para implementação após considerar todas as recomendações relevantes. `,
        title: 'Envio do plano',
        menu_layer: 'envio_epl',
        form_component: <SendingPlanForm />
      },

    ]
  },
  plano_gestao: {
    pre_cadastro: [
      {
        id: 0,
        filled: false,
        clicked: false,
        optional: false,
        navigable: true,
        layer: 'pre_cadastro',
        code: 'pre_cadastro',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Pré-cadastro',
        form_component: <PreRegistrationForm />
      }
    ],
    camada_diagnostica: [
      {
        id: 1,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_diagnostica",
        title: 'Camada Diagnóstica',
        layer: 'camada_diagnostica',
        menu_layer: 'camada_diagnostica',
        form_component: <DiagnosisLayerForm />
      },
      {
        id: 2,
        layer: 'camada_diagnostica',
        filled: false,
        optional: false,
        navigable: false,
        tooltip: `Exemplo: Taxa de mortalidade infantil na cidade do Rio de Janeiro acima do valor de referência da OMS, com velocidade de redução abaixo da média dos 100 maiores municípios do Brasil nos últimos 10 anos (problema/fragilidade);Elevado número de médicos ginecologistas, obstetras e pediatras por habitante, disponíveis na rede pública municipal do Rio de Janeiro, indicador que posiciona o Rio de Janeiro entre as 5 melhores capitais do país (força)`,
        code: 'sintese_diagnostico',
        description: `São os principais problemas que deverão ser reduzidos ou superados e, também, potencialidades que poderão ser melhor exploradas no horizonte de vigência do Plano. Consiste nas principais saídas (“outputs”) do diagnóstico de uma área/tema/dimensão ou da organização. Sua origem deriva da identificação de fragilidades e potencialidades, ou seja, de um lado, são fraquezas, passivos estruturais e indicadores que apresentaram trajetória preocupante e, de outro, são forças e ativos que podem ser melhor utilizados.`,
        title: 'Síntese do Diagnóstico',
        form_component: <SummaryDiagnosisForm />
      },
    ],
    camada_estrategica: [
      {
        id: 3,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_estrategica",
        layer: 'camada_estrategica',
        title: 'Camada Estratégica',
        menu_layer: 'camada_estrategica',
        form_component: <StrategicLayerForm />
      },
      {
        id: 4,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        code: 'eixos_tematicos',
        layer: 'camada_estrategica',
        tooltip: 'Exemplo: “Longevidade, Bem-Estar e Território Conectado; Igualdade e Equidade; Desenvolvimento Econômico, Competitividade e Inovação; Mudanças Climáticas e Resiliência; Cooperação e Paz; Governança” (Fonte: Plano Estratégico 2021 – 2024)',
        description: `Eixos de concentração de esforços e recursos visando às transformações e melhorias desejadas para o alcance da visão de futuro, considerando o objeto do plano. Também podem ser chamados de temas transversais e áreas de resultado.`,
        title: 'Eixo Temático',
        form_component: <ThematicAxisForm />
      },
      {
        id: 5,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        tooltip: 'Exemplo:  “I – universalização da alfabetização garantida ao longo da vida e em todas as modalidades de ensino” (Fonte: Plano Municipal de Educação.)',
        code: 'diretrizes',
        description: `Definição de grandes escolhas que orientam os rumos da área/tema/dimensão ou organização e a forma de atuação dos executores e demais atores envolvidos no plano. Quando presentes no Plano, são desdobradas em objetivos e macroações (ou ações estruturantes).`,
        title: 'Diretrizes',
        form_component: <GuidelinesForm />
      },
      {
        id: 6,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Objetivos',
        form_component: <ObjectivesDemonstrationForm />
      },
      {
        id: 7,
        filled: false,
        clicked: false,
        optional: false,
        act: true,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo_form',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `São resultados prioritários que devem ser alcançados ou mantidos no horizonte temporal de vigência do plano. O objetivo deve sintetizar a transformação que se buscará efetivar. Devem obedecer aos seguintes critérios: pertinentes, mensuráveis, motivadores, inteligíveis e alcançáveis.`,
        title: 'Objetivos',
        form_component: <ObjectivesForm />
      },
      {
        id: 8,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA).`,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 9,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <Similarity type='objetivos' />
      },
      {
        id: 10,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas',
        navigable: false,
        code: 'metas',
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        form_component: <GoalsDemonstrationForm />
      },
      {
        id: 11,
        filled: false,
        clicked: false,
        optional: false,
        atc: true,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas_form',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        tooltip: `Exemplo: Objetivo: Acelerar a redução  da mortalidade infantil no município do Rio de Janeiro para alcançar o padrão de referência da OMS. Meta: Reduzir a Taxa de Mortos por Nascidos Vivos na Cidade do Rio de Janeiro de 14,4 para 10 até 2026.`,
        form_component: <GoalsForm />
      },
      {
        id: 12,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 13,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='goals' />
      },
    ],
    camada_tatica: [
      {
        id: 14,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        navigable: false,
        layer_indicator: true,
        code: "camada_tatica",
        title: 'Camada Tática',
        layer: "camada_tatica",
        menu_layer: 'camada_tatica',
        form_component: <TaticLayerForm />
      },
      {
        id: 15,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes',
        code: 'macroacoes',
        description: `Também chamadas de ações estruturantes, linhas de ação ou mesmo estratégias específicas, indicam o que deve ser feito para, em seu conjunto, produzir os resultados almejados e, consequentemente, atingir os objetivos e metas estabelecidas. Cada macroação deverá conectar-se a pelo menos um objetivo estabelecido na camada estratégica do plano.`,
        title: 'Macroações',
        form_component: <MacroactionDemonstrationForm />
      },
      {
        id: 16,
        filled: false,
        clicked: false,
        optional: false,
        tooltip: `Exemplo: “Criar campanhas e conteúdos infanto-juvenis em planejamento sustentável com periodicidade para alimentar a plataforma Participa.rio.” (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)`,
        navigable: false,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes_form',
        code: 'macroacoes',
        demostration_layer: true,
        description: `Também chamadas de ações estruturantes, linhas de ação ou mesmo estratégias específicas, indicam o que deve ser feito para, em seu conjunto, produzir os resultados almejados e, consequentemente, atingir os objetivos e metas estabelecidas. Cada macroação deverá conectar-se a pelo menos um objetivo estabelecido na camada estratégica do plano.`,
        title: 'Macroações',
        form_component: <MacroactionForm />
      },
      {
        id: 17,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        demostration_layer: true,
        code: 'macroacoes',
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <SimilarityMacroactionDemonstrationForm />
      },
      {
        id: 18,
        filled: false,
        clicked: false,
        demostration_layer: true,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        code: 'macroacoes',
        description: `A Ferramenta de Similaridade ajuda você a comparar suas macroações com as macroações de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='macroacoes' />
      },
      {
        id: 19,
        filled: false,
        optional: true,
        navigable: false,
        layer: 'camada_tatica',
        code: 'iniciativas',
        tooltip: 'Exemplo: “Iniciativa Estratégica Envelhecimento Ativo e Saudável” (Fonte: Plano Estratégico da Cidade do Rio de Janeiro 2021-2024)',
        description: `Compreendem um conjunto de programas e projetos que, em seu conjunto, auxiliam na consecução das estratégias estabelecidas.`,
        title: 'Iniciativas',
        form_component: <InitiativesForm />
      },
      {
        id: 20,
        filled: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        code: 'projetos',
        tooltip: 'Exemplo: Projeto “Aleitamento Materno”, voltado à conscientização e incentivo do aleitamento materno na Zona Norte do Rio de Janeiro”',
        description: `Ações com escopo, recursos alocados, prazo e entregas definidas, que auxiliam na conclusão de uma macroação. Podem estar elencados dentro de um programa, caso possuam inter-relação entre si, ou se conectar diretamente à macroação.`,
        title: 'Projetos',
        form_component: <ProjectsForm />
      },
      {
        id: 21,
        filled: false,
        layer: 'camada_tatica',
        optional: true,
        navigable: false,
        tooltip: 'Exemplo: “Formação da Comissão Permanente de Segurança Viária” (Fonte: Plano de Segurança Viária)',
        code: 'produtos_entregas',
        description: `Os produtos ou entregas são os resultados que decorrem da execução do programa/projeto. Para que o projeto seja concluído com sucesso, há uma série de atividades que precisam ser desenvolvidas, gerando, como “saídas” (outputs), entregas e produtos.`,
        title: 'Produtos/Entregas',
        form_component: <ProductsAndDeliveriesForm />
      },
    ],
    finalizacao_plano: [
      {
        id: 22,
        filled: false,
        clicked: false,
        optional: false,
        description: `Na etapa de conclusão do seu Plano, você passará por cinco etapas principais para finalizar e enviar seu preenchimento:`,
        navigable: false,
        code: "finalizacao_plano",
        layer: "finalizacao_plano",
        title: 'Conclusão',
        layer_indicator: true,
        menu_layer: 'finalizacao_plano',
        form_component: <ConclusionLayerForm />
      },
      {
        id: 23,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Finalize seu Plano com os detalhes essenciais. Inclua o Ato Normativo de Aprovação, palavras-chave relevantes, uma foto de capa atrativa e um ícone representativo. Faça o upload do PDF completo do Plano e um resumo executivo em PDF para uma visão geral rápida. Certifique-se de que todas as informações estejam precisas para garantir a conformidade e a clareza do seu Plano.',
        navigable: false,
        code: "dados_finais",
        title: 'Dados Finais',
        layer: "finalizacao_plano",
        form_component: <FinalDataForm />
      },
      {
        id: 24,
        filled: false,
        optional: false,
        navigable: false,
        layer: "finalizacao_plano",
        code: 'vinculado_pds',
        description: `Alinhe seu Plano com os objetivos, metas e macroações dos planos de longo prazo da prefeitura. Essa vinculação é essencial para garantir que seu Plano esteja em sintonia com a visão estratégica da cidade, promovendo coesão e continuidade nas ações públicas. Ao fazer essa conexão, você contribui para a realização das metas municipais de forma integrada e sustentável, fortalecendo o compromisso com o desenvolvimento urbano e social. `,
        title: 'Vinculação',
        label_2: 'Vinculação ao Plano de longo prazo',
        form_component: <NextLinkingForm />
      },
      {
        id: 25,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Antes de prosseguir, utilize a ferramenta de verificação de pendências. O sistema checará se todos os campos obrigatórios foram preenchidos, ajudando a identificar e corrigir possíveis omissões. Você só poderá seguir para a próxima etapa se não houver pendências apontadas. Observe que essa verificação pode demorar dependendo do tamanho do Plano, garantindo que todas as informações necessárias estejam completas e corretas.',
        navigable: false,
        code: "verificacao_pendencia",
        layer: "finalizacao_plano",
        menu_layer: 'verificacao_pendencia',
        title: 'Verificação de pendências',
        form_component: <PendingCheckLayerForm />
      },
      {
        id: 26,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'finalizacao_plano',
        navigable: false,
        code: 'consideracao',
        description: `Na Página de Considerações, você pode visualizar os comentários inseridos pelo órgão revisor. Esses comentários são organizados por data de revisão, permitindo uma navegação fácil e estruturada. Essa funcionalidade ajuda você a acompanhar o feedback recebido, garantindo que todas as observações e sugestões sejam devidamente consideradas e atendidas. Acompanhe cada revisão para ajustar e aprimorar seu Plano conforme necessário, garantindo um processo de aprovação mais eficiente e transparente.`,
        menu_layer: 'consideracao_plano',
        title: 'Considerações',
        form_component: <ConsiderationLayerForm />
      },
      // {
      //   id: 25,
      //   filled: false,
      //   optional: false,
      //   clicked: true,
      //   layer: 'pre-visualizacao',
      //   menu_layer: 'pre-visualizacao',
      //   code: 'pre_visualizacao',
      //   description: `Visualize uma prévia do Plano diretamente no sistema. Isso permite que você revise e valide todas as seções do documento, garantindo que tudo esteja conforme o esperado.`,
      //   title: 'Pré-visualização',
      //   form_component: <PreviewLayerForm />
      // },
      {
        id: 27,
        filled: false,
        layer: 'finalizacao_plano',
        optional: false,
        clicked: true,
        navigable: false,
        code: 'envio_epl',
        description: `Na sessão de Envio do Plano, você pode tomar a decisão de incluir o Plano no sistema, oficializando sua aprovação, ou devolvê-lo ao órgão responsável para ajustes com base nas considerações recebidas. Essa etapa finaliza o processo de revisão, assegurando que o Plano esteja pronto para implementação após considerar todas as recomendações relevantes. `,
        title: 'Envio do plano',
        menu_layer: 'envio_epl',
        form_component: <SendingPlanForm />
      },
    ]
  },
  plano_orcamentario: {
    pre_cadastro: [
      {
        id: 0,
        filled: false,
        clicked: false,
        optional: false,
        navigable: true,
        code: 'pre_cadastro',
        layer: 'pre_cadastro',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Pré-cadastro',
        form_component: <PreRegistrationForm />
      }
    ],
    camada_diagnostica: [
      {
        id: 1,
        filled: false,
        clicked: false,
        optional: false,
        layer_indicator: true,
        description: ``,
        navigable: false,
        code: "camada_diagnostica",
        title: 'Camada Diagnóstica',
        menu_layer: 'camada_diagnostica',
        layer: 'camada_diagnostica',
        form_component: <DiagnosisLayerForm />
      },
      {
        id: 2,
        filled: false,
        layer: 'camada_diagnostica',
        optional: true,
        navigable: false,
        code: 'sintese_diagnostico',
        tooltip:`Exemplo: Taxa de mortalidade infantil na cidade do Rio de Janeiro acima do valor de referência da OMS, com velocidade de redução abaixo da média dos 100 maiores municípios do Brasil nos últimos 10 anos (problema/fragilidade);Elevado número de médicos ginecologistas, obstetras e pediatras por habitante, disponíveis na rede pública municipal do Rio de Janeiro, indicador que posiciona o Rio de Janeiro entre as 5 melhores capitais do país (força)`,
        description: `São os principais problemas que deverão ser reduzidos ou superados e, também, potencialidades que poderão ser melhor exploradas no horizonte de vigência do Plano. Consiste nas principais saídas (“outputs”) do diagnóstico de uma área/tema/dimensão ou da organização. Sua origem deriva da identificação de fragilidades e potencialidades, ou seja, de um lado, são fraquezas, passivos estruturais e indicadores que apresentaram trajetória preocupante e, de outro, são forças e ativos que podem ser melhor utilizados.`,
        title: 'Síntese do Diagnóstico',
        form_component: <SummaryDiagnosisForm />
      },
    ],
    camada_estrategica: [
      {
        id: 3,
        filled: false,
        clicked: false,
        optional: false,
        description: ``,
        layer_indicator: true,
        navigable: false,
        code: "camada_estrategica",
        title: 'Camada Estratégica',
        layer: "camada_estrategica",
        menu_layer: 'camada_estrategica',
        form_component: <StrategicLayerForm />
      },
      {
        id: 4,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_estrategica',
        code: 'eixos_tematicos',
        tooltip: 'Exemplo: “Longevidade, Bem-Estar e Território Conectado; Igualdade e Equidade; Desenvolvimento Econômico, Competitividade e Inovação; Mudanças Climáticas e Resiliência; Cooperação e Paz; Governança” (Fonte: Plano Estratégico 2021 – 2024)',
        description: `Eixos de concentração de esforços e recursos visando às transformações e melhorias desejadas para o alcance da visão de futuro, considerando o objeto do plano. Também podem ser chamados de temas transversais e áreas de resultado.`,
        title: 'Eixo Temático',
        form_component: <ThematicAxisForm />
      },
      {
        id: 5,
        layer: 'camada_estrategica',
        navigable: false,
        filled: false,
        clicked: false,
        optional: false,
        tooltip: 'Exemplo:  “I – universalização da alfabetização garantida ao longo da vida e em todas as modalidades de ensino” (Fonte: Plano Municipal de Educação.)',
        code: 'diretrizes',
        description: `Definição de grandes escolhas que orientam os rumos da área/tema/dimensão ou organização e a forma de atuação dos executores e demais atores envolvidos no plano. Quando presentes no Plano, são desdobradas em objetivos e macroações (ou ações estruturantes).`,
        title: 'Diretrizes',
        form_component: <GuidelinesForm />
      },
      {
        id: 6,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        description: `Nesta seção, você deve preencher os metadados dos planos. 
        Nas dicas de ferramentas (tooltips), você pode consultar 
        exemplos de como esses dados aparecem no Interplan.Rio. 
        Caso necessário, você pode revisar essas informações a 
        qualquer momento.`,
        title: 'Objetivos',
        form_component: <ObjectivesDemonstrationForm />
      },
      {
        id: 7,
        filled: false,
        clicked: false,
        optional: false,
        atc: true,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_objetivo_form',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `São resultados prioritários que devem ser alcançados ou mantidos no horizonte temporal de vigência do plano. O objetivo deve sintetizar a transformação que se buscará efetivar. Devem obedecer aos seguintes critérios: pertinentes, mensuráveis, motivadores, inteligíveis e alcançáveis.`,
        title: 'Objetivos',
        form_component: <ObjectivesForm />
      },
      {
        id: 8,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA).`,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 9,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'objetivos',
        tooltip: 'Exemplo: “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde)',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <Similarity type='objetivos' />
      },
      {
        id: 10,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas',
        navigable: false,
        code: 'metas',
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        form_component: <GoalsDemonstrationForm />
      },
      {
        id: 11,
        filled: false,
        clicked: false,
        optional: false,
        atc: true,
        layer: 'camada_estrategica',
        menu_layer: 'camada_estrategica_metas_form',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Metas',
        tooltip: `Exemplo: Objetivo: Acelerar a redução  da mortalidade infantil no município do Rio de Janeiro para alcançar o padrão de referência da OMS. Meta: Reduzir a Taxa de Mortos por Nascidos Vivos na Cidade do Rio de Janeiro de 14,4 para 10 até 2026.`,
        form_component: <GoalsForm />
      },
      {
        id: 12,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA).`,
        title: 'Similaridade',
        form_component: <SimilarityDemonstrationForm />
      },
      {
        id: 13,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'camada_estrategica',
        navigable: false,
        code: 'metas',
        demostration_layer: true,
        description: <b>Utilize a Ferramenta de Similaridade para obter insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.</b>,
        title: 'Similaridade',
        form_component: <Similarity type='goals' />
      },
    ],
    camada_tatica: [
      {
        id: 14,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        description: ``,
        layer_indicator: true,
        code: "camada_tatica",
        title: 'Camada Tática',
        layer: "camada_tatica",
        menu_layer: 'camada_tatica',
        form_component: <TaticLayerForm />
      },
      {
        id: 15,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes',
        code: 'macroacoes',
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Macroações',
        form_component: <MacroactionDemonstrationForm />
      },
      {
        id: 16,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        demostration_layer: true,
        code: 'macroacoes',
        tooltip: `Exemplo: “Criar campanhas e conteúdos infanto-juvenis em planejamento sustentável com periodicidade para alimentar a plataforma Participa.rio.” (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)`,
        atc: true,
        layer: 'camada_tatica',
        menu_layer: 'camada_tatica_macroacoes_form',
        description: `Quantificação da visão de futuro ou dos objetivos, as metas dimensionam o esforço a ser empreendido da situação atual aos horizontes estabelecidos. Estão associadas a indicadores e devem ser específicas, mensuráveis, atingíveis, relevantes e temporais, podendo ser de curto, médio e longo prazos.`,
        title: 'Macroações',
        form_component: <MacroactionForm />
      },
      {
        id: 17,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        demostration_layer: true,
        code: 'macroacoes',
        layer: 'camada_tatica',
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA).`,
        title: 'Similaridade',
        form_component: <SimilarityMacroactionDemonstrationForm />
      },
      {
        id: 18,
        filled: false,
        clicked: false,
        optional: false,
        navigable: false,
        demostration_layer: true,
        layer: 'camada_tatica',
        code: 'macroacoes',
        description: `A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). Obtenha insights valiosos e alinhamentos estratégicos, facilitando o planejamento e a tomada de decisões com base em comparações precisas e detalhadas.`,
        title: 'Similaridade',
        form_component: <Similarity type='macroacoes' />
      },
      {
        id: 19,
        filled: false,
        optional: false,
        navigable: false,
        tooltip: 'Exemplo: Programa “Mães Cariocas”, voltado à atenção de mães em situação de vulnerabilidade social na Zona Norte do Rio de Janeiro”',
        layer: 'camada_tatica',
        code: 'programas',
        description: `Consistem em um conjunto de projetos gerenciados de modo coordenado com vistas à conclusão de uma macroação. `,
        title: 'Programas',
        form_component: <ProgramsForm />
      },
      {
        id: 20,
        filled: false,
        optional: false,
        navigable: false,
        layer: 'camada_tatica',
        code: 'projetos',
        tooltip: 'Exemplo: Projeto “Aleitamento Materno”, voltado à conscientização e incentivo do aleitamento materno na Zona Norte do Rio de Janeiro”',
        description: `Ações com escopo, recursos alocados, prazo e entregas definidas, que auxiliam na conclusão de uma macroação. Podem estar elencados dentro de um programa, caso possuam inter-relação entre si, ou se conectar diretamente à macroação.`,
        title: 'Projetos',
        form_component: <ProjectsForm />
      },
      {
        id: 21,
        filled: false,
        optional: true,
        navigable: false,
        layer: 'camada_tatica',
        code: 'atividades',
        tooltip: 'Exemplo: Contratar tutores para a Biblioteca Cecília Meireles em Jacarepaguá - AP 4, para o atendimento de adolescentes em medida socioeducativa. (Fonte: PPA 2022-2025)',
        description: `São tarefas que permitem a execução de projetos ou iniciativas, envolvendo alocação de recursos e monitoramento de desempenho, quando possível. São cruciais para transformar o “planejado” em “realizado”.`,
        title: 'Atividades',
        form_component: <ActivitiesForm />
      },
      {
        id: 22,
        filled: false,
        layer: 'camada_tatica',
        optional: true,
        navigable: false,
        code: 'produtos_entregas',
        tooltip: 'Exemplo: “Formação da Comissão Permanente de Segurança Viária” (Fonte: Plano de Segurança Viária)',
        description: `Os produtos ou entregas são os resultados que decorrem da execução do programa/projeto. Para que o projeto seja concluído com sucesso, há uma série de atividades que precisam ser desenvolvidas, gerando, como “saídas” (outputs), entregas e produtos.`,
        title: 'Produtos/Entregas',
        form_component: <ProductsAndDeliveriesForm />
      },
    ],
    finalizacao_plano: [
      {
        id: 23,
        filled: false,
        clicked: false,
        optional: false,
        description: `Na etapa de conclusão do seu Plano, você passará por cinco etapas principais para finalizar e enviar seu preenchimento:`,
        navigable: false,
        layer_indicator: true,
        code: "finalizacao_plano",
        title: 'Conclusão',
        layer: "finalizacao_plano",
        menu_layer: 'finalizacao_plano',
        form_component: <ConclusionLayerForm />
      },
      {
        id: 24,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Finalize seu Plano com os detalhes essenciais. Inclua o Ato Normativo de Aprovação, palavras-chave relevantes, uma foto de capa atrativa e um ícone representativo. Faça o upload do PDF completo do Plano e um resumo executivo em PDF para uma visão geral rápida. Certifique-se de que todas as informações estejam precisas para garantir a conformidade e a clareza do seu Plano.',
        navigable: false,
        code: "dados_finais",
        title: 'Dados Finais',
        layer: "finalizacao_plano",
        form_component: <FinalDataForm />
      },
      {
        id: 25,
        filled: false,
        optional: false,
        navigable: false,
        code: 'vinculado_pds',
        layer: "finalizacao_plano",
        description: `Alinhe seu Plano com os objetivos, metas e macroações dos planos de longo prazo da prefeitura. Essa vinculação é essencial para garantir que seu Plano esteja em sintonia com a visão estratégica da cidade, promovendo coesão e continuidade nas ações públicas. Ao fazer essa conexão, você contribui para a realização das metas municipais de forma integrada e sustentável, fortalecendo o compromisso com o desenvolvimento urbano e social. `,
        title: 'Vinculação',
        label_2: 'Vinculação ao Plano de longo prazo',
        form_component: <NextLinkingForm />
      },
      {
        id: 26,
        filled: false,
        clicked: false,
        optional: false,
        description: 'Antes de prosseguir, utilize a ferramenta de verificação de pendências. O sistema checará se todos os campos obrigatórios foram preenchidos, ajudando a identificar e corrigir possíveis omissões. Você só poderá seguir para a próxima etapa se não houver pendências apontadas. Observe que essa verificação pode demorar dependendo do tamanho do Plano, garantindo que todas as informações necessárias estejam completas e corretas.',
        navigable: false,
        code: "verificacao_pendencia",
        layer: "finalizacao_plano",
        menu_layer: 'verificacao_pendencia',
        title: 'Verificação de pendências',
        form_component: <PendingCheckLayerForm />
      },
      // {
      //   id: 27,
      //   filled: false,
      //   optional: false,
      //   clicked: true,
      //   navigable: false,
      //   code: 'pre_visualizacao',
      //   layer: 'pre-visualizacao',
      //   menu_layer: 'pre-visualizacao',
      //   description: `Visualize uma prévia do Plano diretamente no sistema. Isso permite que você revise e valide todas as seções do documento, garantindo que tudo esteja conforme o esperado.`,
      //   title: 'Pré-visualização',
      //   form_component: <PreviewLayerForm />
      // },
      {
        id: 27,
        filled: false,
        clicked: false,
        optional: false,
        layer: 'finalizacao_plano',
        navigable: false,
        code: 'consideracao',
        description: `Na Página de Considerações, você pode visualizar os comentários inseridos pelo órgão revisor. Esses comentários são organizados por data de revisão, permitindo uma navegação fácil e estruturada. Essa funcionalidade ajuda você a acompanhar o feedback recebido, garantindo que todas as observações e sugestões sejam devidamente consideradas e atendidas. Acompanhe cada revisão para ajustar e aprimorar seu Plano conforme necessário, garantindo um processo de aprovação mais eficiente e transparente.`,
        menu_layer: 'consideracao_plano',
        title: 'Considerações',
        form_component: <ConsiderationLayerForm />
      },
      {
        id: 28,
        filled: false,
        layer: 'finalizacao_plano',
        optional: false,
        clicked: false,
        navigable: false,
        // tooltip: 'lorem ipsum',
        code: 'envio_epl',
        description: `Na sessão de Envio do Plano, você pode tomar a decisão de incluir o Plano no sistema, oficializando sua aprovação, ou devolvê-lo ao órgão responsável para ajustes com base nas considerações recebidas. Essa etapa finaliza o processo de revisão, assegurando que o Plano esteja pronto para implementação após considerar todas as recomendações relevantes. `,
        title: 'Envio do plano',
        menu_layer: 'envio_epl',
        form_component: <SendingPlanForm />
      },
    ]
  }
} as IObjectMenuProps;

export const codes_case_04 = [
  { code: 'pre_cadastro', pos: null },
  { code: 'sintese_diagnostico', layer: 'camada_diagnostica', pos: 1 },
  { code: "sintese_cenario", layer: 'camada_diagnostica', pos: 2 },
  { code: "visao", layer: 'camada_estrategica', pos: 2, },
]

interface IupdateFilledAndNavigableStatus {
  plan: any;
  codes: any;
  navigableId?: number
}

export const updateFilledAndNavigableStatusItem = ({ codes, plan, navigableId }: IupdateFilledAndNavigableStatus) => {
  let nextNavigableId: any = null;
  let lastItem = {} as Record<string, any>

  const updatedSections = Object.keys(plan).reduce((acc: any, section) => {
    acc[section] = plan[section].map((item: any, index: any, array: any) => {
      if (codes?.includes(item.code)) {
        nextNavigableId = item.id + 1;
        lastItem = { ...item, filled: true, navigable: true }
        return { ...item, filled: true, navigable: true };
      }

      if (item.id === nextNavigableId) {
        lastItem = { ...item, navigable: true }

        return { ...item, navigable: true }
      }

      if (item.id === nextNavigableId + 1 && array[index - 1]?.layer_indicator) {
        lastItem = { ...item, navigable: true }

        return { ...item, navigable: true }
      }

      if (item.atc && lastItem.code == item.code && lastItem?.navigable) {
        lastItem = { ...item, navigable: true }
        return { ...item, navigable: true }
      }

      if (codes.includes(item.code) && item.demostration_layer) {
        lastItem = { ...item, navigable: true }

        return { ...item, navigable: true }
      }

      return item;
    });
    return acc;
  }, {});

  return {
    data: updatedSections,
    nextNavigableId,
  };
};



export const findingMenuItemKey = (cardItem: ICardProps) => {
  switch (cardItem.title) {
    case 'Plano de Estado':
      return 'plano_estado'
    case "Plano Setorial":
      return 'plano_setorial'
    case 'Plano Institucional':
      return 'plano_institucional'
    case 'Plano de Gestão':
      return 'plano_gestao';
    case 'Plano Orçamentário':
      return 'plano_orcamentario';
    default:
      return "plano_estado"
  }
}


export const findingMenuItemKeyById = (id: number) => {
  switch (id) {
    case 1:
      return 'plano_setorial'
    case 2:
      return 'plano_institucional'
    case 3:
      return 'plano_estado'
    case 4:
      return 'plano_gestao';
    case 5:
      return 'plano_orcamentario';
    default:
      return "plano_estado"
  }
}