import React from "react"
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { isNumber } from "lodash";
import { Grid } from '@mui/material'
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ReutilizarElaborationDialogV2 } from "../Vision/ReutilizarModal";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DeleteIcon, transformGuidelineAspirationStrategy } from "../utils";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { Organograma } from "@/utils/organograma/Organograma";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";

function validateArray(array: any) {
  if (!Array.isArray(array)) {
    throw new TypeError('O parâmetro deve ser um array.');
  }

  for (let i = 0; i < array.length; i++) {
    const innerArray = array[i];

    const hasValidItem = innerArray.some((item: any) => {
      const hasTexto = item.texto !== null;
      const hasData = item.data !== null;

      return hasTexto && hasData;
    });

    if (hasValidItem) {
      return { error: null };
    }
  }

  return { error: 'Ao menos um conjunto deve ter tanto o campo "texto" quanto o campo "data" preenchidos.', index: array.length > 0 ? [array.length - 1, 0] : [0, 0] };
}

type InputsStrategy = {
  estrategia: any
  is_optional?: boolean;
};

const defaultValue = {
  id: '',
  texto: '',
  data: null
}

const SchemaStrategy = Yup.object().shape({
  estrategia: Yup.array().required('Campo obrigatório')
});

export const EstrategyForm: React.FC = () => {
  const {
    watch: watchStrategy,
    setError: setErrorStrategy,
    control: controlStrategy,
    register: registerStrategy,
    setValue: setValueStrategy,
    handleSubmit: handleSubmitStrategy,
    formState: { errors: errosStrategy } } = useForm<InputsStrategy>({
      resolver: yupResolver(SchemaStrategy),
      defaultValues: { estrategia: [[defaultValue]] }
    });

  const { update: updateStrategy } = useFieldArray({
    control: controlStrategy,
    name: "estrategia"
  });
  const fields = watchStrategy("estrategia");
  const is_optional = watchStrategy('is_optional') as unknown as string;

  const router = useRouter();
  const [data, setData] = React.useState<any[]>([]);
  const replaceButtonRef = React.useRef(null) as any;
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [loading, setLoading] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [deleteField, setDeleteField] = React.useState([]);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [fieldIndexItem, setFieldIndexItem] = React.useState(0);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openDeleteModalId, setOpenDeleteModalId] = React.useState(0);
  const [deleteStrategyId, setDeleteStrategyId] = React.useState(null);
  const [reuseClickedIndex, setReuseClickedIndex] = React.useState(null);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, completCode, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId } = usePreparationOfPlans();
  const { EstrategyElaboration, GetThematicAxisElaboration, GetEstrategyElaboration, DeleteStrategyElaborations, PostProgressCode } = new ElaborationService();

  const handleAddLine = (field: any, it: any, fieldIndex: any) => {
    const newPlanejados = [...field, { texto: '', data: null, eixo_tematico_id: it.eixo_tematico_id }];
    updateStrategy(fieldIndex, newPlanejados);
  };

  const handleDelete = (field: any, fieldIndex: any, itIndex: any) => {
    updateStrategy(fieldIndex, field.filter((_: any, index: any) => index !== itIndex));
    setOpenDeleteModal(false);
  };

  React.useEffect(() => {
    const { id } = router.query;
    const { pathname } = router;
    setLoading(true);
    if (id && pathname.includes('/elaboracao_planos') || pathname.includes('/aprovacao_planos') && tipoFluxo != 'substituir') {
      const fetchData = async () => {
        try {
          const { data: axisData } = await GetThematicAxisElaboration(router.query.id);
          const axisLabel = axisData.map((item: any) => ({
            label: item.texto,
            eixo_tematico_id: item.id,
          }));

          const { data } = await GetEstrategyElaboration(id);
          const parsedData = data.filter((item: any) => item?.eixoTematico)

          const transformedArray = transformGuidelineAspirationStrategy(parsedData, axisLabel);
          if (transformedArray.length > 0) {
            setValueStrategy('estrategia', transformedArray);
            setLoading(false);
          }
        } catch (error) {
          console.error('Failed to fetch aspiration elaboration data:', error);
        }
      }

      fetchData();
    };
  }, [router.query.id, router.pathname, tipoFluxo, completCode]);

  React.useEffect(() => {
    const { pathname } = router;

    if (planId && pathname.includes('/elaboracao_planos') || pathname.includes('/aprovacao_planos') && tipoFluxo == 'substituir') {
      const fetchData = async () => {
        try {
          const { data } = await GetEstrategyElaboration(planId);
          const parsedData = data.filter((item: any) => item?.eixoTematico)
          if (parsedData.length > 0) {
            const transformedArray = parsedData.map((item: any) => ({ data: item.data, texto: item.texto }))
            setData(transformedArray);
          }

        } catch (error) {
          console.error('Failed to fetch aspiration elaboration data:', error);
        }
      };

      fetchData();
    }
  }, [planId, router.pathname, tipoFluxo, completCode]);

  React.useEffect(() => {
    const code = completCode?.filter(item => item?.code == 'estrategia');
    if (code.length > 0) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [completCode]);

  React.useEffect(() => {
    const code = completCode?.find(item => item?.code == 'estrategia' && item.skipped == false);

    if (code != undefined) {
      setValueStrategy('is_optional', true)
    }
  }, [completCode]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router])

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const { insertItemById, findNewItemsWithId, updateItemById, checkLayerExists, deleteItemById } = useHierarchyPreparationOfPlans();
  const { startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    if (disabeSubmit) return;

    setDisableSubmit(true);
    const estrategiaCode = completCode.find(item => item.code == 'estrategia')

    const { estrategia } = data;

    const { error } = validateArray(estrategia);

    if (error != null) {
      setErrorStrategy('root', { message: error });
      return;
    }

    const filteredArrays = estrategia.map((subArray: any) =>
      subArray.filter((item: any) => !(item.texto === null || (item.texto === "" && item.data === null)))
    ).filter((subArray: any) => subArray.length > 0).flat();

    const newItem = filteredArrays.map((item: any) => {
      const { id, label, plano, ...res } = item;

      if (!isNumber(id)) {
        return {
          ...res,
          data: dayjs(item.data).format('YYYY-MM-DD') + 'T00:00:00'
        }
      } else {
        return {
          ...res,
          id,
          data: dayjs(item.data).format('YYYY-MM-DD') + 'T00:00:00',
        }
      }
    });

    try {
      const res = await EstrategyElaboration({
        data: { itens: newItem },
        id: Number(router.query.id),
      });

      const response = res.data as any[];
      const newArray = findNewItemsWithId(newItem, response);

      for (const item of newArray) {
        const relacionamento = await consultarRelacionamento();
        const root = JSON.parse(JSON.parse(relacionamento));

        const checklayer = checkLayerExists({ root, layerName: 'estrategias', targetId: item.eixoTematico });

        if (!checklayer) {
          const newInsertItem = updateItemById({
            root,
            targetId: item.eixoTematico,
            item: { estrategias: { itens: [] } },
            layerName: 'eixos_tematicos',
          });

          startRelacionamento(JSON.stringify(newInsertItem));
          await atualizarRelacionamento();
        }
      }

      for (const item of newArray) {
        const relacionamento = await consultarRelacionamento();
        const root = JSON.parse(JSON.parse(relacionamento));
        
        
        const newInsertItem = insertItemById({
          root,
          targetId: item.eixoTematico,
          item: { id: item.id, relacionamento: {} },
          layerName: 'estrategias',
        });

        startRelacionamento(JSON.stringify(newInsertItem));
        await atualizarRelacionamento();
      }

      toggleSuccessSnackbar()

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id === selectedItemsMenu?.id).id + 1;

        setDefaultItemMenuId(planId);
        handleFetchProgressCodePlanId(router.query.id);
      } else {
        setNavigable(false);
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id === selectedItemsMenu?.id).id;

        setDefaultItemMenuId(planId);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);
        handleFetchProgressCodePlanId(router.query.id);
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000)
      setDisableSubmit(false);
    } catch (error) {
      toggleErrorSnackbar()
      setDisableSubmit(false);
    }

    if (estrategiaCode != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const newItem = {
        "name": "Estratégia",
        "position": "",
        "manager": 'Eixo Temático',
        "tooltip": "",
        "subordinates": []
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, 'Eixo Temático', newItem);

      startOrganograma(newOrganograma);
      salvarOrganograma();

    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  };

  const Fields = React.useCallback(() => {
    if (is_optional == 'false' || is_optional == undefined) return null;
    if (Boolean(is_optional) == false || is_optional == undefined) return null;

    const inputs = fields?.reduce((acc: any, field: any, fieldIndex: any) => {
      field.map((it: any, itIndex: any) => {
        const data = watchStrategy(`estrategia.${fieldIndex}.${itIndex}.data`);
        const texto = watchStrategy(`estrategia.${fieldIndex}.${itIndex}.texto`);

        const randomId = Math.random()
        acc.push(
          <React.Fragment key={`${randomId}`}>
            <input
              type="hidden"
              {...registerStrategy(`estrategia.${fieldIndex}.${itIndex}.id`, { value: it?.id })}
              defaultValue={it?.id}
            />
            <Grid item xs={12}>
              {ReplaceButton(fieldIndex)}
            </Grid>
            <Grid item xs={4}>
              <TextFieldElaboration
                rows={3}
                required
                multiline
                commentTittle={texto}
                defaultValue={texto}
                errors={errosStrategy}
                register={registerStrategy}
                placeholder="Digite a estratégia"
                label={itIndex === 0 ? it?.label : undefined}
                name={`estrategia.${fieldIndex}.${itIndex}.texto`}
              />
            </Grid>
            <Grid item xs={4} sx={{ mt: itIndex === 0 ? 0.5 : -2.8 }}>
              <DatePickerElaboration
                required
                defaultValue={data}
                errors={errosStrategy}
                commentTittle={texto}
                label="Prazo da estratégia"
                control={controlStrategy}
                name={`estrategia.${fieldIndex}.${itIndex}.data`}
              />
            </Grid>
            <Grid item xs={4} sx={{ mt: -0.5 }}>
              {itIndex !== 0 && !preview && (
                <div onClick={() => {
                  setDeleteField(field)
                  setOpenDeleteModalId(it?.id)
                  setOpenDeleteModal(true);
                  setDeleteStrategyId(itIndex)
                  setFieldIndexItem(fieldIndex)
                }} style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                  <DeleteIcon />
                </div>
              )}
            </Grid>
            {itIndex === field.length - 1 && (
              <Grid item xs={12} sx={{ mt: -2 }}>
                <div className="add_line_wrapper">
                  <p onClick={() => handleAddLine(field, it, fieldIndex)}>
                    + Adicionar linha
                  </p>
                </div>
              </Grid>
            )}
            <DeleteElaborationDialog
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
              onDelete={async () => {
                updateStrategy(fieldIndexItem, fields[fieldIndexItem].filter((_: any, index: any) => index !== deleteStrategyId));
                setOpenDeleteModal(false);

                if (openDeleteModalId != 0) {
                  const relacionamento = await consultarRelacionamento();
                  const root = JSON.parse(JSON.parse(relacionamento));

                  const newItem = deleteItemById({ root, layerName: 'estrategia', targetId: openDeleteModalId });

                  startRelacionamento(JSON.stringify(newItem));
                  atualizarRelacionamento();

                  DeleteStrategyElaborations(openDeleteModalId)
                  setOpenDeleteModalId(0)
                }
              }}
            />
          </React.Fragment>
        );

        return acc;
      });

      return acc;
    }, []);

    return (
      <>

        {inputs}
      </>
    );
  }, [fields, openDeleteModal, is_optional, errosStrategy, handleDelete, handleAddLine]);

  const ReplaceButton = React.useCallback((index: any) => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (replaceButtonRef?.current?.reuseLen > 0) {
            setReutilizar(true);
            setReuseClickedIndex(index)
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, is_optional, reutilizar, openDisclaimer]);

  const ActionButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'true' ? 'true' : 'false'
      } else {
        return Boolean(is_optional) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'false' || is_optional == undefined;
      } else {
        return Boolean(is_optional) == false || is_optional == undefined;
      }
    }

    return (
      <Grid item xs={12}>
        <ActionButtonElaborations disable={disabeSubmit} layer_indicator={false} isOptional={opitional()} onClick={() => {
          if (optionalV2()) {
            setNavigable(true)

            router.push('/dashboard/elaboracao_planos/' + router.query.id);
            PostProgressCode({ code: 'estrategia', skipped: true }, router.query.id)
            const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
            if (plan && plan[selectedItemsMenu?.layer as string]) {
              const planId = plan[selectedItemsMenu?.layer as string]
                .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
              setDefaultItemMenuId(planId);
            }
          }
        }} />
      </Grid>
    )
  }, [is_optional, disabeSubmit])

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitStrategy(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioCheckedElaboration
              required={false}
              name="is_optional"
              disabled={disable}
              errors={errosStrategy}
              control={controlStrategy}
              defaultValue={is_optional == 'true' ? 'true' : 'false'}
              data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
              label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
            />
          </Grid>
          {Fields()}
          {is_optional == 'true' && (
            <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
              <span>{errosStrategy.root?.message}</span>
            </div>
          )}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {ActionButton()}
          <ReutilizarElaborationDialogV2
            title="Estrategia"
            reuseData={data}
            open={reutilizar}
            ref={replaceButtonRef}
            setOpen={() => setReutilizar(false)}
            setValue={(e) => {
              if (reuseClickedIndex != null) {
                e.map((item: any, index: any) => {
                  const { checked, ...res } = item
                  if (index == 0) {
                    setValueStrategy(`estrategia.${reuseClickedIndex}.${index}.texto` as any, res.texto)
                    setValueStrategy(`estrategia.${reuseClickedIndex}.${index}.data` as any, dayjs(res.data))
                    setValueStrategy(`estrategia.${reuseClickedIndex}.${index}.label` as any, fields[reuseClickedIndex][0]?.label)
                    return;
                  }

                  setValueStrategy(`estrategia.${reuseClickedIndex}.${index}.texto` as any, res.texto)
                  setValueStrategy(`estrategia.${reuseClickedIndex}.${index}.data` as any, dayjs(res.data))
                });
              }
            }}
          />
          <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
        </Grid>
      </Container>
    </form>
  )
}
