import React from "react";
import dayjs from "dayjs";
import * as Yup from 'yup';
import { TrashIcon } from "./trash";
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { Grid, Pagination } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { Organograma } from "@/utils/organograma/Organograma";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { SelectFieldElaboration } from "../../Inputs/SelectField";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { MultipleSelect } from "@/components/global/Inputs/MultipleInput";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { DashboardManagementPlansService } from "@/services/endpoints/dashboard";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { analisarTerritorioArray, encontrarCorrespondentes, handleContentRelatedToTerritorySelected, removeDuplicatesFromA } from "../utils";

type InputsMacroactions = {
  macroacao?: any;
  is_optional?: boolean;
}

const SchemaMacroactions = Yup.object().shape({
  macroacao: Yup.array().of(
    Yup.object().shape({
      setor: Yup.string().required('campo obrigatório'),
      orgao: Yup.string().required('campo obrigatório'),
      territorio: Yup.string(),
      responsavel: Yup.string(),
      publicoAlvo: Yup.string(),
      texto: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
    })
  ),
  is_optional: Yup.boolean(),
});

const defaultValue = {
  id: '',
  tor: [],
  setor: '',
  orgao: '',
  prazo: null,
  texto: '',
  territorio: '',
  responsavel: '',
  publicoAlvo: '',
}

const transformMacroactionsData = (data: any[]) => {
  return data.map(item => {
    const tor = item?.macroacaoTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.macroacaoTerritorio)

    return {
      tor,
      territorio,
      id: item?.id,
      texto: item.texto || '',
      orgao: item.orgao?.id || '',
      setor: item.setor?.id || '',
      publicoAlvo: item.publicoAlvo || '',
      responsavel: item.responsavel || '',
      prazo: item.data ? dayjs(item.data) : null,
    };
  });
};

const transformMacroactionsDataReuse = (item: any) => {
  const tor = item?.macroacaoTerritorio.map((m: any) => {
    if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
    if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
    if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
    if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
    return null;
  }).filter(Boolean);

  const territorio = analisarTerritorioArray(item.macroacaoTerritorio)

  return {
    tor,
    territorio,
    texto: item.texto || '',
    prazo: item.data ? dayjs(item.data) : null,
    orgao: item.orgao?.id || '',
    setor: item.setor?.id || '',
    publicoAlvo: item.publicoAlvo || '',
    responsavel: item.responsavel || '',
  };
};

export const MacroactionForm: React.FC = () => {
  const {
    trigger,
    clearErrors,
    watch: watchMacroactions,
    control: controlMacroactions,
    register: registerMacroactions,
    setValue: setValueMacroactions,
    handleSubmit: handleSubmitMacroactions,
    formState: { errors: errosMacroactions } } = useForm<InputsMacroactions>({
      resolver: yupResolver(SchemaMacroactions),
      defaultValues: { macroacao: [defaultValue] }
    });

  const { append: appendMacroactions, update: updateMacroactions, remove: removeMacroactions } = useFieldArray({
    control: controlMacroactions,
    name: "macroacao"
  });

  const fields = watchMacroactions('macroacao');
  const isOptional = watchMacroactions("is_optional") as unknown as string;

  const {
    PostProgressCode,
    MacroactionsElaboration,
    GetGoalsEixoElaborations,
    GetThematicAxisElaboration,
    GetMacroactionsElaborations,
    UpdateMacroactionsElaborations,
    DeleteMacroactionsElaborations,
  } = new ElaborationService();

  const router = useRouter();
  const routerId = router.query.id;

  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [metas, setMetas] = React.useState<any[]>([]);
  const [disable, setDisable] = React.useState(false);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [filterData, setFilterData] = React.useState({
    setor: [] as any[],
    orgaoResponsavel: [] as any[],
    territorio: {} as Record<string, any>
  });
  const [deleteId, setDeleteId] = React.useState(0);
  const [deleteIndex, setDeleteIndex] = React.useState(0);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const { getAllFilters } = new DashboardManagementPlansService();
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handleFetchProgressCodePlanId, setDefaultItemMenuId, setNavigable, completCode } = usePreparationOfPlans();
  const total = metas.length;
  
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  
  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.macroacao?.find((item: any) => item[title]);
    return foundObject ? foundObject[title].message : null;
  }

  const filterSectors = (data: any, orgao: any) => {
    const findItem = data?.orgaoResponsavel?.find((item: any) => item?.id == orgao);
    const filterItems =
      data?.setor?.filter((item: any) => item.codUa == findItem?.codUa)
        .map((item: any) => ({ value: item?.id, name: item?.descricao }))

    return filterItems
  }

  const handleChange = async (_: any, value: any) => {
    const isValid = await trigger();
    if (typeof _ == 'string') {
      setPage(value);
      return;
    }
    if (isValid) {
      handleSubmitMacroactions(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setPage(value);
  };

  const handleLeftClick = () => {
    handleChange('', 1)
    setCurrentIndexEixo(currentIndexEixo === 0 ? eixos_tematicos.length - 1 : currentIndexEixo - 1);
    handleExternalSubmit()
  };

  const handleRightClick = () => {
    handleChange('', 1)
    setCurrentIndexEixo(currentIndexEixo === eixos_tematicos.length - 1 ? 0 : currentIndexEixo + 1);
    handleExternalSubmit();
  };

  const handleExternalSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmitMacroactions(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
  };

  React.useEffect(() => {
    if (router.query.id && router.pathname.split("/").includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos")) {
      GetThematicAxisElaboration(routerId).then(res => {
        setEixosTematicos(res.data);
      });
    }
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    setLoading(true);
    if (router.query.id && (router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) ||
      (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
      GetGoalsEixoElaborations(routerId, eixos_tematicos[currentIndexEixo].id).then((res: any) => {
        setLabel('Meta')
        setMetas(res.data);
      }).finally(() => setLoading(false));
    }
  }, [currentIndexEixo, router.query.id, eixos_tematicos, router.pathname, completCode]);

  React.useEffect(() => {
    const meta = metas[page - 1];
    clearErrors()
    if (meta?.macroacoesMetas?.length > 0) {
      setValueMacroactions('macroacao', transformMacroactionsData(meta.macroacoesMetas))
    } else {
      setValueMacroactions('macroacao', [defaultValue])
    }
  }, [metas, page, completCode]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);

  React.useEffect(() => {
    if (planId && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos') && tipoFluxo == 'substituir') {
      GetMacroactionsElaborations(planId).then((res: any) => {
        setData(res.data);
      });
    }
  }, [planId, router.pathname, tipoFluxo]);

  React.useEffect(() => {
    if (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      getAllFilters().then((res: any) => {
        const { data } = res.data as any;
        setFilterData(data);
      })
    }
  }, [router.pathname]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'macroacoes');

    if (code != undefined) {
      setDisable(true);
    } else {
      setDisable(false)
    }
  }, [completCode]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'macroacoes' && item.skipped == false);

    if (code != undefined) {
      setValueMacroactions('is_optional', true)
    }
  }, [completCode]);

  React.useEffect(() => {
    if (zustandSelectedCardItem.title != 'Plano de Gestão' && zustandSelectedCardItem.title != 'Plano Orcamentário') {
      setValueMacroactions('is_optional', true)
    }
  }, [zustandSelectedCardItem])

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const { insertItemById, updateItemById, checkLayerExists, deleteItemById } = useHierarchyPreparationOfPlans();
  const { startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { macroacao } = data;
    const metaId = metas[page - 1]?.id;
    const macroacaoCode = completCode.find(item => item.code == 'macroacoes')

    if (disabeSubmit) return;

    setDisableSubmit(true);

    let submittedData = macroacao;

    if (event?.data?.length > 0) {
      submittedData = event.data;
    }

    try {
      for (const [index, itemMacro] of submittedData.entries()) {
        const { indicadores, tor, id, orgao, setor, ...rest } = itemMacro;


        let macroacaoData = {
          ...rest,
          metaId,
          eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
          prazo: dayjs(itemMacro.prazo).format('YYYY-MM-DD') + 'T00:00:00',
          territorio: encontrarCorrespondentes(itemMacro.tor, filterData.territorio),
        };

        if (orgao.length > 0) {
          macroacaoData = {
            ...macroacaoData,
            orgao: Number(orgao)
          }
        }

        if (setor.length > 0) {
          macroacaoData = {
            ...macroacaoData,
            setor: Number(setor)
          }
        }

        if (id) {
          if (tipoFluxo === 'substituir') {
            await UpdateMacroactionsElaborations(macroacaoData, id);
          } else {
            await UpdateMacroactionsElaborations(macroacaoData, id);
          }
        } else {
          const response = await MacroactionsElaboration(macroacaoData, router.query.id);

          const relacionamentoCheck = await consultarRelacionamento();
          const rootCheck = JSON.parse(JSON.parse(relacionamentoCheck));
          const checklayer = checkLayerExists({ root: rootCheck, layerName: 'metas', targetId: metaId });

          if (!checklayer) {
            for (const item of [macroacaoData]) {
              const relacionamento = await consultarRelacionamento();
              const root = JSON.parse(JSON.parse(relacionamento));

              const newInsertItem = updateItemById({
                root,
                targetId: metaId,
                item: { macroacoes: { itens: [] } },
                layerName: 'metas',
              });

              startRelacionamento(JSON.stringify(newInsertItem));
              await atualizarRelacionamento(); // Aguarda o término da atualização
            }
          }

          for (const item of [macroacaoData]) {
            const relacionamento = await consultarRelacionamento();
            const root = JSON.parse(JSON.parse(relacionamento));

            const newInsertItem = insertItemById({
              root,
              targetId: metaId,
              item: { id: response?.data?.data, relacionamento: {} },
              layerName: 'macroacoes',
            });

            startRelacionamento(JSON.stringify(newInsertItem));
            await atualizarRelacionamento(); // Aguarda o término da atualização
          }

        }

        await delay(500);
      }

      toggleSuccessSnackbar('Macroação')

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true)

        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
      } else {
        setNavigable(false)
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id)
        handleFetchProgressCodePlanId(router.query.id)
      }
      setTimeout(() => toggleSuccessSnackbar(), 3000);
      setDisableSubmit(false);
    } catch (error: any) {
      if (error.message == 'event is undefined') return;
      toggleErrorSnackbar('Macroação')
      setDisableSubmit(false);
    }

    if (macroacaoCode != undefined) return null;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const newItem = {
        "tooltip": "",
        "position": "",
        "name": "Macroação",
        "subordinates": [],
        "manager": 'Meta',
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, 'Meta', newItem);
      startOrganograma(newOrganograma);
      salvarOrganograma();

    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  };

  const ThematicAxisBanner = React.useCallback(() => {
    if (isOptional == 'false' || isOptional == undefined) return null;
    if (Boolean(isOptional) == false || isOptional == undefined) return null;

    return (
      <>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
        <Grid item xs={12} className="goals_select_content_wrapper">
          <p>Eixo temático</p>
        </Grid>
        <Grid item xs={12}>
          <div className="goals_select_wrapper">
            <div className="goals_left_arrow" onClick={() => handleLeftClick()}>{"<"}</div>
            <div className="goals_select_content_wrapper">
              <p>{eixos_tematicos[currentIndexEixo]?.texto}</p>
              <span>Eixo temático {currentIndexEixo + 1} de {eixos_tematicos.length}</span>
            </div>
            <div className="goals_rigth_arrow" onClick={() => handleRightClick()}>{">"}</div>
          </div>
        </Grid>
        {metas.length == 0 ? null : (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <div className="pagination_wrapper">
              <Pagination
                page={page}
                count={total}
                onChange={handleChange}
                hidePrevButton hideNextButton
                variant="outlined" shape="rounded"
              />
              <p>Página {total == 0 ? 0 : page} de {total}</p>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          {metas.length == 0 ? null : (
            <div className="strategy_wrapper">
              <p>
                <b>{label}:</b>&nbsp;
                {metas[page - 1]?.texto}
              </p>
            </div>
          )}
        </Grid>
      </>
    );
  }, [metas, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, label, currentIndexEixo, isOptional]);

  const ReplaceButton = React.useCallback(() => {
    if ((isOptional == 'false' || isOptional == undefined) && (zustandSelectedCardItem.title != 'Plano de Estado' && zustandSelectedCardItem.title != 'Plano Setorial' && zustandSelectedCardItem.title != 'Plano Institucional')) return null;
    if (tipoFluxo != 'substituir') return null;

    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, metas, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, label, reutilizar, openDisclaimer]);

  const Fields = React.useCallback(() => {
    if (Boolean(isOptional) == false || isOptional == undefined) return null;
    if (isOptional == 'false' || isOptional == undefined) return null;
    if (metas.length == 0) return null;

    return (
      <>
        <Grid item xs={12} sx={{ ml: 2 }}>
          {fields.map((field: any, goalIndex: any) => {
            const randomId = Math.random();
            const tor = watchMacroactions(`macroacao.${goalIndex}.tor`);
            const orgao = watchMacroactions(`macroacao.${goalIndex}.orgao`);
            const setor = watchMacroactions(`macroacao.${goalIndex}.setor`);
            const texto = watchMacroactions(`macroacao.${goalIndex}.texto`);
            const prazo = watchMacroactions(`macroacao.${goalIndex}.prazo`);
            const territorio = watchMacroactions(`macroacao.${goalIndex}.territorio`);
            const responsavel = watchMacroactions(`macroacao.${goalIndex}.responsavel`);

            return (
              <Grid key={randomId} container spacing={2} sx={{ background: '#DFDFDF', borderRadius: '5px', mt: 2, padding: '0 1rem  0 0' }}>
                <Grid item xs={12}>
                  <input
                    type="hidden"
                    {...registerMacroactions(`macroacao.${goalIndex}.id`, { value: field?.id })}
                    defaultValue={field?.id}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePickerElaboration
                    width="90%"
                    label="Prazo"
                    commentTittle={texto}
                    required={true}
                    defaultValue={prazo}
                    errors={errosMacroactions}
                    control={controlMacroactions}
                    name={`macroacao.${goalIndex}.prazo`}
                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosMacroactions, 'prazo')}</span>
                  </div>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <TextFieldElaboration
                    rows={3}
                    required={true}
                    multiline={true}
                    label="Macroação"
                    defaultValue={texto}
                    errors={errosMacroactions}
                    commentTittle={texto}
                    register={registerMacroactions}
                    placeholder="Digite a macroação"
                    name={`macroacao.${goalIndex}.texto`}
                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosMacroactions, 'texto')}</span>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <SelectFieldElaboration
                    required={false}
                    label="Território"
                    commentTittle={texto}
                    defaultValue={territorio}
                    errors={errosMacroactions}
                    register={registerMacroactions}
                    placeholder="Selecione o território"
                    data={[
                      { value: 0, name: '-' },
                      { value: 1, name: 'AP' },
                      { value: 2, name: 'RP' },
                      { value: 3, name: 'RA' },
                      { value: 4, name: 'Bairros' }
                    ]}
                    name={`macroacao.${goalIndex}.territorio`}
                    onClick={() => updateMacroactions(goalIndex, { ...field, tor: [] })}
                    setValue={(value) => setValueMacroactions(`macroacao.${goalIndex}.territorio`, value)}

                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosMacroactions, 'territorio')}</span>
                  </div>
                </Grid>
                {territorio != 0 && (
                  <Grid item xs={12}>
                    <MultipleSelect
                      fullWidth={true}
                      value={Array.isArray(tor) ? tor : []}
                      setValue={(e) => {
                        const items = removeDuplicatesFromA(tor, e)
                        updateMacroactions(goalIndex, { ...field, tor: items })
                      }}
                      {...registerMacroactions(`macroacao.${goalIndex}.tor`, { required: false })}
                      data={handleContentRelatedToTerritorySelected(filterData, territorio, tor)}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <TextFieldElaboration
                    required={false}
                    label="Público-alvo"
                    commentTittle={texto}
                    errors={errosMacroactions}
                    register={registerMacroactions}
                    placeholder="Digite o público-alvo"
                    name={`macroacao.${goalIndex}.publicoAlvo`}
                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosMacroactions, 'publicoAlvo')}</span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <SelectFieldElaboration
                    label="Órgão"
                    required={true}
                    defaultValue={orgao}
                    commentTittle={texto}
                    placeholder="Selecione"
                    errors={errosMacroactions}
                    register={registerMacroactions}
                    name={`macroacao.${goalIndex}.orgao`}
                    setValue={(value) => setValueMacroactions(`macroacao.${goalIndex}.orgao`, value)}
                    data={filterData.orgaoResponsavel.map((item) => ({ value: item.id, name: item.sigla }))}
                  />
                  <div className="error_wrapper" style={{ marginTop: '-.2rem' }}>
                    <span>{getMessageErrorByNameRef(errosMacroactions, 'orgao')}</span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <SelectFieldElaboration
                    label="Setor"
                    required={true}
                    defaultValue={setor}
                    commentTittle={texto}
                    placeholder="Selecione"
                    errors={errosMacroactions}
                    register={registerMacroactions}
                    name={`macroacao.${goalIndex}.setor`}
                    data={filterSectors(filterData, orgao)}
                    setValue={(value) => setValueMacroactions(`macroacao.${goalIndex}.setor`, value)}
                  />
                  <div className="error_wrapper" style={{ marginTop: '-.2rem' }}>
                    <span>{getMessageErrorByNameRef(errosMacroactions, 'setor')}</span>
                  </div>
                </Grid>
                <Grid item xs={3} sx={{ mt: -.3 }}>
                  <TextFieldElaboration
                    required={false}
                    errors={errosMacroactions}
                    commentTittle={texto}
                    label="Responsável"
                    register={registerMacroactions}
                    defaultValue={responsavel}
                    placeholder="Nome do responsável"
                    name={`macroacao.${goalIndex}.responsavel`}
                  />
                  <div className="error_wrapper">
                    <span>{getMessageErrorByNameRef(errosMacroactions, 'responsavel')}</span>
                  </div>
                </Grid>
                {goalIndex != 0 && !preview && (
                  <Grid item xs={12} display='flex' sx={{ mb: 2, justifyContent: 'end', alignItems: 'center' }}>
                    <TrashIcon onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteIndex(goalIndex);
                      setDeleteId(field.id)
                    }} />
                  </Grid>
                )}
                <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={async () => {
                  removeMacroactions(deleteIndex);
                  if (deleteId) {
                    const relacionamento = await consultarRelacionamento();
                    const root = JSON.parse(JSON.parse(relacionamento));
                    
                    const newItem = deleteItemById({ root, layerName: 'macroacoes', targetId: deleteId });
  
                    startRelacionamento(JSON.stringify(newItem));
                    atualizarRelacionamento();

                    DeleteMacroactionsElaborations(deleteId)
                    setDeleteId(0)
                  }
                  setOpenDeleteModal(false);
                }} />
              </Grid>
            )
          })}
        </Grid>
        {fields.length > 0 && (
          <Grid item xs={12}>
            <div className="add_line_wrapper">
              <p onClick={() => appendMacroactions(defaultValue)}>
                {`+ Adicionar nova macroação`}
              </p>
            </div>
          </Grid>
        )}
      </>
    )
  }, [
    fields,
    isOptional,
    filterData,
    openDeleteModal,
    errosMacroactions,
    appendMacroactions,
    updateMacroactions,
    removeMacroactions,
    controlMacroactions,
    registerMacroactions,
    zustandSelectedCardItem,
    handleContentRelatedToTerritorySelected,
  ]);

  const actionButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'true' ? 'true' : 'false'
      } else {
        return Boolean(isOptional) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'false' || isOptional == undefined;
      } else {
        return Boolean(isOptional) == false || isOptional == undefined;
      }
    }
    return (
      <ActionButtonElaborations disable={disabeSubmit} isOptional={opitional()} layer_indicator={false} onClick={() => {
        if (optionalV2()) {
          setNavigable(true)
          PostProgressCode({ code: 'macroacoes', skipped: true }, router.query.id)
          router.push('/dashboard/elaboracao_planos/' + router.query.id);

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          if (plan && plan[selectedItemsMenu?.layer as string]) {
            const planId = plan[selectedItemsMenu?.layer as string]
              .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
            setDefaultItemMenuId(planId);
          }
        }
      }} />
    )
  }, [isOptional, disabeSubmit])

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form id='formulario_macroacao' onSubmit={handleSubmitMacroactions(onSubmit)}>
      <Container>
        <Grid container spacing={1}>
          {(zustandSelectedCardItem.title != 'Plano de Estado' && zustandSelectedCardItem.title != 'Plano Setorial' && zustandSelectedCardItem.title != 'Plano Institucional') && (
            <Grid item xs={12}>
              <RadioCheckedElaboration
                required={false}
                name="is_optional"
                errors={errosMacroactions}
                control={controlMacroactions}
                defaultValue={isOptional == 'true' ? 'true' : 'false'}
                data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                disabled={disable}
                label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
              />
            </Grid>
          )}
          {ReplaceButton()}
          {ThematicAxisBanner()}
          <Grid item xs={12} sx={{ mt: 4 }}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {Fields()}
          <Grid item xs={12}>
            {actionButton()}
          </Grid>
        </Grid>
        <ReutilizarElaborationDialog
          title="Macroações"
          reuseData={data}
          open={reutilizar}
          setOpen={() => setReutilizar(false)}
          setValue={(e) => {
            e.forEach((item: any, index: any) => {
              setValueMacroactions(`macroacao.${index}`, transformMacroactionsDataReuse(item))
            })
          }}
        />
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
      </Container>
    </form>
  )
}
