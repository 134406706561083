import React from "react"
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { isNumber } from "lodash";
import { Grid } from '@mui/material';
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { Organograma } from "@/utils/organograma/Organograma";
import { ActionButtonElaborations } from "../../ActionButton";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { ReutilizarElaborationDialog } from "./ReutilizarModal";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { DisclaimerReuseElaborationDialog } from "./disclaimerModal";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

type InputsVision = {
  visao: any;
  is_optional?: boolean;
};

const defaultValue = {
  id: '',
  texto: '',
  data: null,
}

const SchemaVision = Yup.object().shape({
  visao: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
      data: Yup.date().nullable().required('Campo obrigatório')
    })
  ).required('Campo obrigatório'),
});

export const VisionForm: React.FC = () => {
  const {
    watch: watchVision,
    control: controlVision,
    register: registerVision,
    setValue: setValueVision,
    handleSubmit: handleSubmitVision,
    formState: { errors: errosVision } } = useForm<InputsVision>({
      resolver: yupResolver(SchemaVision),
      defaultValues: { visao: [defaultValue] }
    });

  const { } = useFieldArray({
    control: controlVision,
    name: "visao"
  });

  const fields = watchVision('visao');
  const isOptional = watchVision('is_optional') as unknown as string;

  const router = useRouter();
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [reutilizar, setReutilizar] = React.useState(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { VisionElaboration, GetVisionElaboration, PostProgressCode } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, completCode, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId } = usePreparationOfPlans();

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.visao?.find((item: any) => item && item[title]);
    return foundObject ? foundObject[title].message : null;
  }

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos'))
      setLoading(true)
    GetVisionElaboration(router.query.id).then((res: any) => {
      if (res.data.length > 0) {
        const data = res.data.map((item: any) => {
          return {
            texto: item.texto,
            data: dayjs(item.data),
            id: item.id
          }
        })
        setValueVision('visao', data)
      }
    }).finally(() => setLoading(false))
  }, [router, isOptional, completCode]);

  React.useEffect(() => {
    if (planId && router.pathname.split('/').includes("elaboracao_planos") && tipoFluxo == 'substituir')
      GetVisionElaboration(planId).then((res: any) => {
        if (res.data.length > 0) {
          setData(res.data);
        }
      })
  }, [planId, router.pathname]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes("aprovacao_planos"))
      GetVisionElaboration(router.query.id).then((res: any) => {
        if (res.data.length > 0) {
          setData(res.data);
        }
      })
  }, [router.query, router.pathname]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'visao');

    if (code != undefined && code.skipped) {
      setValueVision('is_optional', false);
    }


    if (code != undefined && !code.skipped) {
      setValueVision('is_optional', true);
    }
  }, [completCode]);

  React.useEffect(() => {
    if (zustandSelectedCardItem.title != 'Plano Setorial') {
      setValueVision('is_optional', true)
    }
  }, [zustandSelectedCardItem])

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    if (disabeSubmit) return;
    const code = completCode.find(item => item.code == 'visao');

    setDisableSubmit(true);

    try {
      const { visao } = data;

      for (const item of visao) {
        const { id, ...res } = item;

        let json = {};

        if (isNumber(id)) {
          json = {
            ...res,
            id,
            data: dayjs(item.data).format('YYYY-MM-DD') + 'T00:00:00',
          };
        } else {
          json = {
            ...res,
            data: dayjs(item.data).format('YYYY-MM-DD') + 'T00:00:00',
          }
        }

        await VisionElaboration(json, router.query.id);

        toggleSuccessSnackbar();

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        let planId;

        if (event.nativeEvent.submitter.name === 'next_step') {
          setNavigable(true);

          planId = plan[selectedItemsMenu?.layer as string].find((item: any) => item.id === selectedItemsMenu?.id).id + 1;

          router.push('/dashboard/elaboracao_planos/' + router.query.id);
        } else {
          setNavigable(false);

          planId = plan[selectedItemsMenu?.layer as string].find((item: any) => item.id === selectedItemsMenu?.id).id;

          router.push('/dashboard/elaboracao_planos/' + router.query.id);
        }

        setDefaultItemMenuId(planId);
        handleFetchProgressCodePlanId(router.query.id);

        setTimeout(() => toggleSuccessSnackbar(), 3000);
        setDisableSubmit(false);
      }
    } catch (error) {
      toggleErrorSnackbar();
      setDisableSubmit(false);
    }

    if (code != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const cenario = completCode.find(item => item.code == 'sintese_cenario')

      let manager: any;

      if (cenario != undefined) {
        manager = 'Síntese Diagnóstico'
      } else {
        manager = 'Síntese Diagnóstico'
      }

      const newItem = {
        "name": "Visão",
        "position": "",
        "manager": manager,
        "tooltip": "",
        "subordinates": []
      }

      const newOrganograma = addItemToNodeOrganogram(organograma, manager ,newItem);

      startOrganograma(newOrganograma);
      salvarOrganograma()

    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  };

  const Fields = React.useCallback(() => {
    if (isOptional == 'false' || isOptional == undefined) return null;
    if (Boolean(isOptional) == false || isOptional == undefined) return null;

    return (
      <>
        {fields.map((field: any, index: any) => {
          const randomId = Math.random();
          const data = watchVision(`visao.${index}.data`);
          const texto = watchVision(`visao.${index}.texto`);


          return (
            <React.Fragment key={randomId}>
              <Grid item xs={12}>
                <input
                  type="hidden"
                  defaultValue={field.id}
                  {...registerVision(`visao.${index}.id`, { value: field.id })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldElaboration
                  rows={2}
                  label="Visão"
                  required={true}
                  multiline={true}
                  errors={errosVision}
                  defaultValue={texto}
                  commentTittle={texto}
                  register={registerVision}
                  name={`visao.${index}.texto`}
                  placeholder="Digite a visão do plano"
                />
                <div className="error_wrapper">
                  <span>{getMessageErrorByNameRef(errosVision, 'texto')}</span>
                </div>
              </Grid>
              <Grid item xs={5}>
                <DatePickerElaboration
                  required={true}
                  defaultValue={data}
                  errors={errosVision}
                  label="Prazo da visão"
                  control={controlVision}
                  commentTittle={texto}
                  name={`visao.${index}.data`}
                />
                <div className="error_wrapper">
                  <span>{getMessageErrorByNameRef(errosVision, 'data')}</span>
                </div>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <div style={{ height: '1px', background: '#B4B4B4' }}></div>
              </Grid>
            </React.Fragment>
          );
        })}

      </>
    );
  }, [isOptional, zustandSelectedCardItem, data, errosVision, fields]);

  const CheckBoxField = React.useCallback(() => {
    if (zustandSelectedCardItem.title == 'Plano de Estado' || zustandSelectedCardItem.title == 'Plano Institucional') return null;
    const opitional = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'true' ? 'true' : 'false';
      } else {
        return Boolean(isOptional) == true ? 'true' : 'false'
      }
    }
    return (
      <Grid item xs={12}>
        <RadioCheckedElaboration
          errors={errosVision}
          required={false}
          control={controlVision}
          name="is_optional"
          defaultValue={opitional()}
          data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
          disabled={completCode?.find(item => item.code == 'visao') ? true : false}
          label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
        />
      </Grid>
    );
  }, [zustandSelectedCardItem, completCode, isOptional]);

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, reutilizar, data, openDisclaimer]);

  const actionButton = React.useCallback(() => {
    const optional = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'true' ? 'true' : 'false';
      } else {
        return Boolean(isOptional) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'false' || isOptional == undefined;
      } else {
        return Boolean(isOptional) == false || isOptional == undefined;
      }
    }

    return (
      <ActionButtonElaborations disable={disabeSubmit} isOptional={optional()} layer_indicator={false} onClick={() => {
        if (optionalV2()) {
          setNavigable(true)

          router.push('/dashboard/elaboracao_planos/' + router.query.id);
          PostProgressCode({ code: 'visao', skipped: true }, router.query.id)
          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          if (plan && plan[selectedItemsMenu?.layer as string]) {
            const planId = plan[selectedItemsMenu?.layer as string]
              .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
            setDefaultItemMenuId(planId);
          }
        }
      }} />
    )
  }, [isOptional, disabeSubmit])

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitVision(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          {CheckBoxField()}
          {ReplaceButton()}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {Fields()}
          <Grid item xs={12}>
            {actionButton()}
          </Grid>
          <ReutilizarElaborationDialog
            title="Visão"
            reuseData={data}
            open={reutilizar}
            setOpen={() => setReutilizar(false)}
            setValue={(e) => {
              const data = e.map((item: any, index: any) => {
                setValueVision(`visao.${index}.texto`, item?.texto)
                setValueVision(`visao.${index}.data`, dayjs(item.data))
              });
            }}
          />
        </Grid>
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
      </Container>
    </form>
  )
}
