const groupProperties = (data: any, prefix: any) => {
  const groupedArray = [];
  const regex = new RegExp(`^${prefix}_(\\d+)$`); // Regex para identificar propriedades com sufixos numerados

  // Inclui o valor original da propriedade, se existir
  if (data[prefix]) {
    groupedArray.push(data[prefix]);
  }

  // Itera sobre as propriedades do objeto
  for (const key in data) {
    if (regex.test(key)) {
      groupedArray.push(data[key]);
      delete data[key]; // Remove a propriedade do objeto original
    }
  }

  // Retorna o array agrupado se não estiver vazio
  return groupedArray.length > 0 ? groupedArray : null;
}

export const ElaborationTransformData = (data: any, prefixes: any) => {
  const result = { ...data }; // Cria uma cópia do objeto original

  // Itera sobre os prefixos e agrupa as propriedades correspondentes
  prefixes.forEach((prefix: any) => {
    const groupedArray = groupProperties(result, prefix);
    if (groupedArray) result[prefix] = groupedArray;
  });

  return result;
}


export const transformToDiagnosisLayer = (data: any) => {
  const parsedObject = { itens: [] } as Record<string, any>;
  const keys = Object.keys(data);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = data[key];

    if (Array.isArray(value)) {
      for (let j = 0; j < value.length; j++) {
        parsedObject.itens.push({ texto: `${key}: ${value[j]}` });
      }
    } else {
      parsedObject.itens.push({ texto: `${value}` });
    }
  }

  return parsedObject;
}

export function getTransformToDiagnosisLayer(data: any) {
  const result = {
    forcas: [{ id: '', texto: '' }],
    ameacas: [{ id: '', texto: '' }],
    fraquezas: [{ id: '', texto: '' }],
    diagnostico: [{ id: '', texto: '' }],
    oportunidades: [{ id: '', texto: '' }],
  } as Record<string, any>;

  data.sintese.forEach((item: any) => {
    const { id, texto } = item;
    const delimiterIndex = texto.indexOf(":");
    if (delimiterIndex === -1) return; // Skip if no delimiter found

    const prefix = texto.slice(0, delimiterIndex).trim();
    const processedText = texto.slice(delimiterIndex + 1).trim();
    const entry = { id, texto: processedText };

    if (prefix === "Forças" || prefix === "forcas") {
      if (result.forcas === null) {
        result.forcas = [entry];
      } else if (Array.isArray(result.forcas)) {
        result.forcas = [entry];
      } else {
        result.forcas = [result.forcas];
      }
    } else if (prefix === "Oportunidades" || prefix === "oportunidades") {
      if (result.oportunidades === null) {
        result.oportunidades = [entry];
      } else if (Array.isArray(result.oportunidades)) {
        result.oportunidades = [entry];
      } else {
        result.oportunidades = [result.oportunidades];
      }
    } else if (prefix === "Diagnostico" || prefix === "diagnostico" || prefix === 'Diagnóstico') {
      if (result.diagnostico === null) {
        result.diagnostico = [entry];
      } else if (Array.isArray(result.diagnostico)) {
        result.diagnostico = [entry];
      } else {
        result.diagnostico = [result.diagnostico];
      }
    } else if (prefix === "Ameaças" || prefix === "ameacas") {
      if (result.ameacas === null) {
        result.ameacas = [entry];
      } else if (Array.isArray(result.ameacas)) {
        result.ameacas = [entry];
      } else {
        result.ameacas = [result.ameacas];
      }
    } else if (prefix === "Fraquezas" || prefix === "fraquezas") {
      if (result.fraquezas === null) {
        result.fraquezas = [entry];
      } else if (Array.isArray(result.fraquezas)) {
        result.fraquezas = [entry];
      } else {
        result.fraquezas = [result.fraquezas];
      }
    }
  });

  return result;
}

export function replaceSpecialCharacters(str: string): string {
  const replacedStr = str.replace(/ç/g, 'c');
  return replacedStr.toLowerCase();
}

export function transformDiagnosisLayer(json: any) {
  return json?.sintese?.reduce((acc: any, item: any) => {
    const [prefixo, texto] = item?.texto?.split(': ').map((str: any) => str?.trim());

    // Normaliza o prefixo removendo caracteres especiais
    let parsedPrefixo = replaceSpecialCharacters(prefixo);

    // Se o prefixo for "Diagnóstico", alterá-lo para "Diagnostico"
    if (parsedPrefixo === "diagnóstico") {
      parsedPrefixo = "diagnostico";
    }

    // Se o prefixo não existir ainda no acumulador, inicialize um array para ele
    if (!acc[parsedPrefixo]) {
      acc[parsedPrefixo] = [];
    }
    
    // Adiciona o item ao grupo correspondente ao prefixo
    acc[parsedPrefixo].push({ id: item.id, texto });
    
    return acc;
  }, {});
}

export function getTransformToScenarioLayer(data: any) {
  const result = {
    descricao: null
  } as Record<string, any>;

  data.forEach((item: any) => {
    const { id, texto } = item;
    const delimiterIndex = texto.indexOf(":");
    if (delimiterIndex === -1) return; // Skip if no delimiter found

    const prefix = texto.slice(0, delimiterIndex).trim();
    const processedText = texto.slice(delimiterIndex + 1).trim();
    const entry = { id, texto: processedText };

    switch (prefix) {
      case "descricao":
        if (result.descricao === null) {
          result.descricao = [entry];
        }
        break;
    }
  });

  return result;
}

export const compareObjectDiagnosisLayer = (objetoA: any, objetoB: any) => {
  // Criar um mapa para mapear textos únicos em objetoA
  let textosUnicos = {} as Record<string, any>;

  // Mapear textos únicos em objetoA
  objetoA.forEach((item: any) => {
    textosUnicos[item.texto] = item;
  });

  // Verificar se há um novo texto com prefixo "diagnóstico" em B.itens
  let novoDiagnosticoEmB = objetoB.itens.find((itemB: any) => itemB.texto.startsWith("diagnostico: "));
  if (novoDiagnosticoEmB) {
    // Substituir o texto correspondente em A, se existir
    let diagnosticoEmA = objetoA.find((itemA: any) => itemA.texto.startsWith("diagnostico: "));
    if (diagnosticoEmA) {
      diagnosticoEmA.texto = novoDiagnosticoEmB.texto;
    } else {
      // Caso não exista, adicionar o novo texto em A
      objetoA.push({ texto: novoDiagnosticoEmB.texto, });
    }
  }

  // Adicionar novos itens de B.itens em A, exceto o novo diagnóstico já tratado acima
  objetoB.itens.forEach((itemB: any) => {
    if (!itemB.texto.startsWith("diagnostico: ") && !textosUnicos[itemB.texto]) {
      // Adicionar item de B em A
      objetoA.push({ texto: itemB.texto });
      textosUnicos[itemB.texto] = true; // Marcar como adicionado
    }
  });

  return objetoA; // Retornar objetoA atualizado
}

export const removeEmptyTextDiagnosisLayer = (objeto: any) => {
  const prefixos = ["oportunidades:", "ameacas:", "fraquezas:", "forcas:"];
  return objeto.itens = objeto.itens.filter((item: any) => {
      for (let prefixo of prefixos) {
          if (item.texto.startsWith(prefixo)) {
              // Verifica se há conteúdo após o prefixo
              const conteudo = item.texto.slice(prefixo.length).trim();
              if (conteudo !== '') {
                  return true; // Mantém o objeto se houver conteúdo após o prefixo
              } else {
                  return false; // Remove o objeto se o conteúdo estiver vazio
              }
          }
      }
      return true; // Mantém o objeto se não for encontrado nenhum prefixo correspondente
  });
}

export function clearIdToArray(arr: any[]) {
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    if ((obj.hasOwnProperty('id') && typeof obj.id === 'string') || (obj.id == undefined || obj.id.length == 0)) {
      delete obj.id;
    }
  }
  return arr;
}

export function transformObjectSintese(input: any) {
  // Verifica se o input é um array e tem pelo menos um objeto
  if (Array.isArray(input) && input.length > 0) {
      const obj = input[0];
      const result = { itens: [] } as any;

      // Itera sobre as propriedades do objeto
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              // Verifica se o valor da propriedade é um array
              if (Array.isArray(obj[key])) {
                  // Adiciona cada elemento do array ao resultado
                  obj[key].forEach((value: any) => {
                      result.itens.push({ texto: `${key}: ${value}` });
                  });
              } else {
                  // Adiciona a propriedade ao resultado
                  result.itens.push({ texto: `${key}: ${obj[key]}` });
              }
          }
      }

      return result;
  }

  // Retorna um objeto vazio se o input não for um array válido
  return { itens: [] };
}