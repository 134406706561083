import React from 'react';
import 'dayjs/locale/pt-br';
import { InfoIncon } from '../info';
import { Container } from './styled';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ptBR } from '@mui/x-date-pickers/locales';
import { Controller, Control } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConsideracaoIcon } from '../consideracao';
import { ConsiderationModal } from '../../Forms/ConsiderationModal';
import { useRouter } from 'next/router';

interface IProps {
  errors: any;
  mt?: number;
  name: string;
  label: string;
  views?: any[];
  width?: string;
  tooltip?: string;
  defaultValue?: any;
  required?: boolean;
  commentTittle?: string;
  control: Control<any, any>
}

const brazilianLocale = ptBR.components.MuiLocalizationProvider.defaultProps.localeText;

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: '#fff',
    height: '38px', // Permitir altura personalizada
  },
  '& .MuiInputBase-input': {
    height: '100%', // Ajustar a altura do input
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#DEE2E6 !important'
  },
}));

const dayOfWeekFormatter = (day: string) => {
  switch (day) {
    case 'Do':
      return 'D';
    case '2ª':
      return 'S';
    case '3ª':
      return 'T';
    case '4ª':
      return 'Q';
    case '5ª':
      return 'Q';
    case '6ª':
      return 'S';
    case 'Sá':
      return 'S';
    default:
      return day;
  }
};

export const DatePickerElaboration: React.FC<IProps> = ({
  mt,
  name,
  label,
  views,
  width,
  errors,
  tooltip,
  control,
  required,
  defaultValue,
  commentTittle,
}) => {
  return (
    <Container>
      <div className="title_wrapper_datepicker">
        <ToolTipWrapper
          name={name}
          label={label}
          tooltip={tooltip}
          required={required}
          commentTittle={commentTittle}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br" localeText={brazilianLocale}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <CustomDatePicker
                value={defaultValue}
                views={views}
                minDate={dayjs()}
                sx={{ mt, width, height: '14px' }}
                dayOfWeekFormatter={dayOfWeekFormatter}
                onChange={(date) => field.onChange(date)}
              />
            )
          }}
        />
      </LocalizationProvider>
      {errors && (
        <div className="error_wrapper" style={{ marginTop: '28px;' }}>
          <span>{errors[name]?.message}</span>
        </div>
      )}
    </Container>
  );
}

type TooltipWrapperProps = {
  name?: string,
  label?: string,
  register?: any,
  value?: string,
  tooltip?: string,
  required?: boolean,
  commentTittle?: string;
}

import { tooltipClasses } from '@mui/material/Tooltip';
import dayjs from 'dayjs';

const LightTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.withe,
    backgroundColor: theme.palette.common.white,
  },
}));

const ToolTipWrapper = ({
  name,
  value,
  label,
  tooltip,
  required,
  register,
  commentTittle
}: TooltipWrapperProps) => {

  const router = useRouter();
  const [epl, setEpl] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!router.isReady) return
    if (!router.query.id) return

    setEpl(router.asPath.includes('epl') && router.asPath.includes('dashboard/aprovacao_planos'));
  }, [router])

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <label style={{ display: 'flex', alignItems: 'center' }} className="interFont">
        <p>{label}</p>
        <span>
          {required ? '*' : ''}
        </span>
      </label>
      {!epl && (
        <>
          {tooltip && (
            <LightTooltip title={tooltip}>
              <div>
                <InfoIncon />
              </div>
            </LightTooltip>
          )}
        </>
      )}
      {epl && (
        <CondiserationWrapper
          name={name}
          label={label}
          value={value}
          register={register}
          commentTittle={commentTittle}
        />
      )}
    </div>
  )

}

const CondiserationWrapper = ({
  name,
  label,
  value,
  register,
  commentTittle,
}: {
  name?: string,
  register?: any,
  label?: string,
  value?: string,
  commentTittle?: string;
}) => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a className="consideration-modal" onClick={() => { setOpen(true) }}>
        <ConsideracaoIcon />
      </a>
      <ConsiderationModal
        open={open}
        name={name}
        label={label}
        value={value}
        register={register}
        handleClose={handleClose}
        commentTittle={commentTittle}
      />
    </>
  )
}