export const Svg_009 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={59}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M18.99 35.723a12.193 12.193 0 0 1 1.875-14.85c4.759-4.759 12.502-4.76 17.26 0a12.236 12.236 0 0 1 2.035 2.686l.656 1.176 16.763-4.491-.732-1.812a29.314 29.314 0 0 0-6.492-9.79c-5.572-5.57-12.98-8.64-20.86-8.64-7.88 0-15.288 3.07-20.86 8.641C-1.871 19.151-2.918 35.902 6.202 47.607l1.202 1.544 12.273-12.273-.685-1.155Z"
      />
      <path
        fill="url(#c)"
        d="m58.728 25.53-.263-1.946L41.69 28.08l.01 1.336a12.179 12.179 0 0 1-.814 4.48l-.486 1.261 14.213 9.952.918-1.72c2.929-5.487 4.034-11.662 3.197-17.857Z"
      />
      <path
        fill="url(#d)"
        d="M37.37 38.828a12.178 12.178 0 0 1-7.878 2.884c-2.14 0-4.29-.562-6.217-1.705l-1.155-.685L9.847 51.594l1.544 1.203a29.343 29.343 0 0 0 18.078 6.2c7.598 0 15.167-2.916 20.886-8.635.317-.317.632-.646.935-.978l1.327-1.453-14.23-9.964-1.017.86Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={13.315}
        x2={34.857}
        y1={7.375}
        y2={48.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={44.702}
        x2={55.519}
        y1={26.813}
        y2={41.942}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={19.738}
        x2={25.952}
        y1={41.121}
        y2={61.573}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h59v59H0z" />
      </clipPath>
    </defs>
  </svg>
)
