import React from "react"
import * as Yup from 'yup';
import { Grid } from '@mui/material'
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { Organograma } from "@/utils/organograma/Organograma";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";

type InputsThematicAxis = {
  eixo_tematico: any;
};

const defaultValue = {
  id: '',
  texto: '',
}

const SchemaThematicAxis = Yup.object().shape({
  eixo_tematico: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
    }))
}).required('O eixo temático é obrigatória');

export const ThematicAxisForm: React.FC = () => {
  const {
    watch: watchThematicAxis,
    control: controlThematicAxis,
    register: registerThematicAxis,
    setValue: setValueThematicAxis,
    handleSubmit: handleSubmitThematicAxis,
    formState: { errors: errosThematicAxis } } = useForm<InputsThematicAxis>({
      resolver: yupResolver(SchemaThematicAxis as any) as any,
      defaultValues: { eixo_tematico: [defaultValue] }
    });

  const { append: appendThematicAxis, remove: removeThematicAxis } = useFieldArray({
    control: controlThematicAxis,
    name: "eixo_tematico"
  });
  const fields = watchThematicAxis("eixo_tematico");

  const router = useRouter();
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [reutilizar, setReutilizar] = React.useState(false);
  const [deleteEixoId, setDeleteEixoId] = React.useState(null)
  const [deleteId, setDeleteId] = React.useState(0)
  const [preview, setPreview] = React.useState<boolean>(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { ThematicAxisElaboration, GetThematicAxisElaboration, DeleteThematicAxisElaborations } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId, completCode, selectedCardItem } = usePreparationOfPlans();

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.eixo_tematico?.find((item: any) => item && item[title]);
    return foundObject ? foundObject[title].message : null;
  };

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos"))
      setLoading(true);
    GetThematicAxisElaboration(router.query.id).then((res: any) => {
      if (res.data.length > 0) {
        setValueThematicAxis('eixo_tematico', res.data)
      } else {
        setValueThematicAxis("eixo_tematico", [defaultValue])
      }
    }).finally(() => setLoading(false));
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    if (planId && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos'))
      GetThematicAxisElaboration(planId).then((res: any) => {
        setData(res.data);
      })
  }, [planId]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);

  React.useEffect(() => {
    const eixo_tematico_code = completCode.find(item => item.code == 'eixos_tematicos')

    if (eixo_tematico_code == undefined) {
      startRelacionamento(JSON.stringify(createInitialData()));
      salvarRelacionamento();
    }
  }, [completCode])

  const { createInitialData, insertItemById, findNewItemsWithId, ClearInitialData } = useHierarchyPreparationOfPlans();
  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);
  const { salvarRelacionamento, startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);

  const onSubmit = async (data: any, event: any) => {
    const { eixo_tematico } = data;
    if (disabeSubmit) return;
    const eixo_tematico_code = completCode.find(item => item.code == "eixos_tematicos")

    setDisableSubmit(true);

    const formattedArray = eixo_tematico.map((item: any) => {
      if (item.id) {
        return {
          ...item,
        }
      } else {
        const { id, ...res } = item;

        return {
          ...res
        }
      }
    })

    try {
      const res = await ThematicAxisElaboration({
        data: { itens: formattedArray },
        id: Number(router.query.id)
      });

      const response = res.data as any[];


      const relacionamento = await consultarRelacionamento()
      const newArray = findNewItemsWithId(eixo_tematico, response);
      if (relacionamento.length == 0) {

      } else {
        const root = JSON.parse(JSON.parse(relacionamento));

        await Promise.all(newArray.map(async (item: any) => {
          const newItem = insertItemById({ root, targetId: item.id, item: { id: item.id, relacionamento: {} }, layerName: 'eixos_tematicos' });
          startRelacionamento(JSON.stringify(newItem));
          atualizarRelacionamento();
        }));
      }


      toggleSuccessSnackbar();

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id === selectedItemsMenu?.id).id + 1;

        setDefaultItemMenuId(planId);
        handleFetchProgressCodePlanId(router.query.id);
      } else {
        setNavigable(false);
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id === selectedItemsMenu?.id).id;

        setDefaultItemMenuId(planId);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);
        handleFetchProgressCodePlanId(router.query.id);
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000);
    } catch (error: any) {
      if (error.message) {
        toggleErrorSnackbar('Eixo Temático', error.message);

      } else {
        toggleErrorSnackbar();
        setDisableSubmit(false);
      }
      console.log("error", { error })
    };

    setDisableSubmit(false);

    if (eixo_tematico_code != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const visao = completCode.find(item => item.code == 'visao');
      const cenario = completCode.find(item => item.code == 'sintese_cenario');
      const diagnostico = completCode.find(item => item.code == 'sintese_diagnostico');

      let manager: any;

      if (zustandSelectedCardItem.title == 'Plano de Estado') {
        manager = 'Visão';
      };

      if (zustandSelectedCardItem.title == 'Plano Setorial') {
        if (diagnostico != undefined && cenario != undefined && visao != undefined) {
          manager = 'Visão'
        }

        if (diagnostico != undefined && cenario != undefined && visao == undefined) {
          manager == 'Síntese Diagnóstico'
        }

        if (diagnostico != undefined && cenario == undefined && visao == undefined) {
          manager = 'Síntese Diagnóstico'
        }
      };

      if (zustandSelectedCardItem.title == 'Plano Institucional') {
        manager = 'Valor'
      };

      if (zustandSelectedCardItem.title == 'Plano de Gestão') {
        manager = 'Síntese Diagnóstico'
      };

      if (zustandSelectedCardItem.title == 'Plano Orçamentário') {
        if (diagnostico != undefined) {
          manager = 'Síntese Diagnóstico'
        } else {
          startOrganograma([
            {
              "name": "Eixo Temático",
              "position": "",
              "manager": "Organograma",
              "tooltip": "",
              "subordinates": []
            }
          ]);

          salvarOrganograma();
          return;
        }
      }

      const newItem = {
        "name": "Eixo Temático",
        "position": "",
        "manager": manager,
        "tooltip": "",
        "subordinates": []
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, manager, newItem);

      startOrganograma(newOrganograma);
      salvarOrganograma();
    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  };

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, reutilizar, data, openDisclaimer]);

  const Fields = React.useCallback(() => {
    return (
      <Grid item xs={12} sx={{ mt: -2 }}>
        {fields.map((field: any, fieldIndex: any) => {
          const randomId = Math.random();
          const texto = watchThematicAxis(`eixo_tematico.${fieldIndex}.texto`);
          return (
            <React.Fragment key={randomId}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: '.5rem' }}>
                <input
                  type="hidden"
                  {...registerThematicAxis(`eixo_tematico.${fieldIndex}.id`, { value: field.id })}
                  defaultValue={field.id}
                />
                <TextFieldElaboration
                  key={field.id}
                  required={true}
                  label="Eixo Temático"
                  errors={errosThematicAxis}
                  register={registerThematicAxis}
                  commentTittle={texto}
                  name={`eixo_tematico.${fieldIndex}.texto`}
                  defaultValue={`eixo_tematico.${fieldIndex}.texto`}
                  placeholder={`Digite o eixo temático ${fieldIndex + 1} do plano`}
                />
                {fieldIndex !== 0 && !preview && (
                  <div className="delete-icon" onClick={() => {
                    setOpenDeleteModal(true);
                    setDeleteEixoId(fieldIndex);
                    setDeleteId(field.id)
                  }} style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                    </svg>
                  </div>
                )}
              </div>
              <div className="error_wrapper">
                <span>{getMessageErrorByNameRef(errosThematicAxis, 'texto')}</span>
              </div>
              <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={async () => {
                if (deleteEixoId) {
                  removeThematicAxis(deleteEixoId)
                }

                if (deleteId != 0) {
                  DeleteThematicAxisElaborations(deleteId);
                  const relacionamento = await consultarRelacionamento();
                  const root = JSON.parse(JSON.parse(relacionamento));

                  const newItem = ClearInitialData(root, deleteId);

                  startRelacionamento(JSON.stringify(newItem));
                  atualizarRelacionamento();

                  setDeleteId(0)
                }
                setOpenDeleteModal(false);
              }} />
            </React.Fragment>
          )
        })}
        <div className="add_line_wrapper" style={{ marginTop: '.5rem' }}>
          <p onClick={() => appendThematicAxis({ texto: '', id: '' })}>
            {`+ Adicionar linha`}
          </p>
        </div>
      </Grid>
    );
  }, [fields, openDeleteModal, errosThematicAxis]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitThematicAxis(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            {ReplaceButton()}

          </Grid>
          {Fields()}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={12}>
            <ActionButtonElaborations disable={disabeSubmit} layer_indicator={false} isOptional="true" />
          </Grid>
          <ReutilizarElaborationDialog
            title="Eixo Temático"
            reuseData={data}
            open={reutilizar}
            setOpen={() => setReutilizar(false)}
            setValue={(e) => {
              e.map((item: any, index: any) => {
                setValueThematicAxis(`eixo_tematico.${index}.texto`, item.texto)
              });
            }}
          />
          <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
        </Grid>
      </Container>
    </form>
  )
}
