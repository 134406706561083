import axios from 'axios';
import { ACCESS } from "@/utils/enviroments";
import { Api } from '@/services/configs/axios';

export class RelacionamentoJsonService {
  private relacionamento: any | null;
  private apiUrl: string = ACCESS.API_URL + "plano/relacionamento-json/";
  private idPlano: number = 0

  constructor(idPlano: number) {
    this.relacionamento = null;
    this.idPlano = idPlano;
  }

  consultarRelacionamento = async () => {
    try {
      const response = await Api.get<any>(this.apiUrl + this.idPlano);

      const data = response.data.data.json as any
      const relacionamento = data;

      return relacionamento;
    } catch (error) {
      if (axios.isAxiosError(error)) {
      } else {
      }

      return [];
    }
  }

  startRelacionamento = (item: any): void => {
    this.relacionamento = item

  };

  salvarRelacionamento = async (): Promise<void> => {
    if (this.relacionamento == null) {
      return;
    }
    if (this.idPlano == 0) {
      return;
    }

    try {
      const response = await Api.post(this.apiUrl + this.idPlano, {
        json: JSON.stringify(this.relacionamento),
      });

      console.log('response salvar relacionamento', response)
    } catch (error) {
      console.log('error salvar relacionamento', { error })

      if (axios.isAxiosError(error)) {
      } else {
      }
    }
  }

  atualizarRelacionamento = async (): Promise<void> => {
    if (this.relacionamento == null) {
      return;
    }
    if (this.idPlano == 0) {
      return;
    }


    try {
      const response = await Api.put(this.apiUrl + this.idPlano, {
        json: JSON.stringify(this.relacionamento),
      });

    } catch (error) {
      if (axios.isAxiosError(error)) {
      } else {
      }
    }
  }
}


