import React from "react"
import { Grid } from '@mui/material'
import { MainContainer } from './styled';
import { ActionButtonElaborations } from "../../ActionButton";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { ComoMontarMeta } from "./svgs/ComoMontarMeta";

export const GoalsDemonstrationForm: React.FC = () => {
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem } = usePreparationOfPlans();
  return (
    <MainContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="first_row">
            <span>Como montar uma Meta?</span>
            <p>
              Para criar uma meta eficaz, descreva claramente o resultado quantitativo desejado, indique o valor almejado, associe um indicador específico para medir o progresso e estabeleça um prazo definido para alcançar esse resultado.
            </p>
            <div className="svg_container">
              <ComoMontarMeta />
            </div>
          </div>
          <div className="second_row">
            <span>Exemplos</span>
            <p>
              Reduzir o tempo médio de espera no atendimento ao paciente de 15 minutos para 8 minutos até o final de 2027.
            </p>
            <p>
              Estabelecer três novas escolas bilíngues na região metropolitana até o final de 2025.
            </p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
        <Grid item xs={12}>
          <ActionButtonElaborations onClick={() => {
            const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
            const id = plan[selectedItemsMenu?.layer as string]
              ?.find((item: any) => item.id == selectedItemsMenu?.id).id + 1;

            handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true })
          }} isOptional="false" />
        </Grid>
      </Grid>
    </MainContainer>
  )
}
