export const Svg_010 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={58}
    height={59}
    fill="none"
  >
    <path
      fill="url(#a)"
      d="M41.46 1.82V0H16.54v1.82c0 2.365.95 4.619 2.592 6.246h19.736A8.812 8.812 0 0 0 41.46 1.82Z"
    />
    <path fill="url(#b)" d="M19.371 11.523H38.63v4.034H19.37v-4.034Z" />
    <path
      fill="url(#c)"
      d="m47.213 24.986-6.42-5.972H17.207l-6.42 5.972C6.81 28.684 4.53 33.948 4.53 39.429 4.531 50.22 13.162 59 23.771 59h10.458c10.609 0 19.24-8.78 19.24-19.571 0-5.48-2.28-10.745-6.256-14.443ZM28.35 36.466h1.297c2.854 0 5.175 2.36 5.175 5.263 0 2.537-1.773 4.66-4.124 5.155v2.309h-3.398v-2.239c-2.323-.302-4.124-2.325-4.124-4.769h3.398c0 .745.596 1.351 1.328 1.351h1.746c.979 0 1.776-.81 1.776-1.807 0-.996-.797-1.806-1.776-1.806h-1.297c-2.854 0-5.175-2.362-5.175-5.264 0-2.536 1.773-4.66 4.124-5.155v-2.309h3.398v2.239c2.323.302 4.124 2.325 4.124 4.769h-3.398a1.34 1.34 0 0 0-1.328-1.35h-1.746c-.979 0-1.776.81-1.776 1.806s.797 1.807 1.776 1.807Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={22.302}
        x2={23.95}
        y1={1.21}
        y2={9.449}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={23.825}
        x2={24.37}
        y1={12.128}
        y2={16.342}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={15.848}
        x2={32.937}
        y1={25.012}
        y2={58.859}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
    </defs>
  </svg>
)
