import dayjs from "dayjs";
import React from "react";
import * as Yup from 'yup';
import { TrashIcon } from "./trash";
import _, { isNumber } from "lodash";
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { Grid, Pagination } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { SelectFieldElaboration } from "../../Inputs/SelectField";
import { CreateUnityElaboration } from "../../Modal/CreateUnidade";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { MultipleSelect } from "@/components/global/Inputs/MultipleInput";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { DashboardManagementPlansService } from "@/services/endpoints/dashboard";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { analisarTerritorioArray, encontrarCorrespondentes, handleContentRelatedToTerritorySelected, removeDuplicatesFromA } from "../utils";
import { Organograma } from "@/utils/organograma/Organograma";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";

const defaultValue = {
  id: '',
  tor: [],
  meta: '',
  setor: '',
  orgao: '',
  territorio: '',
  publicoAlvo: '',
  responsavel: '',
  indicadores: [
    {
      fonte: '',
      indicador: '',
      polaridade: '',
      unidadeMedida: '',
      formulaCalculo: '',
      planejados: [
        {
          prazo: null,
          planejado: '',
        }
      ]
    }
  ]
}

type InputsGoals = {
  meta: any;
}

const SchemaGoals = Yup.object().shape({
  meta: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      setor: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
      territorio: Yup.string(),
      publicoAlvo: Yup.string(),
      responsavel: Yup.string().required('Campo obrigatório'),
      indicadores: Yup.array().of(
        Yup.object().shape({
          fonte: Yup.string().required("Campo obrigatório"),
          indicador: Yup.string().required('Campo obrigatório'),
          polaridade: Yup.string().required('Campo obrigatório'),
          unidadeMedida: Yup.string().required('Campo obrigatório'),
          formulaCalculo: Yup.string().required('Campo obrigatório'),
          planejados: Yup.array().of(
            Yup.object().shape({
              prazo: Yup.date().nonNullable().required('Campo obrigatório'),
              planejado: Yup.string().required('Campo obrigatório')
            })
          )
        })
      )
    })
  ).required(''),
});

const transformMetaData = (data: any[]) => {
  return data.map((item: any) => {
    const tor = item?.metasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.metasTerritorio)

    return {
      tor,
      territorio,
      id: item?.id,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      publicoAlvo: item?.publicoAlvo || '',
      responsavel: item?.responsavel || '',
      prazo: item.data ? dayjs(item.data) : null,
      indicadores: item?.indicadores?.map((indicador: any) => ({
        id: indicador?.id,
        fonte: indicador?.fonte || '',
        indicador: indicador?.indicador || '',
        polaridade: indicador?.polaridade || '',
        unidadeMedida: indicador?.unidadeMedida || '',
        formulaCalculo: indicador?.formulaCalculo || '',
        planejados: indicador?.values?.map((planejado: any) => ({
          id: planejado?.id,
          prazo: planejado?.data ? dayjs(planejado?.data) : null,
          planejado: planejado?.planejado || ''
        }))
      }))
    };
  });
};

const transformMetaDataReuse = (data: any) => {
  return data.map((item: any) => {
    const tor = item?.metasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.metasTerritorio)

    return {
      tor,
      territorio,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      publicoAlvo: item?.publicoAlvo || '',
      responsavel: item?.responsavel || '',
      prazo: item.data ? dayjs(item.data) : null,
      indicadores: item?.indicadores?.map((indicador: any) => ({
        id: indicador?.id,
        fonte: indicador?.fonte || '',
        indicador: indicador?.indicador || '',
        polaridade: indicador?.polaridade || '',
        unidadeMedida: indicador?.unidadeMedida || '',
        formulaCalculo: indicador?.formulaCalculo || '',
        planejados: indicador?.values?.map((planejado: any) => ({
          id: planejado?.id,
          prazo: planejado?.data ? dayjs(planejado?.data) : null,
          planejado: planejado?.planejado || ''
        }))
      }))
    };
  });
};

export const GoalsForm: React.FC = () => {
  const {
    trigger,
    clearErrors,
    watch: watchGoals,
    control: controlGoals,
    register: registerGoals,
    setValue: setValueGoals,
    handleSubmit: handleSubmitGoals,
    formState: { errors: errosGoals }
  } = useForm<InputsGoals>({
    resolver: yupResolver(SchemaGoals),
    defaultValues: { meta: [defaultValue] }
  });

  const { append: appendGoals, update: updateGoals, remove: removeGoals } = useFieldArray({
    control: controlGoals,
    name: "meta"
  });

  const fields = watchGoals('meta');

  const {
    GetUnidadesMedida,
    GoalsElaborations,
    NumberElaboration,
    PostUnidadesMedida,
    IndicatorsElaboration,
    DeleteGoasElaborations,
    UpdateGoalsElaborations,
    GetObjectivesElaboration,
    UpdateNumbersElaboration,
    DeleteNumbersElaboration,
    DeleteIndicatorElaboration,
    GetThematicAxisElaboration,
    GetObjectivesEixoElaboration,
  } = new ElaborationService();

  const router = useRouter();

  const {
    itemsMenu,
    completCode,
    setNavigable,
    selectedItemsMenu,
    setDefaultItemMenuId,
    handleFetchProgressCodePlanId,
  } = usePreparationOfPlans();

  const routerId = router.query.id;
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState<any[]>([]);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [filterData, setFilterData] = React.useState({
    setor: [] as any[],
    orgaoResponsavel: [] as any[],
    territorio: {} as Record<string, any>
  });
  const [loading, setLoading] = React.useState(false);
  const [openUnity, setOpenUnity] = React.useState(false);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [deleteGoalId, setDeleteGoalId] = React.useState(0);
  const [deleteLinhaId, setDeleteLinhaId] = React.useState(0);
  const [objetivos, setObjetivos] = React.useState<any[]>([]);
  const [goalSelectedIndex, setGoalIndex] = React.useState(0);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [deleteGoalIndex, setDeleteGoalIndex] = React.useState(0);
  const { getAllFilters } = new DashboardManagementPlansService();
  const [deleteLinhaIndex, setDeleteLinhaIndex] = React.useState(0);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [deleteIndicatorId, setDeleteIndicatorId] = React.useState(0);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [unidadesMedida, setUnidadesMedida] = React.useState<any[]>([]);
  const [openDeleteModal3, setOpenDeleteModal3] = React.useState(false);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const [indicadoresSelected, setIndicadores] = React.useState({} as any);
  const [deleteIndicatorIndex, setDeleteIndicatorIndex] = React.useState(0);
  const [openDeleteLinhaModal, setOpenDeleteLinhaModal] = React.useState(false);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);

  const total = objetivos.length;

  function getMessageErrorByNameRef(json: any, title: any) {
    try {
      const foundObject = json?.meta?.find((item: any) => item[title]);
      return foundObject ? foundObject[title].message : null;
    } catch (error) {
      return null
    }

  }

  function getMessageErrorByNameRefSubArray(json: any, title: any, index: any) {
    try {
      const foundObject = json?.meta?.map((item: any) => {
        return item.indicadores.find((subItem: any) => subItem[title]);
      })

      return foundObject && foundObject[index] ? foundObject[index][title].message : null;
    } catch (error) {
      return null
    }
  }

  function getMessageErrorByNameRefSubArrayPlanned(json: any, title: any, index: any) {
    if (Object.keys(json).length == 0) return null;

    const planejadosArray = json.meta.flatMap((item: any) =>
      item?.indicadores?.flatMap((indicador: any) => indicador.planejados)
    );

    return planejadosArray && planejadosArray[index] && planejadosArray[index][title] ? planejadosArray[index][title].message : null;
  }

  const handleLeftClick = async () => {
    handleChange("", 1)
    const isValid = await trigger();

    if (isValid) {
      handleSubmitGoals(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setCurrentIndexEixo(currentIndexEixo === 0 ? eixos_tematicos.length - 1 : currentIndexEixo - 1);
  };

  const handleRightClick = async () => {
    handleChange("", 1)
    const isValid = await trigger();

    if (isValid) {
      handleSubmitGoals(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setCurrentIndexEixo(currentIndexEixo === eixos_tematicos.length - 1 ? 0 : currentIndexEixo + 1);
  };

  const handleChange = async (_: any, value: any) => {
    if (typeof _ == 'string') {
      setPage(value);
      return;
    }

    const isValid = await trigger();

    if (isValid) {
      handleSubmitGoals(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);

    }
    setPage(value);
  };

  const handleSaveUnity = (e: any) => {
    PostUnidadesMedida({ descricao: e }).then(res => {
      GetUnidadesMedida().then(res => {
        const { data } = res.data as any;
        setUnidadesMedida(data.map((item: any) => ({ value: item.unidade, name: item.unidade })));
        setOpenUnity(false);
      })
    })
  };

  const filterSectors = (data: any, orgao: any) => {
    const findItem = data?.orgaoResponsavel?.find((item: any) => item?.id == orgao);
    const filterItems =
      data?.setor?.filter((item: any) => item.codUa == findItem?.codUa)
        .map((item: any) => ({ value: item?.id, name: item?.descricao }))

    return filterItems
  };

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos")) {
      GetThematicAxisElaboration(routerId).then(res => {
        setEixosTematicos(res.data);
      });
    }
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    setLoading(true);
    if (router.query.id && (router.pathname.split('/').includes("elaboracao_planos") && eixos_tematicos.length > 0 || router.pathname.split('/').includes("aprovacao_planos") && eixos_tematicos.length > 0)) {
      GetObjectivesEixoElaboration(routerId, eixos_tematicos[currentIndexEixo].id).then((res: any) => {
        setObjetivos(res.data);
      }).finally(() => setLoading(false));
    }
  }, [currentIndexEixo, router.query, eixos_tematicos, router.pathname, page, completCode]);

  React.useEffect(() => {
    const objetivo = objetivos[page - 1];
    clearErrors();
    if (objetivo?.metas?.length > 0) {
      setValueGoals('meta', transformMetaData(objetivo.metas))
    } else {
      setValueGoals('meta', [defaultValue])
    }
  }, [objetivos, page, completCode]);

  React.useEffect(() => {
    if (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos'))
      getAllFilters().then((res: any) => {
        const { data } = res.data as any;
        setFilterData(data);
      })
  }, [router.pathname]);

  React.useEffect(() => {
    if (planId && tipoFluxo == 'substituir' && (router.pathname.split('/').includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos"))) {
      GetObjectivesElaboration(planId).then((res: any) => {
        setData(res.data)
      });
    }
  }, [planId, tipoFluxo, router.pathname]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);

  React.useEffect(() => {
    GetUnidadesMedida().then(res => {
      const { data } = res.data as any;
      setUnidadesMedida(data.map((item: any) => ({ value: item.unidade, name: item.unidade })));
    })
  }, []);

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const { insertItemById, updateItemById, checkLayerExists, deleteItemById } = useHierarchyPreparationOfPlans();
  const { startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);

  const onSubmit: SubmitHandler<any> = React.useCallback(async (data, event: any) => {
    const { meta } = data;
    const objetivoId = objetivos[page - 1]?.id;
    const metaCode = completCode.find(item => item.code == 'metas')

    if (disabeSubmit) return;

    setDisableSubmit(true);

    let submittedData = meta;

    if (event?.data?.length > 0) {
      submittedData = event.data;
    }

    try {
      for (const [indexMeta, itemMeta] of submittedData.entries()) {
        for (const [indexIndicador, itemIndicador] of itemMeta.indicadores.entries()) {
          const { planejados, unidadeMedida: uni, id, ...rest } = itemIndicador;

          const indicador = isNumber(id)
            ? {
              ...rest,
              id,
              planoId: router.query.id,
              unidadeMedida: unidadesMedida.find(item => item.value === uni)?.name
            }
            : {
              ...rest,
              planoId: router.query.id,
              unidadeMedida: unidadesMedida.find(item => item.value === uni)?.name
            };

          try {
            const res = await IndicatorsElaboration(indicador);
            const data = res.data;

            setValueGoals(`meta.${indexMeta}.indicadores.${indexIndicador}.id`, data.id);

            itemIndicador.id = data.id;
          } catch (error) {
            console.error('Erro ao elaborar indicador:', error);
          }
        }
      }

      for (const [indexMeta, itemMeta] of fields.entries()) {
        const { indicadores, tor, ...rest } = itemMeta;

        const meta = {
          ...rest,
          objetivoId,
          planoId: router.query.id,
          eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
          indicadoresIds: indicadores.map((item: any) => item.id), // IDs agora estão garantidos
          prazo: dayjs(itemMeta.prazo).format('YYYY-MM-DD') + 'T00:00:00',
          territorio: encontrarCorrespondentes(tor, filterData.territorio),
        };

        try {
          if (itemMeta.id) {
            await UpdateGoalsElaborations(meta, itemMeta.id);
          } else {
            const postResponse = await GoalsElaborations(meta);

            const relacionamentoCheck = await consultarRelacionamento();
            const rootCheck = JSON.parse(JSON.parse(relacionamentoCheck));
            const checklayer = checkLayerExists({ root: rootCheck, layerName: 'meta', targetId: objetivoId });

            if (!checklayer) {
              for (const item of [meta]) {
                const relacionamento = await consultarRelacionamento();
                const root = JSON.parse(JSON.parse(relacionamento));

                const newInsertItem = updateItemById({
                  root,
                  targetId: objetivoId,
                  item: { metas: { itens: [] } },
                  layerName: 'objetivos',
                });

                startRelacionamento(JSON.stringify(newInsertItem));
                await atualizarRelacionamento(); // Aguarda o término da atualização
              }
            }

            for (const item of [meta]) {
              const relacionamento = await consultarRelacionamento();
              const root = JSON.parse(JSON.parse(relacionamento));

              const newInsertItem = insertItemById({
                root,
                targetId: objetivoId,
                item: { id: postResponse?.data?.data, relacionamento: {} },
                layerName: 'metas',
              });

              startRelacionamento(JSON.stringify(newInsertItem));
              await atualizarRelacionamento(); // Aguarda o término da atualização
            }

          }
        } catch (error) {
          console.error('Erro ao processar metas:', error);
        }

        for (const [indexIndicador, indicadorItem] of indicadores.entries()) {
          for (const [indexPlanejado, planejadoItem] of indicadorItem.planejados.entries()) {
            const planejado = {
              planoId: Number(router.query.id),
              planejado: Number(planejadoItem.planejado),
              indicadorId: indicadorItem.id, // O ID está garantido aqui
              date: dayjs(planejadoItem.prazo).format('YYYY-MM-DD') + 'T00:00:00',
            };

            try {
              if (planejadoItem.id) {
                await UpdateNumbersElaboration(planejadoItem.id, planejado);
              } else {
                await NumberElaboration(planejado);
              }
            } catch (error) {
              console.error('Erro ao processar planejado:', error);
            }
          }
        }
      };

      toggleSuccessSnackbar();

      if (event.nativeEvent.submitter.name == 'next_step') {
        setNavigable(true)

        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
      } else {
        setNavigable(false)
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id)
        handleFetchProgressCodePlanId(router.query.id)
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000)
      setDisableSubmit(false);
    } catch {
      toggleErrorSnackbar();
      setDisableSubmit(false);
    }

    if (metaCode != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const newItem = {
        "tooltip": "",
        "position": "",
        "name": "Meta",
        "subordinates": [],
        "manager": 'Objetivo',
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, 'Objetivo', newItem);

      startOrganograma(newOrganograma);
      salvarOrganograma();

    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  }, [fields])

  const ThematicAxisBanner = React.useCallback(() => {
    return (
      <React.Fragment>
        <Grid item xs={12} className="goals_select_content_wrapper">
          <p>Eixo temático</p>
        </Grid>
        <Grid item xs={12}>
          <div className="goals_select_wrapper">
            <div className="goals_left_arrow" onClick={() => handleLeftClick()}>{"<"}</div>
            <div className="goals_select_content_wrapper">
              <p>{eixos_tematicos[currentIndexEixo]?.texto}</p>
              <span>Eixo temático {currentIndexEixo + 1} de {eixos_tematicos.length}</span>
            </div>
            <div className="goals_rigth_arrow" onClick={() => handleRightClick()}>{">"}</div>
          </div>
        </Grid>
        {eixos_tematicos[currentIndexEixo]?.obejtivos?.length == 0 ? null : (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <div className="pagination_wrapper">
              <Pagination
                count={total}
                page={page}
                onChange={handleChange}
                hidePrevButton hideNextButton
                variant="outlined" shape="rounded"
              />
              <p>Página {page} de {total}</p>
            </div>
          </Grid>
        )
        }
        <Grid item xs={12}>
          {eixos_tematicos[currentIndexEixo]?.obejtivos?.length == 0 ? null : (
            <div className="strategy_wrapper">
              <p>
                <b>Objetivo:</b>&nbsp;
                {objetivos[page - 1]?.texto}
              </p>
            </div>
          )}
        </Grid>
      </React.Fragment >
    );
  }, [objetivos, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, fields]);

  const Fields = React.useCallback(() => {
    if (eixos_tematicos && eixos_tematicos[currentIndexEixo] && eixos_tematicos[currentIndexEixo].obejtivos.length == 0) return null;

    return (
      <>
        <Grid item xs={12} sx={{ ml: 2 }}>
          {fields?.map((field: any, goalIndex: any) => {
            const randomId = Math.random();
            const tor = watchGoals(`meta.${goalIndex}.tor`);
            const orgao = watchGoals(`meta.${goalIndex}.orgao`);
            const setor = watchGoals(`meta.${goalIndex}.setor`);
            const texto = watchGoals(`meta.${goalIndex}.texto`);
            const prazo = watchGoals(`meta.${goalIndex}.prazo`);
            const territorio = watchGoals(`meta.${goalIndex}.territorio`);
            const responsavel = watchGoals(`meta.${goalIndex}.responsavel`);

            return (
              <React.Fragment key={randomId}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 2,
                    borderRadius: '5px',
                    padding: '0 1rem  0 0',
                    background: '#DFDFDF',
                  }}
                >
                  <Grid item xs={12}>
                    <input
                      type="hidden"
                      defaultValue={field?.id}
                      {...registerGoals(`meta.${goalIndex}.id`, { value: field?.id })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePickerElaboration
                      width="90%"
                      label="Prazo"
                      required={true}
                      errors={errosGoals}
                      defaultValue={prazo}
                      commentTittle={texto}
                      control={controlGoals}
                      name={`meta.${goalIndex}.prazo`}
                    />
                    <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                      <span>{getMessageErrorByNameRef(errosGoals, 'prazo')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldElaboration
                      rows={3}
                      label="Meta"
                      multiline={true}
                      required={true}
                      errors={errosGoals}
                      defaultValue={texto}
                      commentTittle={texto}
                      register={registerGoals}
                      placeholder="Digite a meta"
                      name={`meta.${goalIndex}.texto`}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosGoals, 'texto')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectFieldElaboration
                      required={false}
                      label="Território"
                      errors={errosGoals}
                      commentTittle={texto}
                      register={registerGoals}
                      defaultValue={territorio}
                      placeholder="Selecione o território"
                      data={[
                        { value: 0, name: '-' },
                        { value: 1, name: 'AP' },
                        { value: 2, name: 'RP' },
                        { value: 3, name: 'RA' },
                        { value: 4, name: 'Bairros' }
                      ]}
                      name={`meta.${goalIndex}.territorio`}
                      onClick={() => updateGoals(goalIndex, { ...field, tor: [] })}
                      setValue={(value: any) => setValueGoals(`meta.${goalIndex}.territorio`, value)}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosGoals, 'territorio')}</span>
                    </div>
                  </Grid>
                  {territorio != 0 && (
                    <Grid item xs={12}>
                      <MultipleSelect
                        fullWidth={true}
                        value={Array.isArray(tor) ? tor : []}
                        setValue={(e) => {
                          const items = removeDuplicatesFromA(tor, e)
                          updateGoals(goalIndex, { ...field, tor: items })
                        }}
                        {...registerGoals(`meta.${goalIndex}.tor`, { required: false })}
                        data={handleContentRelatedToTerritorySelected(filterData, territorio, tor)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <TextFieldElaboration
                      required={false}
                      errors={errosGoals}
                      label="Público-alvo"
                      register={registerGoals}
                      commentTittle={texto}
                      placeholder="Digite o público-alvo"
                      name={`meta.${goalIndex}.publicoAlvo`}
                    />
                    <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                      <span>{getMessageErrorByNameRef(errosGoals, 'publicoAlvo')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <SelectFieldElaboration
                      label="Órgão"
                      required={true}
                      errors={errosGoals}
                      defaultValue={orgao}
                      commentTittle={texto}
                      placeholder="Selecione"
                      register={registerGoals}
                      name={`meta.${goalIndex}.orgao`}
                      setValue={(value) => setValueGoals(`meta.${goalIndex}.orgao`, value)}
                      data={filterData.orgaoResponsavel.map((item) => ({ value: item.id, name: item.sigla }))}
                    />
                    <div className="error_wrapper" style={{ marginTop: '-.2rem' }}>
                      <span>{getMessageErrorByNameRef(errosGoals, 'orgao')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <SelectFieldElaboration
                      label="Setor"
                      required={true}
                      errors={errosGoals}
                      defaultValue={setor}
                      commentTittle={texto}
                      placeholder="Selecione"
                      register={registerGoals}
                      name={`meta.${goalIndex}.setor`}
                      data={filterSectors(filterData, orgao)}
                      setValue={(value) => setValueGoals(`meta.${goalIndex}.setor`, value)}
                    />
                    <div className="error_wrapper" style={{ marginTop: '-.2rem' }}>
                      <span>{getMessageErrorByNameRef(errosGoals, 'setor')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={3} sx={{ mt: -.3 }}>
                    <TextFieldElaboration
                      required={true}
                      errors={errosGoals}
                      label="Responsável"
                      commentTittle={texto}
                      register={registerGoals}
                      defaultValue={responsavel}
                      placeholder="Nome do responsável"
                      name={`meta.${goalIndex}.responsavel`}
                    />
                    <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                      <span>{getMessageErrorByNameRef(errosGoals, 'responsavel')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <p className="sub_item_topographic">
                      Indicadores
                    </p>
                  </Grid>
                  {field?.indicadores?.map((indicator: any, indicatorIndex: any, arr: any[]) => {
                    const randomIdIndicadores = Math.random();
                    const lastIdesOfIndicator = arr?.lastIndexOf(arr[arr.length - 1]);
                    const fonte = watchGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.fonte`);
                    const indicador = watchGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.indicador`);
                    const unidade = watchGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.unidadeMedida`);
                    const polaridade = watchGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.polaridade`);
                    const formula = watchGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.formulaCalculo`);

                    return (
                      <React.Fragment key={randomIdIndicadores}>
                        <Grid
                          container
                          spacing={2}
                          className="sub_container"
                          sx={{ background: '#fff', margin: '0 0 1rem 1rem', padding: '0 1rem 1rem 1rem ' }}
                        >
                          <Grid item xs={12} sx={{ mt: 2 }}>
                            <TextFieldElaboration
                              required={true}
                              label="Indicador"
                              errors={errosGoals}
                              commentTittle={texto}
                              register={registerGoals}
                              defaultValue={indicador}
                              placeholder="Digite o indicador"
                              name={`meta.${goalIndex}.indicadores.${indicatorIndex}.indicador`}
                            />
                            <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosGoals, 'indicador', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <SelectFieldElaboration
                              required={true}
                              errors={errosGoals}
                              commentTittle={texto}
                              data={unidadesMedida}
                              defaultValue={unidade}
                              register={registerGoals}
                              label="Unidade de medida"
                              handleCreate={() => setOpenUnity(true)}
                              placeholder="Crie uma nova unidade de medida"
                              name={`meta.${goalIndex}.indicadores.${indicatorIndex}.unidadeMedida`}
                              setValue={(value) => setValueGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.unidadeMedida`, value)}
                            />
                            <div className="error_wrapper" style={{ marginTop: '' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosGoals, 'unidadeMedida', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <TextFieldElaboration
                              required={true}
                              errors={errosGoals}
                              placeholder="Digite"
                              commentTittle={texto}
                              defaultValue={formula}
                              register={registerGoals}
                              label="Fórmula de cálculo"
                              name={`meta.${goalIndex}.indicadores.${indicatorIndex}.formulaCalculo`}
                            />
                            <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosGoals, 'formulaCalculo', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          <Grid item xs={3} sx={{ mt: 0 }}>
                            <TextFieldElaboration
                              label="Fonte"
                              required={true}
                              errors={errosGoals}
                              placeholder="Digite"
                              defaultValue={fonte}
                              commentTittle={texto}
                              register={registerGoals}
                              name={`meta.${goalIndex}.indicadores.${indicatorIndex}.fonte`}
                            />
                            <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosGoals, 'fonte', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          <Grid item xs={3} sx={{ mt: 0 }}>
                            <SelectFieldElaboration
                              required={true}
                              label="Polaridade"
                              errors={errosGoals}
                              commentTittle={texto}
                              placeholder="Selecione"
                              register={registerGoals}
                              defaultValue={polaridade}
                              name={`meta.${goalIndex}.indicadores.${indicatorIndex}.polaridade`}
                              setValue={(value) => setValueGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.polaridade`, value)}
                              data={[{ value: 'Negativo', name: 'Negativo' }, { value: 'Positivo', name: 'Positivo' }, { value: 'Neutro', name: 'Neutro' }]}
                            />
                            <div className="error_wrapper" style={{ marginTop: '' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosGoals, 'polaridade', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          {indicator?.planejados?.map((plannedField: any, plannedIndex: any, array: any) => {
                            const randomIdPlanejados = Math.random();
                            const lastIdesOfPlanned = array?.lastIndexOf(array[array.length - 1]);
                            const id = watchGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.id`)
                            const prazo = watchGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.prazo`);
                            const planejado = watchGoals(`meta.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.planejado`);

                            return (
                              <React.Fragment key={randomIdPlanejados}>
                                <Grid item xs={12}>
                                  <input
                                    type="hidden"
                                    {
                                    ...registerGoals(
                                      `meta.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.id`,
                                      { value: id })}
                                    defaultValue={id}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <DatePickerElaboration
                                    width="90%"
                                    label="Prazo"
                                    required={true}
                                    commentTittle={texto}
                                    errors={errosGoals}
                                    defaultValue={prazo}
                                    control={controlGoals}
                                    name={`meta.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.prazo`}
                                  />
                                  <div className="error_wrapper" style={{ marginTop: '2rem' }}>
                                    <span>{getMessageErrorByNameRefSubArrayPlanned(errosGoals, 'prazo', plannedIndex)}</span>
                                  </div>
                                </Grid>
                                <Grid item xs={9}>
                                  <TextFieldElaboration
                                    type='number'
                                    required={true}
                                    label="Planejado"
                                    commentTittle={texto}
                                    errors={errosGoals}
                                    defaultValue={planejado}
                                    register={registerGoals}
                                    placeholder="Digite o valor do planejado para o indicador da meta"
                                    name={`meta.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.planejado`}
                                  />
                                  <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                                    <span>{getMessageErrorByNameRefSubArrayPlanned(errosGoals, 'planejado', plannedIndex)}</span>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sx={{ mb: 2, display: 'flex', justifyContent: lastIdesOfPlanned == plannedIndex ? 'space-between' : 'flex-end' }}>
                                  {lastIdesOfPlanned == plannedIndex && (
                                    <div className="add_line_wrapper">
                                      <p onClick={() => {
                                        const newPlanejados = [
                                          ...indicator.planejados,
                                          { prazo: null, planejado: '' },
                                        ];
                                        updateGoals(goalIndex, {
                                          ...field,
                                          indicadores: field.indicadores.map((ind: any, i: number) => i === indicatorIndex ? { ...ind, planejados: newPlanejados } : ind)
                                        });
                                      }}>
                                        {`+ Adicionar linha`}
                                      </p>
                                    </div>
                                  )}
                                  {plannedIndex != 0 && (
                                    <div
                                      className="remove_line_wrapper"
                                      onClick={() => {
                                        setOpenDeleteLinhaModal(true);
                                        setDeleteLinhaIndex(plannedIndex)
                                        setDeleteLinhaId(plannedField?.id)
                                        setGoalIndex(goalIndex)
                                        setIndicadores(indicator)
                                      }}
                                    >
                                      <TrashIcon />
                                      <p>Excluir</p>
                                    </div>
                                  )}
                                </Grid>
                                <DeleteElaborationDialog open={openDeleteLinhaModal} setOpen={(e) => setOpenDeleteLinhaModal(e)} onDelete={() => {
                                  const newPlanejados = indicadoresSelected.planejados.filter((_: any, i: any) => i != goalSelectedIndex);

                                  updateGoals(goalSelectedIndex, {
                                    ...field,
                                    indicadores: field.indicadores.map((ind: any, i: number) => i === indicatorIndex ? { ...ind, planejados: newPlanejados } : ind)
                                  });

                                  if (deleteLinhaId != 0) {
                                    DeleteNumbersElaboration(deleteLinhaId);
                                    setDeleteLinhaId(0)
                                  }

                                  setOpenDeleteLinhaModal(false);
                                }} />
                              </React.Fragment>
                            )
                          })}
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2, mt: -2, display: 'flex', justifyContent: lastIdesOfIndicator == indicatorIndex ? 'space-between' : 'flex-end' }}>
                          {lastIdesOfIndicator == indicatorIndex && (
                            <div className="add_line_wrapper">
                              <p onClick={() => {
                                const newIndicadores = [
                                  ...field.indicadores,
                                  {
                                    fonte: '',
                                    indicador: '',
                                    polaridade: '',
                                    unidadeMedida: '',
                                    formula_calculo: '',
                                    planejados: [
                                      {
                                        prazo: null,
                                        planejado: '',
                                      }
                                    ]
                                  }
                                ];
                                updateGoals(goalIndex, { ...field, indicadores: newIndicadores });
                              }}>
                                {`+ Adicionar indicador para meta`}
                              </p>
                            </div>

                          )}
                          {indicatorIndex != 0 && !preview && (
                            <div
                              className="remove_line_wrapper"
                              onClick={() => {
                                setOpenDeleteModal(true)
                                setDeleteIndicatorIndex(indicatorIndex)
                                setDeleteIndicatorId(field.indicadores[indicatorIndex]?.id)
                              }}
                            >
                              <TrashIcon />
                              <p>Excluir</p>
                            </div>
                          )}
                        </Grid>
                        <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={() => {
                          const newIndicadores = field.indicadores.filter((_: any, i: any) => i !== deleteIndicatorIndex);
                          updateGoals(goalIndex, { ...field, indicadores: newIndicadores });

                          if (deleteIndicatorId != 0) {
                            DeleteIndicatorElaboration(deleteIndicatorId);
                            setDeleteIndicatorId(0)
                          }

                          setOpenDeleteModal(false);
                        }} />
                      </React.Fragment>
                    )
                  })}
                  {goalIndex != 0 && (
                    <Grid item xs={12} sx={{ mb: 1 }} display='flex' justifyContent='flex-end'>
                      <div
                        className="remove_line_wrapper"
                        onClick={() => {
                          setOpenDeleteModal3(true);
                          setDeleteGoalId(field.id)
                          setDeleteGoalIndex(goalIndex)
                        }}
                      >
                        <TrashIcon />
                        <p>Excluir</p>
                      </div>
                    </Grid>
                  )}
                  <DeleteElaborationDialog open={openDeleteModal3} setOpen={(e) => setOpenDeleteModal3(e)} onDelete={async () => {
                    removeGoals(deleteGoalIndex);

                    if (deleteGoalId != 0) {
                      const relacionamento = await consultarRelacionamento();
                      const root = JSON.parse(JSON.parse(relacionamento));
                      
                      const newItem = deleteItemById({ root, layerName: 'meta', targetId: deleteGoalId });
    
                      startRelacionamento(JSON.stringify(newItem));
                      atualizarRelacionamento();

                      DeleteGoasElaborations(deleteGoalId);
                      setDeleteGoalId(0)
                    }

                    setOpenDeleteModal3(false);
                  }} />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        {objetivos.length > 0 && (
          <Grid item xs={12}>
            <div className="add_line_wrapper">
              <p onClick={() => appendGoals(defaultValue)}>
                {`+ Adicionar meta`}
              </p>
            </div>
          </Grid>
        )}
      </>
    );
  }, [
    fields,
    unidadesMedida,
    filterData,
    errosGoals,
    watchGoals,
    updateGoals,
    Math.random,
    controlGoals,
    registerGoals,
    openDeleteModal,
    openDeleteModal3,
    openDeleteLinhaModal,
    handleContentRelatedToTerritorySelected,
  ]);

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, openDisclaimer, reutilizar]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitGoals(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {ReplaceButton()}
          {ThematicAxisBanner()}
          {Fields()}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={12}>
            <ActionButtonElaborations disable={disabeSubmit} isOptional="true" layer_indicator={false} isEmpty={eixos_tematicos[currentIndexEixo]?.obejtivos?.length == 0} />
          </Grid>
          <ReutilizarElaborationDialog
            title="Metas"
            reuseData={data}
            open={reutilizar}
            setOpen={() => setReutilizar(false)}
            setValue={(e) => {
              e.forEach((item: any) => {
                setValueGoals('meta', transformMetaDataReuse(item.metas))
              })
            }}
          />
          <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
          <CreateUnityElaboration onClose={() => setOpenUnity(false)} open={openUnity} save={(e) => handleSaveUnity(e)} />
        </Grid>
      </Container>
    </form>
  )
}
