import React from "react";
import {
  useForm,
  useFieldArray,
} from "react-hook-form";
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { Container } from './styled';
import { useRouter } from "next/router";
import { SubmitHandler } from "react-hook-form";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { SelectFieldElaboration } from "../../Inputs/SelectField";
import { AutoCompleteElaboration } from "../../Inputs/AutoComplete";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { Organograma } from "@/utils/organograma/Organograma";

const defaultValuePreRegister = {
  tema: null,
  titulo: '',
  descricao: '',
  estrutura: [''],
  enteResponsavel: null,
  informacoesGerais: [''],
};

type InputsPreRegister = {
  pre_registro: {
    tema: number;
    titulo: string;
    descricao: string;
    estrutura: string[];
    enteResponsavel: number;
    informacoesGerais: string[];
  }[];
};

const SchemaPreRegister = Yup.object().shape({
  pre_registro: Yup.array().of(
    Yup.object().shape({
      titulo: Yup.string().required('O título é obrigatório.'),
      descricao: Yup.string().required('A descrição é obrigatória.'),
      estrutura: Yup.array()
        .min(1, 'Não deve ser possível enviar uma estrutura preenchida.')
        .test(
          'no-empty-string',
          'Deve haver pelo menos uma estrutura preenchida.',
          (value) => value && value.every((item) => item.trim() !== '')
        ),
      informacoesGerais: Yup.array()
        .min(1, 'Deve haver pelo menos uma informação geral preenchida.')
        .test(
          'no-empty-string',
          'Deve haver pelo menos uma informação geral preenchida.',
          (value) => value && value.every((item) => item.trim() !== '')
        ),
      tema: Yup.number().required('O tema é obrigatório.'),
      enteResponsavel: Yup.number().required('A esfera governamental é obrigatória.'),
    })
  ),
});

function getMessageErrorByNameRef(json: any, title: any) {
  const foundObject = json?.pre_registro?.find((item: any) => item[title]);
  return foundObject ? foundObject[title].message : null;
}

export const PreRegistrationForm: React.FC = () => {
  const {
    watch: watchPreRegister,
    control: controlPreRegister,
    register: registerPreRegister,
    setValue: setValuePreRegister,
    handleSubmit: handleSubmitPreRegister,
    formState: { errors: errosPreRegister } } = useForm<InputsPreRegister>({
      resolver: yupResolver(SchemaPreRegister as any),
      defaultValues: { pre_registro: [defaultValuePreRegister as any] }
    });

  const { update: updatePreRegister } = useFieldArray({
    name: "pre_registro",
    control: controlPreRegister,
  });

  const fields = watchPreRegister('pre_registro');

  const { setCurrentPlanTitle } = usePreparationOfPlans()

  const router = useRouter();
  const { planId } = useTipeOfFlow();
  const { tipo: tipoFluxo } = useTipeOfFlow();
  const { completCode } = usePreparationOfPlans();
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [deleteInfoIndex, setDeleteInfoIndex] = React.useState(0);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [deleteEstruturaIndex, setDeleteEstruturaIndex] = React.useState(0);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const [openDeleteEstruturaModal, setOpenDeleteStruturaModal] = React.useState(false);
  const [openDeleteInformacoesGeraisModal, setOpenDeletInformacoesGeraisModal] = React.useState(false);
  const { PreRegisterElaboration, UpdatePreRegisterElaboration, GetPreRegisterElaboration } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId,  } = usePreparationOfPlans();

  const addNewInformacoesGeraisField = (index: number) => {
    const fields = watchPreRegister('pre_registro')
    updatePreRegister(index, {
      ...fields[index],
      informacoesGerais: [...fields[index].informacoesGerais, ''],
    });
  };

  const addNewEstruturaField = (index: number) => {
    const fields = watchPreRegister('pre_registro')

    updatePreRegister(index, {
      ...fields[index],
      estrutura: [...fields[index].estrutura, ''],
    });
  };

  const addNewTheme = (index: number, value: any) => {
    const fields = watchPreRegister('pre_registro')

    updatePreRegister(index, {
      ...fields[index],
      tema: value,
    });
  };

  React.useEffect(() => {
    if (router.query.id && (router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos'))) {
      setLoading(true);
      GetPreRegisterElaboration(router.query.id).then((res: any) => {
        const { data } = res.data;
        setCurrentPlanTitle(data?.titulo);
        setValuePreRegister('pre_registro.0.tema', data.tema.id);
        setValuePreRegister('pre_registro.0.titulo', data?.titulo);
        setValuePreRegister('pre_registro.0.descricao', data?.descricao);
        setValuePreRegister('pre_registro.0.enteResponsavel', data.enteResponsavel ? data.enteResponsavel.id : null);
        setValuePreRegister('pre_registro.0.estrutura', data?.estruturas?.map((item: any) => item.descricao));
        setValuePreRegister('pre_registro.0.informacoesGerais', data?.informacoesGerais?.map((item: any) => item.descricao));
      }).finally(() => setLoading(false));
    }
  }, [completCode, router.query, router.pathname]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router])

  React.useEffect(() => {
    if (router.pathname.split('/').includes('pre_cadastro') && tipoFluxo == 'substituir') {
      GetPreRegisterElaboration(planId).then(res => {
        const { data } = res.data as any;
        const newData = { ...data, checked: false };
        setData([newData]);
      })
    }
  }, [router, router.pathname, tipoFluxo]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('aprovacao_planos')) {
      GetPreRegisterElaboration(router.query.id).then(res => {
        const { data } = res.data as any;
        const newData = { ...data, checked: false };
        setData([newData]);
      })
    }
  }, [router, router.pathname, tipoFluxo]);

  const [disabeSubmit, setDisableSubmit] = React.useState(false);

  const onSubmit: SubmitHandler<any> = React.useCallback(async (data, event: any) => {
    const { pre_registro } = data;

    if (disabeSubmit) return;

    setDisableSubmit(true);

    const parsedData = pre_registro.map((item: any) => {
      return {
        ...item,
        createdByPlanejator: true,
        tipoPlano: zustandSelectedCardItem.ref_id,
      }
    })

    setNavigable(false);

    if (router.query.id) {
      try {
        toggleSuccessSnackbar()
        await UpdatePreRegisterElaboration(parsedData[0], router.query.id);
        if (event.nativeEvent.submitter.name == 'next_step') {
          setNavigable(true);
          router.push('/dashboard/elaboracao_planos/' + router.query.id)

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          const planId = plan[selectedItemsMenu?.layer as string]
            .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;

          setDefaultItemMenuId(planId);
          handleFetchProgressCodePlanId(router.query.id)

        } else {
          setNavigable(false);
          handleFetchProgressCodePlanId(router.query.id)

          router.push('/dashboard/elaboracao_planos/' + router.query.id)

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          const planId = plan[selectedItemsMenu?.layer as string]
            .find((item: any) => item.id == selectedItemsMenu?.id).id;

          setDefaultItemMenuId(planId);
        }

        setTimeout(() => toggleSuccessSnackbar(), 3000);

      } catch (error) {
        toggleErrorSnackbar()
        setDisableSubmit(false)
      }
    } else {
      try {
        const res = await PreRegisterElaboration(parsedData);

        toggleSuccessSnackbar()

        if (event.nativeEvent.submitter.name == 'next_step') {
          setNavigable(true);
          router.push('/dashboard/elaboracao_planos/' + res.data.data.id);

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          const planId = plan[selectedItemsMenu?.layer as string]
            .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;

          setDefaultItemMenuId(planId);

        } else {
          setNavigable(false);
          handleFetchProgressCodePlanId(res.data.data.id)

          router.push('/dashboard/elaboracao_planos/' + res.data.data.id)

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          const planId = plan[selectedItemsMenu?.layer as string]
            .find((item: any) => item.id == selectedItemsMenu?.id).id;

          setDefaultItemMenuId(planId);
          setTimeout(() => toggleSuccessSnackbar(), 3000);

        }
      } catch (error) {
        toggleErrorSnackbar()
        setDisableSubmit(false)
      }
    }
    setDisableSubmit(false)
  }, [zustandSelectedCardItem, completCode])

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;

    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, setReutilizar, reutilizar, openDisclaimer]);

  const Fields = React.useCallback(() => {
    const tema = watchPreRegister(`pre_registro.0.tema`);
    const enteResponsavel = watchPreRegister(`pre_registro.${0}.enteResponsavel`);
    const titulo = watchPreRegister('pre_registro.0.titulo')

    return (
      <>
        <Grid item xs={12}>
          <TextFieldElaboration
            required={true}
            nameRef="titulo"
            label="Titulo do plano"
            tooltip={
              <p>
                Escreva um título claro e objetivo que reflita o propósito principal do seu plano.
                Para ver exemplos de títulos de planos no Interplan.Rio, <a href="https://web-interplanhom.apps.rio.gov.br/planos" target="_blank">clique aqui.</a>
              </p>
            }
            errors={errosPreRegister}
            commentTittle={titulo}
            name='pre_registro.0.titulo'
            register={registerPreRegister}
            placeholder="Digite o título do plano"
          />
          <div className="error_wrapper">
            <span>{getMessageErrorByNameRef(errosPreRegister, 'titulo')}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextFieldElaboration
            rows={2}
            required={true}
            maxLength={400}
            multiline={true}
            commentTittle={titulo}
            nameRef="descricao"
            label="Sobre o Plano"
            errors={errosPreRegister}
            register={registerPreRegister}
            name="pre_registro.0.descricao"
            tooltip={
              <p>
                Escreva um resumo breve que capture a essência e os principais pontos que o Plano apresenta. Como exemplo, consulte a descrição do PDS
                &nbsp;
                <a href="https://web-interplanhom.apps.rio.gov.br/plano/7" target="_blank">aqui.</a>
              </p>
            }
            placeholder="Digite uma breve descrição do plano"
          />
          <div className="error_wrapper">
            <span>{getMessageErrorByNameRef(errosPreRegister, 'descricao')}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          {fields.map((field, fieldIndex) => (
            <div key={fieldIndex}>
              {field.informacoesGerais.map((infoItem, infoIndex) => {
                const randomInfoId = Math.random();
                const fiel = watchPreRegister(`pre_registro.${fieldIndex}.estrutura.${infoIndex}`) as any

                return (
                  <React.Fragment key={randomInfoId}>
                    <div style={{ display: 'flex', alignItems: 'center' }} key={infoIndex}>
                      <TextFieldElaboration
                        required={infoIndex === 0}
                        errors={errosPreRegister}
                        multiline={infoIndex === 0}
                        commentTittle={titulo}
                        nameRef="informacoesGerais"
                        register={registerPreRegister}
                        rows={infoIndex === 0 ? 2 : 1}
                        tooltip={
                          <p>
                            Comece com uma introdução que apresente as informações
                            gerais do seu Plano. Em seguida, adicione os tópicos que deseja incluir,
                            como os principais objetivos ou diretrizes do plano por exemplo. Para ver um exemplo, &nbsp;
                            <a href="https://web-interplanhom.apps.rio.gov.br/plano/1" target="_blank">clique aqui.</a>
                          </p>
                        }
                        label={infoIndex === 0 ? "Informações Gerais" : undefined}
                        name={`pre_registro.${fieldIndex}.informacoesGerais.${infoIndex}`}
                        placeholder={
                          infoIndex === 0 ?
                            'Digite uma breve introdução para as informações gerais do plano' :
                            `Digite o tópico ${infoIndex + 1} das informações gerais`
                        }
                      />
                      {!fiel?.id && infoIndex != 0 && !preview && (
                        <div onClick={() => {
                          setOpenDeletInformacoesGeraisModal(true);
                          setDeleteInfoIndex(infoIndex)
                        }} style={{ marginTop: '-.5rem', display: 'flex', alignItems: 'center' }} className="delete-icon">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                          </svg>
                        </div>
                      )}
                    </div>
                    <DeleteElaborationDialog open={openDeleteInformacoesGeraisModal} setOpen={(e) => setOpenDeletInformacoesGeraisModal(e)} onDelete={() => {
                      const informacoesGerais = [...field.informacoesGerais].filter((item, index) => index !== deleteInfoIndex) as any;

                      updatePreRegister(fieldIndex, {
                        ...field,
                        informacoesGerais
                      })
                      setOpenDeletInformacoesGeraisModal(false);
                    }} />
                  </React.Fragment>
                );
              })}
              <div className="add_line_wrapper">
                <p onClick={() => addNewInformacoesGeraisField(fieldIndex)}>
                  {`+ Adicionar linha`}
                </p>
              </div>
            </div>
          ))}
          <div className="error_wrapper">
            <span>{getMessageErrorByNameRef(errosPreRegister, 'informacoesGerais')}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          {fields.map((field, fieldIndex) => (
            <div key={fieldIndex}>
              {field.estrutura.map((fiels, estrutIndex) => {
                const randomId = Math.random()
                const fiel = watchPreRegister(`pre_registro.${fieldIndex}.estrutura.${estrutIndex}`) as any
                return (
                  <React.Fragment key={randomId}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }} key={estrutIndex}>
                      <TextFieldElaboration
                        nameRef="estrutura"
                        errors={errosPreRegister}
                        required={estrutIndex === 0}
                        register={registerPreRegister}
                        commentTittle={titulo}
                        tooltip={
                          <p>
                            Insira a organização do seu Plano em tópicos claros,
                            destacando as seções principais. Cada tópico deve representar
                            uma parte importante da estrutura, como introdução, objetivos,
                            metas e ações. Certifique-se de que a ordem e a organização sejam
                            lógicas e fáceis de seguir. Para ver um exemplo, &nbsp;
                            <a href="https://web-interplanhom.apps.rio.gov.br/plano/1" target="_blank">clique aqui.</a>
                          </p>
                        }
                        label={estrutIndex === 0 ? "Estrutura" : undefined}
                        name={`pre_registro.${fieldIndex}.estrutura.${estrutIndex}`}
                        placeholder={`Digite o tópico ${estrutIndex + 1} da estrutura`}
                      />
                      {!fiel?.id && estrutIndex != 0 && (
                        <div onClick={() => {
                          setOpenDeleteStruturaModal(true);
                          setDeleteEstruturaIndex(estrutIndex)
                        }} style={{ marginTop: '-.5rem', display: 'flex', alignItems: 'center' }} className="delete-icon">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                          </svg>
                        </div>
                      )}
                    </div>
                    <DeleteElaborationDialog open={openDeleteEstruturaModal} setOpen={(e) => setOpenDeleteStruturaModal(e)} onDelete={() => {
                      const fields_estrutura = [...field.estrutura].filter((item, index) => index != deleteEstruturaIndex);
                      updatePreRegister(fieldIndex, {
                        ...field,
                        estrutura: fields_estrutura
                      });
                      setOpenDeleteStruturaModal(false);
                    }} />
                  </React.Fragment>
                );
              })}
              <div className="add_line_wrapper">
                <p onClick={() => addNewEstruturaField(fieldIndex)}>
                  {`+ Adicionar linha`}
                </p>
              </div>
            </div>
          ))}
          <div className="error_wrapper">
            <span>{getMessageErrorByNameRef(errosPreRegister, 'estrutura')}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <AutoCompleteElaboration
            label="Tema"
            nameRef="tema"
            required={true}
            commentTittle={titulo}
            tooltip={`Selecione ou insira o tema principal do seu Plano. 
            Para planos multitemáticos, escolha 'Transversal’. 
            Para adicionar um novo tema, selecione 'Adicionar novo tema' na 
            lista e use o modal para inseri-lo. Após salvar, escolha o 
            tema na lista suspensa.`
            }
            errors={errosPreRegister}
            defaultValue={String(tema)}
            name='pre_registro.0.tema'
            register={registerPreRegister}
            placeholder="Selecione ou digite um novo tema"
            setValue={(e) => addNewTheme(0, e.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectFieldElaboration
            label="Esfera governamental"
            required={true}
            commentTittle={titulo}
            errors={errosPreRegister}
            tooltip="Selecione se a abrangência do seu Plano é municipal, estadual ou federal."
            register={registerPreRegister}
            setValue={(value) => setValuePreRegister(`pre_registro.${0}.enteResponsavel`, value)}
            placeholder="Selecione a esfera"
            data={[
              { value: 1, name: 'Municipal' },
              { value: 2, name: 'Estadual' },
              { value: 3, name: 'Federal' },
            ]}
            defaultValue={String(enteResponsavel)}
            name={`pre_registro.${0}.enteResponsavel`}
          />
          <div className="error_wrapper">
            <span>{getMessageErrorByNameRef(errosPreRegister, 'enteResponsavel')}</span>
          </div>
        </Grid>
      </>
    );
  }, [fields, openDeleteEstruturaModal, openDeleteInformacoesGeraisModal, addNewEstruturaField, addNewInformacoesGeraisField, addNewTheme, preview, router])

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitPreRegister(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <p>Todos os campos marcados com asterisco (*) ao longo do preenchimento do Plano são obrigatórios.</p>
          </Grid>
          {ReplaceButton()}
          {Fields()}
          <Grid item xs={12}>
            <ActionButtonElaborations disable={disabeSubmit} layer_indicator={false} isOptional="true" />
          </Grid>
        </Grid>
      </Container>
      <ReutilizarElaborationDialog reuseData={data} title="Pré cadastro" open={reutilizar} setOpen={() => setReutilizar(false)} setValue={(e) => {
        setReutilizar(false);
        setValuePreRegister('pre_registro.0.tema', e[0]?.tema.id);
        setValuePreRegister('pre_registro.0.titulo', e[0]?.titulo);
        setValuePreRegister('pre_registro.0.descricao', e[0]?.descricao);
        setValuePreRegister('pre_registro.0.enteResponsavel', e[0]?.enteResponsavel.id);
        setValuePreRegister('pre_registro.0.estrutura', e[0]?.estruturas?.map((item: any) => item.descricao));
        setValuePreRegister('pre_registro.0.informacoesGerais', e[0]?.informacoesGerais?.map((item: any) => item.descricao));
      }} />
    </form>
  );
};
