import React from "react";
import _ from 'lodash';

interface IFuncProps {
  root: any;
  item: any;
  targetId: any;
  layerName: any;
}

interface IFuncDeleteProps {
  root: any;
  targetId: any;
  layerName: any;
}

interface ICreateInitialData {
  eixos_tematicos: {
    itens: any[];
  };
}

interface IHierarchyPreparationOfPlans {
  createInitialData: () => ICreateInitialData;
  ClearInitialData: (root: any, id: any) => void;
  findNewItemsById(firstArray: any[], id: any): any[];
  findNewItemsWithId(firstArray: any, secondArray: any): any;
  insertItemById: ({ item, layerName, root, targetId }: IFuncProps) => any;
  updateItemById: ({ item, layerName, root, targetId }: IFuncProps) => any;
  deleteItemById: ({ layerName, root, targetId }: IFuncDeleteProps) => any;
  checkLayerExists: ({ root, targetId, layerName }: IFuncDeleteProps) => boolean;
}

export const HierarchyPreparationOfPlansContext = React.createContext({} as IHierarchyPreparationOfPlans);

interface IHierarchyPreparationOfPlansProps {
  children: React.ReactNode;
}

export const HierarchyPreparationOfPlansProvider: React.FC<IHierarchyPreparationOfPlansProps> = ({ children }) => {
  const deepClone = (obj: any): any => {
    if (obj === null || typeof obj !== 'object') return obj;
    if (Array.isArray(obj)) return obj.map(deepClone);

    const clone: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        clone[key] = deepClone(obj[key]);
      }
    }
    return clone;
  };

  const traverseTree = (node: any, callback: any) => {
    if (!node || typeof node !== 'object') return;

    callback(node);

    for (let key in node) {
      if (Array.isArray(node[key])) {
        node[key].map((item: any) => traverseTree(item, callback));
      } else if (typeof node[key] === 'object') {
        traverseTree(node[key], callback);
      }
    }
  }

  const insertItemById = ({ item, layerName, root, targetId }: IFuncProps) => {
    if (!root || typeof root !== 'object' || !targetId || !item || !layerName) return root;

    const clonedRoot = deepClone(root); // Cria uma cópia profunda do root
    let idFound = false;

    // Percorre a árvore procurando pelo ID
    traverseTree(clonedRoot, (node: any) => {
      if (node.id === targetId && node.relacionamento && node.relacionamento[layerName]) {
        idFound = true;
        node.relacionamento[layerName].itens.push(item);
      }
    });

    // Se o ID não foi encontrado, cria a estrutura no clone
    if (!idFound) {
      let currentNode = clonedRoot;
      const keys = layerName.split('.');

      // Cria a estrutura se não existir
      for (let key of keys) {
        currentNode[key] = currentNode[key] || {};
        currentNode = currentNode[key];
      }

      // Adiciona o novo item
      currentNode.itens = currentNode.itens || [];
      currentNode.itens.push(item);
    }

    return clonedRoot; // Retorna o objeto atualizado
  };

  const updateItemById = ({ item, layerName, root, targetId }: IFuncProps) => {
    if (!root || typeof root !== 'object' || !targetId || !item || !layerName) {
      return root; // Retorna o objeto original se a validação falhar
    }

    const clonedRoot = deepClone(root); // Faz uma cópia profunda do root
    let idFound = false;

    // Percorre a árvore procurando pelo ID no objeto clonado
    traverseTree(clonedRoot, (node: any) => {
      if (node.id === targetId && node.relacionamento) {
        idFound = true;
        node.relacionamento = { ...node.relacionamento, ...item }; // Atualiza o relacionamento no clone
      }
    });

    if (!idFound) {
      console.log(`ID ${targetId} não encontrado para atualização.`);
    }

    return clonedRoot; // Retorna o objeto clonado com a modificação
  };

  const deleteItemById = ({ layerName, root, targetId }: IFuncDeleteProps) => {
    if (!root || typeof root !== 'object' || !targetId || !layerName) {
      return root; // Retorna o objeto original se a validação falhar
    }

    const clonedRoot = deepClone(root); // Faz uma cópia profunda do root
    let idDeleted = false;

    // Percorre a árvore no objeto clonado
    traverseTree(clonedRoot, (node: any) => {
      if (node.relacionamento && node.relacionamento[layerName]) {
        const index = node.relacionamento[layerName].itens.findIndex((item: any) => item.id === targetId);
        if (index !== -1) {
          node.relacionamento[layerName].itens.splice(index, 1); // Remove o item no clone
          idDeleted = true;
          return true; // Retorna true para interromper a iteração
        }
      }
    });

    if (!idDeleted) {
      console.log(`ID ${targetId} não encontrado na camada ${layerName} para deleção.`);
    }

    return clonedRoot; // Retorna o objeto clonado com a modificação
  };

  const createInitialData = () => {
    return {
      eixos_tematicos: {
        itens: []
      }
    };
  };

  const ClearInitialData = (root: any, id: any) => {
    const items = root.eixos_tematicos.itens.filter((item: any) => item.id != id);
    root.eixos_tematicos.itens = items;
    return root;
  };

  function findNewItemsById(firstArray: any[], id: any) {
    return firstArray.filter(item => item.id == id);
  }

  function findNewItemsWithId(firstArray: any, secondArray: any) {
    return secondArray.filter((secondItem: any) => {
      const firstItem = firstArray.find((firstItem: any) => firstItem.texto === secondItem.texto);
      return firstItem && !firstItem.id && secondItem.id;
    });
  }

  const checkLayerExists = ({ root, targetId, layerName }: IFuncDeleteProps) => {
    if (!root || typeof root !== 'object' || !targetId || !layerName) return false;

    let layerExists = false;

    // Percorre a árvore procurando pelo ID e verificando o relacionamento
    traverseTree(root, (node: any) => {
      if (node.id === targetId && node.relacionamento && node.relacionamento[layerName]) {
        layerExists = true;
      }
    });

    return layerExists;
  };


  const value = {
    deleteItemById,
    insertItemById,
    updateItemById,
    findNewItemsById,
    checkLayerExists,
    ClearInitialData,
    createInitialData,
    findNewItemsWithId,
  } as IHierarchyPreparationOfPlans;

  return <HierarchyPreparationOfPlansContext.Provider value={value}>{children}</HierarchyPreparationOfPlansContext.Provider>;
};