import React, { useEffect, useState } from "react";
import { Grid, Button, Divider } from '@mui/material';
import { MainContainer } from './styles';
import { ActionButtonElaborations } from "../../../ActionButton";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { PlansService } from "@/services/endpoints/plans";
import { useRouter } from "next/router";
import { Loading } from "@/components/global/Loading";
import { PaginationItr } from "@/components/global/Pagination";



interface IProps {
  plans?: any[],
  type?: string
}

export const Similarity: React.FC<IProps> = ({ plans, type }) => {

  const router = useRouter();
  const { GetGoals, GetGoalsSimilar, GetMacroactions, GetMacroactionsSimilar, GetObjetivos, GetObjetivoSimilar } = new PlansService()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem } = usePreparationOfPlans();

  const [loading, setLoading] = useState<boolean>(false);
  const [goalSimilar, setGoalSimilar] = useState<any>([]);
  const [goalsPage, setGoalsPage] = useState(0);
  const [goalsTotalPage, setGoalsTotalPage] = useState(10);

  const handleAnalyzeSimilarity = () => {
    setLoading(true)

    let paginate = goalsPage
    if(paginate == 0) paginate = paginate + 1

    switch (type) {
      case 'goals':
        GetGoals(router.query.id, paginate, 3)
          .then(({ data }: any) => {
            setGoalsPage(data.currentPage)
            setGoalsTotalPage(data.totalPages)
            handleSimilar(data.data)
          })
        break;

      case 'macroacoes':
        if (router.query.id)
          GetMacroactions(router.query.id, paginate, 3)
            .then(({ data }: any) => {
              setGoalsPage(data.currentPage)
              setGoalsTotalPage(data.totalPages)
              handleSimilar(data.data)
            })
        break;

      case 'objetivos':
        if (router.query.id)
          GetObjetivos(router.query.id, paginate, 3)
            .then(({ data }: any) => {
              setGoalsPage(data.currentPage)
              setGoalsTotalPage(data.totalPages)
              handleSimilar(data.data)
            })
        break;

      default:
        setLoading(false)

        break;
    }


  };

  const handleSimilar = async (data: any) => {

    let similar: any = []

    for (let item of data) {
      let ItemData = {
        title: '',
        description: '',
        planos: []
      }

      switch (type) {
        case 'goals':

          ItemData = {
            title: `Meta `,
            description: item.texto,
            planos: []
          }

          try {
            const goalData : any = await GetGoalsSimilar(item.id)
            ItemData.planos = goalData.data.sort((a: any, b: any) => b.similaridade - a.similaridade);
          } catch (error) {
            console.error(error)
          }
          similar.push(ItemData)
          break;

        case 'macroacoes':

          ItemData = {
            title: `Macroação `,
            description: item.texto,
            planos: []
          }

          try {
            const macroacionData: any = await GetMacroactionsSimilar(item.id)
            ItemData.planos = macroacionData.data.sort((a: any, b: any) => b.similaridade - a.similaridade);
          } catch (error) {
            console.error(error)
          }
          similar.push(ItemData)
          break;

        case 'objetivos':

          ItemData = {
            title: `Objetivo`,
            description: item.texto,
            planos: []
          }

          try {
            const objetivoData : any = await GetObjetivoSimilar(item.id)
            ItemData.planos = objetivoData.data.sort((a: any, b: any) => b.similaridade - a.similaridade);
          } catch (error) {
            console.error(error)
          }
          similar.push(ItemData)
          break;

        default:
          setLoading(false)
          break;
      }
    }

    setGoalSimilar(similar)
    setLoading(false)
  }


  useEffect(() => {
    if(goalsPage > 1){
      handleAnalyzeSimilarity()
    }
  }, [goalsPage])

  return (
    <MainContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" disabled={loading} onClick={handleAnalyzeSimilarity}>
            Analisar similaridade
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <GoalSimilarCards goalSimilar={goalSimilar} loading={loading} />
        </Grid>
        <Grid>
          <PaginationItr
            currentPage={goalsPage}
            totalPages={goalsTotalPage}
            setPage={setGoalsPage}
            dataSize={goalSimilar.length}
          />
        </Grid>
        <Grid item xs={12}>
          <ActionButtonElaborations onClick={() => {
            const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
            const id = plan[selectedItemsMenu?.layer as string]
              ?.find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
            handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true })
          }} isOptional="false" />
        </Grid>
      </Grid>
    </MainContainer>
  );
};


const GoalSimilarCards = ({ goalSimilar = [], loading = false }: { goalSimilar: any, loading: boolean }) => {

  const [visiblePlans, setVisiblePlans] = useState(goalSimilar.map(() => 2));

  const handleLoadMore = (index: any) => {
    setVisiblePlans((prevVisiblePlans: any) => {
      const newVisiblePlans = [...prevVisiblePlans];
      newVisiblePlans[index] = newVisiblePlans[index] + 2;
      return newVisiblePlans;
    });
  };

  const handlePlanos = (item: any, index: number) => {

    if (item.planos == undefined) return
    if(!Array.isArray(item.planos)) return
    if (item.planos.length == 0) return


    return (
      <>
        {item.planos.slice(0, visiblePlans[index]).map((plano: any, planoIndex: any) => (
          <div key={planoIndex} className="plan_wrapper">
            <div className="plan_title_wrapper">
              <p>{`${plano.title ?? plano.titulo_plano ?? ''} | ${plano.startDate ?? plano.ano_inicial_vigencia ?? 'Sem data de inicio'} - ${plano.endDate ?? plano.ano_final_vigencia ?? 'Sem data Final'} | ${plano.orgao ?? plano.orgao_plano ?? '-'}`}</p>
            </div>
            <div className="plan_content_wrapper">
              <p>{plano.description ?? plano.texto ?? ''}</p>
            </div>
          </div>
        ))}
        {visiblePlans[index] < item.planos.length && (
          <div className="load_more">
            <p onClick={() => handleLoadMore(index)}>
              Carregar mais
            </p>
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    setVisiblePlans(goalSimilar.map(() => 2))
  }, [goalSimilar])

  if (loading) {
    return (
      <Loading loading={loading} />
    )
  }


  return (
    <div className="goals_wrapper">
      {goalSimilar.map((item: any, index: number) => (
        <React.Fragment key={index}>
          <span>{item.title ?? ''}</span>
          <p>{item.description ?? ''}</p>

          {handlePlanos(item, index)}

          <Divider />
        </React.Fragment>
      ))}
    </div>
  )
}