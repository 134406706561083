export const Svg_012 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={58}
    height={58}
    fill="none"
  >
    <path
      fill="url(#a)"
      d="M13.832 58h22.685a6.433 6.433 0 0 0 6.38-5.664H7.453A6.433 6.433 0 0 0 13.832 58Z"
    />
    <path
      fill="url(#b)"
      d="M26.874 43.84h-3.398V6.23h3.398v2.832h16.067V6.424A6.43 6.43 0 0 0 36.518 0H13.832A6.43 6.43 0 0 0 7.41 6.423v42.514h35.532v-7.93H26.874v2.833Z"
    />
    <path
      fill="url(#c)"
      d="M45.206 14.044c0 2.096 1.706 3.801 3.801 3.801h1.584v-5.384h-5.385v1.583Z"
    />
    <path
      fill="url(#d)"
      d="M45.206 36.026v1.583h5.385v-5.384h-1.584a3.805 3.805 0 0 0-3.8 3.8Z"
    />
    <path
      fill="url(#e)"
      d="M49.007 21.244c-3.97 0-7.199-3.23-7.199-7.2v-1.583H26.874v25.148h14.934v-1.583c0-3.97 3.23-7.2 7.2-7.2h1.583v-7.582h-1.584Zm-13.198 5.49H32.41v-3.398h3.399v3.398Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={15.65}
        x2={16.238}
        y1={53.185}
        y2={59.144}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={15.626}
        x2={41.283}
        y1={7.341}
        y2={37.49}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={46.452}
        x2={49.009}
        y1={13.269}
        y2={17.408}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={46.452}
        x2={49.009}
        y1={33.033}
        y2={37.172}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={32.359}
        x2={44.605}
        y1={16.233}
        y2={34.924}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
    </defs>
  </svg>
)
