import dayjs from "dayjs";
import * as Yup from 'yup';
import { Grid } from '@mui/material'
import { Container } from './styled';
import { useRouter } from "next/router";
import { TrashIcon } from "../Activities/trash";
import { LoadingBuffer } from "../utils/Loading";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconesModal } from "./components/IconesModal";
import { PlansService } from "@/services/endpoints/plans";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { FileFieldElaboration } from "../../Inputs/FileInput";
import { ActionButtonElaborations } from "../../ActionButton";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

type Inputs = {
  link?: string;
  link_foto?: any,
  palavras: any[],
  ato_final?: any,
  capa_plano: any,
  is_optional: any,
  icone_plano?: any,
  ato_inicial?: any,
  resumo_plano: any,
  completo_plano: any,
  ato_normativo?: string,
}

const Schema = Yup.object().shape({
  link_foto: Yup.string(),
  ato_final: Yup.string(),
  ato_inicial: Yup.string(),
  icone_plano: Yup.string(),
  link: Yup.string(),
  palavras: Yup.array().required(''),
  capa_plano: Yup.string().required(),
  is_optional: Yup.string().required(),
  resumo_plano: Yup.string().required(),
  ato_normativo: Yup.string(),
  completo_plano: Yup.string().required(),
});

const getFullYear = (dateString: string) => {

  if (dateString == '') return '0000'

  let date = new Date(dateString);
  let year = date.getFullYear();

  return year
}

const palabrasChave = (palavras: any[]) => {

  let palavrasArray = ""

  palavras.forEach(el => {
    const word = el.palavras.trim()
    if (word !== '' && word.length > 0) {
      palavrasArray = `${word}, ${palavrasArray}`
    }
  });

  return palavrasArray
}


export const FinalDataForm: React.FC = () => {
  const {
    watch,
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue: setValueDados,
  } = useForm<Inputs>({ resolver: yupResolver(Schema), defaultValues: { palavras: [{ palavra: '' }] } });

  const { fields, append, remove: removePalavras } = useFieldArray({
    control,
    name: "palavras"
  });

  const isOptional = watch("is_optional");

  const router = useRouter();
  const { PostFinal, getFinal } = new PlansService();
  const [loading, setLoading] = React.useState(false);
  const [ato_final, set_ato_final] = useState<any>('');
  const [ato_inicial, set_ato_inicial] = useState<any>('');
  const [capa_plano, set_capa_plano] = useState<string>('');
  const [icone_plano, set_icone_plano] = useState<string>('');
  const [resumo_plano, set_resumo_plano] = useState<string>('');
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [completo_plano, set_completo_plano] = useState<string>('');
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, setDefaultItemMenuId, setNavigable, handleFetchProgressCodePlanId } = usePreparationOfPlans();


  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const capaPlano = document.getElementById('file-inputcapa_plano') as HTMLInputElement;
    const resumoPlano = document.getElementById('file-inputresumo_plano') as HTMLInputElement;
    const incompletoPlano = document.getElementById('file-inputcompleto_plano') as HTMLInputElement;

    if (disabeSubmit) return;

    setDisableSubmit(true);

    try {
      const formData = new FormData();

      if (capaPlano?.files?.[0]) {
        formData.append('capa_plano', capaPlano.files[0]);
      }

      if (incompletoPlano?.files?.[0]) {
        formData.append('completo_plano', incompletoPlano.files[0]);
      }

      if (resumoPlano?.files?.[0]) {
        formData.append('resumo_plano', resumoPlano.files[0]);
      }

      const icone = getValues('icone_plano');
      if (icone != undefined) {
        formData.append('icone_plano', icone);
      }
      formData.append('nomeAtoNormativo', data.ato_normativo);
      formData.append('linkAtoNormativo', data.link);
      formData.append('linkCapa', data.link_foto);
      formData.append('palavrasChaves', palabrasChave(data.palavras));
      formData.append('atoInicial', getFullYear(data.ato_inicial).toString());
      formData.append('atoFinal', getFullYear(data.ato_final).toString());

      await PostFinal(formData, router.query.id);

      toggleSuccessSnackbar();
      GetFinalDados();

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true);

        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        if (plan && plan[selectedItemsMenu?.layer as string]) {
          const planId = plan[selectedItemsMenu?.layer as string]
            .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
          setDefaultItemMenuId(planId);
        }
      } else {
        setNavigable(false);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        if (plan && plan[selectedItemsMenu?.layer as string]) {

          const planId = plan[selectedItemsMenu?.layer as string]
            .find((item: any) => item.id == selectedItemsMenu?.id).id;
          setDefaultItemMenuId(planId);

          router.push('/dashboard/elaboracao_planos/' + router.query.id);
          handleFetchProgressCodePlanId(router.query.id);
        }
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000);

    } catch (error) {
      toggleErrorSnackbar();
    }
  };

  const GetFinalDados = () => {
    setLoading(true);
    getFinal(router.query.id)
      .then(({ data }: any) => {
        setValueDados('is_optional', 'true')
        setValueDados('link', data.linkAtoNormativo)
        setValueDados('link_foto', data.linkCapa)
        setValueDados('ato_normativo', data.nomeAtoNormativo)

        if (data.atoFinal) set_ato_final(dayjs(`Mon, 16 Aug ${data.atoFinal} 03:00:00 GMT`))
        if (data.atoInicial) set_ato_inicial(dayjs(`Mon, 16 Aug ${data.atoInicial} 03:00:00 GMT`))

        set_capa_plano(data.capa)
        set_icone_plano(data.iconeDoPlano)
        set_completo_plano(data.arquivoDoPlano)
        set_resumo_plano(data.resumoExecutivo)

        const palabras = data.palavrasChaves?.split(',')

        let index = 0
        removePalavras()

        if (palabras && palabras.length > 0) {
          palabras?.forEach((element: string) => {
            const word = element.trim()
            if (word !== '') {
              append({ palavra: '' })
              setValueDados(`palavras.${index}.palavras`, word)
              index++
            }
          });
        } else {
          append({ palavra: '' })
        }

      }).finally(() => {
        setLoading(false)
        setDisableSubmit(false);

      });

    setDisableSubmit(false);
  }

  const palavrasWrapper = () => {
    return <>
      {fields.map((field, index) => (
        <Grid item xs={12} key={field.id}>
          <div className="delete-icon" style={{ display: 'flex', placeItems: 'end', gap: 5 }}>
            <TextFieldElaboration
              required={true}
              errors={errors}
              register={register}
              tooltip="Digite 10 palavras-chave que ajudem a encontrar seu plano na ferramenta de busca. Certifique-se de que esses termos apareçam no PDF original do plano"
              name={`palavras.${index}.palavras`}
              placeholder={`Digite a palavra-chave ${index + 1}`}
              label={index == 0 ? "Palavras-chaves (máx.10)" : ''}
            />
            {index != 0 &&
              <a className="delete-icon" onClick={() => removePalavras(index)}>  <TrashIcon /></a>
            }
          </div>
        </Grid>
      ))}
      {fields.length < 10 && (
        <Grid item xs={12}>
          <div className="add_line_wrapper">
            <p onClick={() => append({ palavra: '' })}>
              {`+ Adicionar palavra chave`}
            </p>
          </div>
        </Grid>
      )}
    </>
  }

  useEffect(() => {
    if (router.query.id && (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos'))) {
      GetFinalDados();
    };
  }, [router.query, router.pathname]);

  const IconField = React.useCallback(() => {
    return (
      <Grid item xs={isOptional == 'true' ? 4 : 6} mt={1}>
        <IconesModal
          errors={errors}
          required={true}
          accept="image/*"
          name="icone_plano"
          register={register}
          getDados={GetFinalDados}
          downloadName={icone_plano}
          label="Selecionar ícone do plano"
          setValue={(e) => setValueDados('icone_plano', e)}
        />
      </Grid>
    )
  }, [isOptional, errors, register, GetFinalDados, icone_plano])


  if (loading) {
    return <LoadingBuffer />
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ marginBottom: 10 }} >
            <DatePickerElaboration
              required={false}
              errors={errors}
              views={['year']}
              control={control}
              name="ato_inicial"
              defaultValue={ato_inicial}
              label="Ano inicial de vigência"
              tooltip="Selecione a data inicial de vigência do seu plano, se já estiver disponível. Se o Plano ainda não foi publicado, essas informações podem ser adicionadas posteriormente pelo usuário administrador.​ "
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <DatePickerElaboration
              required={false}
              errors={errors}
              name="ato_final"
              views={['year']}
              control={control}
              defaultValue={ato_final}
              label="Ano final de vigência"
              tooltip="Selecione a data final de vigência do seu plano, se já estiver disponível. Se o Plano ainda não foi publicado, essas informações podem ser adicionadas posteriormente pelo usuário administrador, deixando o AAAA."
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElaboration
              required={false}
              errors={errors}
              register={register}
              name="ato_normativo"
              label="Ato normativo e link"
              placeholder={`Digite o ato normativo de publicação do plano caso exista`}
              tooltip="Digite o ato normativo e o link de sua publicação. Se o Plano ainda não foi publicado, essas informações podem ser adicionadas posteriormente pelo usuário administrador"
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElaboration
              name='link'
              required={false}
              errors={errors}
              register={register}
              placeholder={`Insira o link do ato normativo de publicação do plano caso exista`}
            />
          </Grid>

          {palavrasWrapper()}
          <Grid item xs={12} sx={{ mt: 4 }}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={6}>
            <FileFieldElaboration
              errors={errors}
              required={true}
              accept="image/*"
              name="capa_plano"
              register={register}
              getDados={GetFinalDados}
              downloadName={capa_plano}
              labelLinkLabel="Banco de imagens"
              labelLink="https://flickr.com/photos/riotur/"
              label="Carregar foto do banco de imagens da prefeitura"
              tooltip="Carregar foto do banco de imagens da prefeitura (Link do Banco de imagens: https://flickr.com/photos/riotur/)"
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldElaboration
              required={true}
              errors={errors}
              name='link_foto'
              register={register}
              label="Link da foto"
              placeholder={`Cole o link da foto do banco de imagens da prefeitura`}
            />
          </Grid>
          {IconField()}
          {isOptional == 'true' && (
            <Grid item xs={isOptional == 'true' ? 4 : 0} mt={1}>
              <FileFieldElaboration
                accept=".pdf"
                errors={errors}
                required={true}
                register={register}
                name="completo_plano"
                getDados={GetFinalDados}
                downloadName={completo_plano}
                label="Arquivo completo do plano"
              />

            </Grid>
          )}
          <Grid item xs={isOptional == 'true' ? 4 : 6} mt={1}>
            <FileFieldElaboration
              accept=".pdf"
              errors={errors}
              required={false}
              name="resumo_plano"
              register={register}
              getDados={GetFinalDados}
              downloadName={resumo_plano}
              label="Arquivo do resumo executivo"
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <RadioCheckedElaboration
              errors={errors}
              required={false}
              control={control}
              name="is_optional"
              defaultValue={isOptional == 'true' ? 'true' : 'false'}
              label="Deseja incluir o plano na íntegra e seu resumo executivo na área externa?"
              data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não (incluir apenas o resumo executivo)' }]}
            />
          </Grid>
          <Grid item xs={12}>
            <ActionButtonElaborations
              disable={disabeSubmit}
              layer_indicator={selectedItemsMenu?.demostration_layer}
              isOptional={'true'}
              onClick={() => {
                if (isOptional == 'false' || isOptional == undefined) {
                  setNavigable(true)

                  router.push('/dashboard/elaboracao_planos/' + router.query.id);

                  const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
                  if (plan && plan[selectedItemsMenu?.layer as string]) {
                    const planId = plan[selectedItemsMenu?.layer as string]
                      .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
                    setDefaultItemMenuId(planId);
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </form>
  )
}
