import React from "react"
import * as Yup from 'yup';
import { Container } from './styled';
import { useRouter } from "next/router";
import { Grid, Pagination } from '@mui/material';
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { clearIdToArray } from "@/utils/elaborationParseData";
import { ActionButtonElaborations } from "../../ActionButton";
import { Organograma } from "@/utils/organograma/Organograma";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { addItemToNodeOrganogram } from "@/utils/organograma/addItemToNode";
import { RelacionamentoJsonService } from "@/services/endpoints/hierarquia";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { useHierarchyPreparationOfPlans, usePreparationOfPlans } from "@/hooks/elaboracao_planos";

type InputsObjectives = {
  label?: any;
  objetivos: any;
};

const SchemaObjectives = Yup.object().shape({
  objetivos: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Objetivo obrigatório.')
    })
  )
});

const defaultValue = {
  id: '',
  texto: '',
}

export const ObjectivesForm: React.FC = () => {
  const {
    trigger,
    clearErrors,
    watch: watchObjectives,
    control: controlObjectives,
    register: registerObjectives,
    setValue: setValueObjectives,
    handleSubmit: handleSubmitObjectives,
    formState: { errors: errosObjectives } } = useForm<InputsObjectives>({
      resolver: yupResolver(SchemaObjectives) as any,
      defaultValues: { objetivos: [defaultValue] }
    });

  const { append: appendObjectives, remove: removeObjectives } = useFieldArray({
    name: "objetivos",
    control: controlObjectives,
  });

  const fields = watchObjectives('objetivos');

  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const { completCode } = usePreparationOfPlans();
  const [data, setData] = React.useState<any[]>([]);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [loading, setLoading] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [dataAxis, setDataAxis] = React.useState<any[]>([]);
  const [deleteFieldId, setDeleteFieldId] = React.useState(0);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [deleteFieldIndex, setDeleteFieldIndex] = React.useState(0);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const { GetThematicAxisElaboration, GetEstrategyElaboration, GetGuideLinesElaboration } = new ElaborationService();
  const { ObjectivesElaboration, GetObjectivesElaboration, DeleteObjectivesElaborations } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId } = usePreparationOfPlans();

  const total = dataAxis.length;

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.objetivos?.find((item: any) => item[title]);
    return foundObject ? foundObject[title].message : null;
  }

  const handleChange = async (_: any, value: any) => {
    if (typeof _ == 'string') {
      setPage(value);
      return;
    }
    const isValid = await trigger();

    if (isValid) {
      handleSubmitObjectives(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setPage(value);
  };

  const handleLeftClick = async () => {
    handleChange('', 1)
    const isValid = await trigger();

    if (isValid) {
      handleSubmitObjectives(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setCurrentIndexEixo(currentIndexEixo === 0 ? eixos_tematicos.length - 1 : currentIndexEixo - 1);
  };

  const handleRightClick = async () => {
    handleChange('', 1)
    const isValid = await trigger();

    if (isValid) {
      handleSubmitObjectives(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setCurrentIndexEixo(currentIndexEixo === eixos_tematicos.length - 1 ? 0 : currentIndexEixo + 1);
  };

  const empty = () => {
    if (zustandSelectedCardItem.title != 'Plano Institucional') {
      if (dataAxis.length == 0) return
    } else {
      if (isEmpty) return true;
    }
  }

  React.useEffect(() => {
    if (router.query.id && router.pathname.split("/").includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos")) {
      GetThematicAxisElaboration(router.query.id).then(res => {
        setEixosTematicos(res.data);
      })
    }
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes("aprovacao_planos")) {
      setLoading(true)
      const typeOfPlans = zustandSelectedCardItem.title
      const estrategia = completCode.some((item: any) => item.code == 'estrategia' && item.skipped == false);
      const diretrizes = completCode.some((item: any) => item.code == 'diretrizes' && item.skipped == false);
      const eixo_tematico = completCode.some((item: any) => item.code == 'eixos_tematicos' && item.skipped == false);

      if (typeOfPlans == "Plano de Estado" || typeOfPlans == "Plano Setorial") {
        if (estrategia && eixo_tematico) {
          setLabel('Estratégia');
          GetEstrategyElaboration(router.query.id).then((res: any) => {
            const data = res.data as any[];
            const arr: any[] = [];
            data.filter(item => item.eixoTematico == eixos_tematicos[currentIndexEixo]?.id)
              .map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));
            setDataAxis(arr);

          }).finally(() => setLoading(false));
        };

        if (!estrategia && eixo_tematico) {
          setLabel('Eixo Temático')
          GetThematicAxisElaboration(router.query.id).then(res => {
            const data = res.data as any[];
            const arr: any[] = [];

            data.map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));
            setDataAxis(arr);
          }).finally(() => setLoading(false));;
        };

      }

      if (typeOfPlans == "Plano de Gestão" || typeOfPlans == "Plano Orçamentário") {
        setLabel('Diretriz')

        if (diretrizes && eixo_tematico) {
          GetGuideLinesElaboration(router.query.id).then(res => {
            const data = res.data as any[];
            const arr: any[] = [];
            data.filter(item => item.eixoTematico == eixos_tematicos[currentIndexEixo]?.id)
              .map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));

            setDataAxis(arr);

          }).finally(() => setLoading(false));
        }

        if (!diretrizes && eixo_tematico) {
          setLabel('Eixo Temático')

          GetThematicAxisElaboration(router.query.id).then(res => {
            const data = res.data as any[];
            const arr: any[] = [];
            data.filter(item => item.id == eixos_tematicos[currentIndexEixo]?.id).map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));
            setDataAxis(arr);
            const item = data.filter(item => item.id == eixos_tematicos[currentIndexEixo]?.id);
            if (item.length == 0) {
              setIsEmpty(false);
            }
          }).finally(() => setLoading(false));;
        }
      }

      if (typeOfPlans == "Plano Institucional") {
        setLabel('Eixo Temático')

        GetThematicAxisElaboration(router.query.id).then(res => {
          const data = res.data as any[];
          const arr: any[] = [];
          data.filter(item => item.id == eixos_tematicos[currentIndexEixo]?.id).map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));
          setDataAxis(arr);
          const item = data.filter(item => item.id == eixos_tematicos[currentIndexEixo]?.id);

          if (item.length == 0) {
            setIsEmpty(false);
          }
        }).finally(() => setLoading(false));;
      }

    }
  }, [completCode, zustandSelectedCardItem, router, eixos_tematicos, currentIndexEixo]);

  React.useEffect(() => {
    const d = label == 'Eixo Temático' ? dataAxis[currentIndexEixo] : dataAxis[page - 1] as any;
    clearErrors()
    if (d?.objetivos?.length > 0) {
      setValueObjectives('objetivos', d?.objetivos)
    } else {
      setValueObjectives('objetivos', [defaultValue])
    }
  }, [dataAxis, page, completCode]);

  React.useEffect(() => {
    if (planId && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos') && tipoFluxo == 'substituir') {
      GetObjectivesElaboration(planId).then(res => {
        setData(res.data);
      })
    }
  }, [planId, router.pathname]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);

  const { consultarOrganograma, salvarOrganograma, startOrganograma } = new Organograma(router.query.id as unknown as number);

  const { insertItemById, findNewItemsWithId, updateItemById, deleteItemById, checkLayerExists } = useHierarchyPreparationOfPlans();
  const { startRelacionamento, consultarRelacionamento, atualizarRelacionamento } = new RelacionamentoJsonService(router.query.id as unknown as number);


  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { objetivos } = data;
    const objetivoCode = completCode.find(item => item.code == 'objetivos');

    if (disabeSubmit) return;

    setDisableSubmit(true);

    let submittedData = objetivos;
    if (event?.data?.length > 0) {
      submittedData = event.data;
    }

    let label_eixo = '';
    if (label === 'Eixo Temático') {
      label_eixo = 'eixo_tematico_id';
    } else if (label === 'Diretriz') {
      label_eixo = 'diretrizes_id';
    } else if (label === 'Estratégia') {
      label_eixo = 'estrategia_id';
    }

    try {
      const formattedData = submittedData?.map((item: any) => ({
        ...item,
        [label_eixo]: dataAxis[page - 1]?.value,
        eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
        eixo_tematico_id: eixos_tematicos[currentIndexEixo]?.id,
      }));

      const res = await ObjectivesElaboration({
        data: { itens: clearIdToArray(formattedData) },
        id: Number(router.query.id),
      });

      const response = res.data as any[];
      const newArray = findNewItemsWithId(objetivos, response);

      let layerName = '';
      let prop = ''
      if (label == 'Eixo Temático') {
        layerName = 'eixos_tematicos'
        prop = 'eixoTematico'
      }

      if (label == 'Diretriz') {
        layerName = 'diretrizes'
        prop = 'diretrizes'
      }

      if (label == 'Estratégia') {
        layerName = 'estrategias'
        prop = 'estrategias'

      }

      const targetId = (lab: any) => {
        if (lab == 'Eixo Temático') {
          return eixos_tematicos[currentIndexEixo]?.id
        } else {
          return eixos_tematicos[currentIndexEixo][prop][page - 1]?.id
        }
      }

      const relacionamentoCheck = await consultarRelacionamento();
      const rootCheck = JSON.parse(JSON.parse(relacionamentoCheck));
      const checklayer = checkLayerExists({ root: rootCheck, layerName: 'metas', targetId: targetId(label) });

      if (!checklayer) {
        for (const item of newArray) {
          const relacionamento = await consultarRelacionamento();
          const root = JSON.parse(JSON.parse(relacionamento));

          const newInsertItem = updateItemById({
            root,
            targetId: dataAxis[page - 1]?.value,
            item: { objetivos: { itens: [] } },
            layerName,
          });

          startRelacionamento(JSON.stringify(newInsertItem));
          await atualizarRelacionamento(); // Aguarda o término da atualização
        }
      }

      for (const item of newArray) {
        const relacionamento = await consultarRelacionamento();
        const root = JSON.parse(JSON.parse(relacionamento));

        const newInsertItem = insertItemById({
          root,
          targetId: dataAxis[page - 1]?.value,
          item: { id: item.id, relacionamento: {} },
          layerName: 'objetivos',
        });

        startRelacionamento(JSON.stringify(newInsertItem));
        await atualizarRelacionamento(); // Aguarda o término da atualização
      }

      const data = res.data as any[];
      data.map((item, index) => setValueObjectives(`objetivos.${index}.id`, item))

      toggleSuccessSnackbar();

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id === selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
        handleFetchProgressCodePlanId(router.query.id);

      } else {
        setNavigable(false);
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id === selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id);
        handleFetchProgressCodePlanId(router.query.id);

      }
      setTimeout(() => toggleSuccessSnackbar(), 3000);
      setDisableSubmit(false);

    } catch (error: any) {
      toggleErrorSnackbar();
      setDisableSubmit(false);
    }

    if (objetivoCode != undefined) return;

    try {
      const response = await consultarOrganograma();
      const organograma = response.Organograma;

      const newItem = {
        "name": "Objetivo",
        "position": "",
        "manager": label,
        "tooltip": "",
        "subordinates": []
      };

      const newOrganograma = addItemToNodeOrganogram(organograma, label, newItem);

      startOrganograma(newOrganograma);
      salvarOrganograma();

    } catch {
      toggleErrorSnackbar('Organograma', 'Erro ao salvar o organograma.');
    }
  };

  const Fields = React.useCallback(() => {
    if (zustandSelectedCardItem.title != 'Plano Institucional') {
    } else {
      if (isEmpty) return null;
    }

    return (
      <>
        {fields.map((field: any, fieldIndex: any) => {
          const texto = watchObjectives(`objetivos.${fieldIndex}.texto`);
          const randomId = Math.random()
          return (
            <React.Fragment key={randomId}>
              <input
                type="hidden"
                defaultValue={field.id}
                {...registerObjectives(`objetivos.${fieldIndex}.id`, { value: field.id })}
              />
              <Grid display='flex' alignItems='center' gap='8px' item xs={12}>
                <TextFieldElaboration
                  rows={3}
                  required={false}
                  multiline={true}
                  defaultValue={texto}
                  errors={errosObjectives}
                  register={registerObjectives}
                  placeholder="Digite o objetivo"
                  name={`objetivos.${fieldIndex}.texto`}
                  label={fieldIndex == 0 ? 'Objetivos' : ''}
                />
                {fieldIndex != 0 && (
                  <div
                    className="delete-icon"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteFieldIndex(fieldIndex);
                      setDeleteFieldId(field.id)
                    }} style={{ display: 'flex', alignItems: 'center' }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                    </svg>
                  </div>
                )}
              </Grid>
              <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
                <span>{getMessageErrorByNameRef(errosObjectives, 'texto')}</span>
              </div>
              <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={async () => {
                removeObjectives(deleteFieldIndex)
                setOpenDeleteModal(false);

                if (deleteFieldId != 0) {
                  const relacionamento = await consultarRelacionamento();
                  const root = JSON.parse(JSON.parse(relacionamento));

                  const newItem = deleteItemById({ root, layerName: 'objetivo', targetId: deleteFieldId });

                  startRelacionamento(JSON.stringify(newItem));
                  atualizarRelacionamento();

                  DeleteObjectivesElaborations(deleteFieldId)
                  setDeleteFieldId(0)
                }
              }} />
            </React.Fragment>
          );
        })}
        {dataAxis[page - 1]?.name.length > 0 && (
          <Grid item xs={6}>
            <div className="add_line_wrapper">
              <p onClick={() => appendObjectives({ texto: '', id: '' })}>
                {`+ Adicionar linha`}
              </p>
            </div>
          </Grid>

        )}
      </>
    );
  }, [fields, openDeleteModal, watchObjectives, registerObjectives, errosObjectives, removeObjectives, appendObjectives, watchObjectives, dataAxis, label]);

  const ThematicAxisBanner = React.useCallback(() => {
    return (
      <>
        <Grid item xs={12} className="goals_select_content_wrapper">
          <p>Eixo temático</p>
        </Grid>
        <Grid item xs={12}>
          <div className="goals_select_wrapper">
            <div className="goals_left_arrow" onClick={handleLeftClick}>{"<"}</div>
            <div className="goals_select_content_wrapper">
              <p>{eixos_tematicos[currentIndexEixo]?.texto}</p>
              <span>Eixo temático {currentIndexEixo + 1} de {eixos_tematicos.length}</span>
            </div>
            <div className="goals_rigth_arrow" onClick={handleRightClick}>{">"}</div>
          </div>
        </Grid>
        {empty() || label == 'Eixo Temático' ? null : (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <div className="pagination_wrapper">
              <Pagination
                page={page}
                count={total}
                onChange={handleChange}
                hidePrevButton hideNextButton
                variant="outlined" shape="rounded"
              />
              <p>Página {page} de {total}</p>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          {label != 'Eixo Temático' ? (
            <>
              {empty() ? null : (
                <div className="strategy_wrapper">
                  <p>
                    <b>{label}:</b>&nbsp;
                    {dataAxis[page - 1]?.name}
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {empty() ? null : (
                <div className="strategy_wrapper">
                  <p>
                    <b>{label}:</b>&nbsp;
                    {eixos_tematicos[currentIndexEixo]?.texto}
                  </p>
                </div>
              )}
            </>
          )}
        </Grid>
      </>
    );
  }, [dataAxis, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick]);

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, reutilizar, openDisclaimer]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitObjectives(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          {ReplaceButton()}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {ThematicAxisBanner()}
          {Fields()}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={12}>
            <ActionButtonElaborations disable={disabeSubmit} isOptional="true" isEmpty={empty()} />
          </Grid>
          <ReutilizarElaborationDialog
            title="Objetivos"
            reuseData={data}
            open={reutilizar}
            setOpen={() => setReutilizar(false)}
            setValue={(e) => {
              e.forEach((item: any, index: any) => setValueObjectives(`objetivos.${index}.texto`, item.texto))
            }}
          />
          <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
        </Grid>
      </Container>
    </form>
  )
};