import axios from 'axios';
import { ACCESS } from "@/utils/enviroments";
import { Api } from '@/services/configs/axios';

interface Subordinate {
  name: string;
  position: string | null;
  manager: string | null;
  tooltip: string;
  subordinates: Subordinate[];
}

interface OrganogramaData {
  organization: Subordinate[];
}

export class Organograma {
  private organograma: OrganogramaData | null;
  private apiUrl: string = ACCESS.API_URL + "organograma";
  private idPlano: number = 0

  constructor(idPlano: number) {
    this.organograma = null;
    this.idPlano = idPlano;
  }

  consultarOrganograma = async () => {
    try {
      const response = await Api.get<OrganogramaData>(this.apiUrl + '/' + this.idPlano);

      const data = response.data as any
      const organograma = JSON.parse(data.jsonOrganograma);

      this.organograma = organograma;
      return organograma;
    } catch (error) {
      if (axios.isAxiosError(error)) {
      } else {
      }

      return [];
    }
  }

  startOrganograma = (item: any[]): void => {
    if (!this?.organograma) {
      this.organograma = {
        'Organograma': item
      } as any;
      return;
    }
  };

  salvarOrganograma = async (): Promise<void> => {
    if (this.organograma == null) {
      return;
    }
    if (this.idPlano == 0) {
      return;
    }

    try {
      const response = await Api.post(this.apiUrl, {
        jsonOrganograma: JSON.stringify(this.organograma),
        idPlano: this.idPlano
      });

    } catch (error) {
      if (axios.isAxiosError(error)) {
      } else {
      }
    }
  }
}


